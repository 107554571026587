import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { HomePage, InfoArea, InfoInput, SolidButton, TitleContainer, WiserLogo, CompanyLogo, InputContainer } from './style.Auth';
import { TextButton } from 'pages/style.pages';

import ProductLogo from "assets/image/wiser-logo.png";
import ProductTitle from "assets/image/company-logo.png";


export default function AccountDetailsForm(props) {

    const { values, handleChange } = props;
    const navigate = useNavigate();

    const gotoNextStep = e => {
        e.preventDefault();
        props.nextStep();
    };

    const handleIconClick = () => {
        return window.open("https://www.globalactionalliance.net/", "_blank")
    };

    return (
        <HomePage>
            <InfoArea>
                <TitleContainer onClick={handleIconClick}>
                    <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" />
                    <CompanyLogo id="org-title" src={ProductTitle} alt="LifeScience WISE-R" />
                </TitleContainer>
                <InputContainer style={{margin: "2vh auto 1vh"}}>
                    <InfoInput
                        type={"text"}
                        value={values.name}
                        onChangeCallback={(e) => {handleChange("name", e.target.value)}}
                        placeholder="Name"
                        keyDownCallback={gotoNextStep}
                    />
                    <InfoInput
                        type={"text"}
                        value={values.email}
                        onChangeCallback={(e) => {handleChange("email", e.target.value)}}
                        placeholder="Organization Email"
                        keyDownCallback={gotoNextStep}
                    />
                    <InfoInput
                        type={"password"}
                        value={values.password}
                        onChangeCallback={(e) => {handleChange("password", e.target.value)}}
                        placeholder="Password"
                        keyDownCallback={gotoNextStep}
                    />
                </InputContainer>
                <SolidButton onClick={gotoNextStep}>Continue</SolidButton>
                <TextButton 
                    onClick={() => navigate('/')} 
                    style={{marginTop: "15px"}}
                >
                    Already have an account? Log in here
                </TextButton>
            </InfoArea>
        </HomePage>
    )
};