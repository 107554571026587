import styled from "styled-components";

//------ WHO PQ--------

export const WhoPrequalified = styled.section`
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 0.583rem;
    font-weight: 400;
`


export const Table = styled.table`
    /* width: 100%; */
    margin: 20px 0px 50px;
    font-family: 'Libre Franklin', Arial, sans-serif;
    font-style: normal;
    border-radius: 10px;
    overflow: hidden;
    
    font-size: 0.75rem;
    border-collapse: collapse;
    
    & td{
        border: 0.8px solid #DBEAF3;
    }
`

export const Thead = styled.thead`
    & tr{
        background-color: #23647C;
        color: #FAFAF9;
        text-align: left;
    }
    & tr > th {
        padding: 1.2vh 1.1vw;
        font-weight: normal;
        white-space: nowrap;
    }
`

export const Tbody = styled.tbody`
    & tr{
        text-align: left;
        color: #164050;
        
    }
    & tr > td {
        padding: 1.2vh 1.1vw;
        font-weight: normal;
    }
    & tr:nth-of-type(even){
        background-color: #DBEAF3;
    }
`

export const ThDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    & span:last-child{
        display: flex; 
        flex-direction: column; 
        height: 3vh;
    }
`

export const TdDiv = styled.div`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`