import { useNavigate, useLocation } from "react-router-dom";
import { Breadcrumb, Link, Typography } from './styles';


const getDisplayName = (input_pathNames) => {
    if (input_pathNames.length >= 2 && input_pathNames[1] === "list") {
        input_pathNames[1] += " of all ";
        switch (input_pathNames[0]) {
            case "clinicalTrials":
                input_pathNames[1] += "Trials";
                break;
            case "licensedProducts":
                input_pathNames[1] += "Vaccines";
                break;
            case "funding":
                input_pathNames[1] += "Projects";
                break;
            default:
                break;
        }
    }
    return input_pathNames;
}

const Breadcrumbs = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const pathNames = getDisplayName(pathname.split('/').filter(x => x));
    return (
        <Breadcrumb>
            <Link style={{marginLeft: "0px"}} onClick={() => { navigate('/') }}>{'Global Action Alliance'}</Link>
            <Link onClick={() => { navigate('/') }}>{'Home'}</Link>
            {
                pathNames.map((name, index) => {
                    const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
                    const lastIndex = pathNames.length - 1 === index;

                    const tempName = name.split(/(?=[A-Z])/);
                    const dispName = tempName.join(" ");

                    return (
                        lastIndex ?
                            <Typography>{dispName[0].toUpperCase() + dispName.substring(1)}</Typography>
                            :
                            <Link onClick={() => { navigate(routeTo) }}>
                                {dispName[0].toUpperCase() + dispName.substring(1)}
                            </Link>
                    );
                })
            }
        </Breadcrumb>
    );
}

export default Breadcrumbs;