import { createSlice } from '@reduxjs/toolkit';


const defaultState = {
    options: {
        Location: [],
        Active: ["Active", "Inactive"],
        Type: [],
        Year: [],
        Funder: [],
    }
};

const options = createSlice({
    name: 'option/vaccine',
    initialState: defaultState,
    reducers: {
        updateOptions: (state, action) => {
            state.options[action.payload.optionKey] = action.payload.optionValue;
        }
    }
})


export const { updateOptions } = options.actions;

export default options.reducer;
