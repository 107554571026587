import styled from "styled-components";

import OpenMenuIcon from "assets/image/selected-arrow.png";
import ClosedMenuIcon from "assets/image/unselected-arrow.png";

export const CheckboxItem = styled.div`
    display: flex;
    justify-content: space-between;
    // max-width: 50%;
`;

export const ArrowIcon = styled.img.attrs(({ifOpen}) => ({
    src:
        ifOpen ? OpenMenuIcon : ClosedMenuIcon
    }))`
        width: 8px;
        height: 6px;
        margin-right: 10px;
        padding: 3.5px;

        &:hover {
            cursor: pointer;
        }
`;

export const Menu = styled.div`
    display: ${props => props.ifOpen ? 'block' : 'none'};
    max-width: 50%;
`;