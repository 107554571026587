import React, { Component } from "react";
import CancelBtn from "assets/image/cancel_btn.png";
// import DropdownBtn from "assets/image/dropdown_btn.png";
// import Loader from "react-loader-spinner";

// import store from "store/index";
// import { getFilterUpdateAction, getFilterChangeAction, getFilterDeselectAction } from "store/whoPq/actionCreators";


import { filterActions } from 'store/whoPq/filtersReducer';
import { connect } from "react-redux";

import { FiltersRow } from "pages/style.pages";

// import 'pages/styles.scss';

class Filters extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     filters: store.getState().whoPqModule.filters,
        //     filtersSelection: store.getState().whoPqModule.options,
        // }

        this.handleFiltersSelect = this.handleFiltersSelect.bind(this);
        this.handleFiltersDeselect = this.handleFiltersDeselect.bind(this);
        this.handleFiltersUpdate = this.handleFiltersUpdate.bind(this);

        // store.subscribe(() => {
        //     this.setState({
        //         filters: store.getState().whoPqModule.filters,
        //         filtersSelection: store.getState().whoPqModule.options,
        //     });
        // });
    };

    filtersPresentation = new Map([
        ["Type", "Type"],
        ["Manufacturer", "Manufacturer"],
        ["Presentation", "Presentation"],
        ["PharmaceuticalForm", "Pharmaceutical Form"],
        ["NoOfDoses", "No. Of Doses"]
    ])

    handleFiltersUpdate() {
        this.props.updateFilters();
        // const action = getFilterUpdateAction(this.state.filters);
        // store.dispatch(action);
    }

    handleFiltersSelect(key, e) {
        this.props.changeFilter({ key: key, value: e.target.value });
        // this.setState((prevState) => ({
        //     filters: { ...prevState.filters, [key]: e.target.value }
        // }));
    };

    handleFiltersDeselect(key) {
        this.props.deselectFilter({key: key})
        // this.setState((prevState) => ({
        //     filters: { ...prevState.filters, [key]: "" }
        // }));
    };

    render() {
        return (
            <FiltersRow>
                {Object.entries(this.props.filters).map((entry) => {
                    return (
                        entry[1] == "" ?
                            <select
                                className="filter-item selsected"
                                onChange={(e) => this.handleFiltersSelect(entry[0], e)}
                            >
                                <option selected disabled>{this.filtersPresentation.get(entry[0])}</option>
                                {this.getFilterOptions(entry[0])}
                                { /* <img src={ DropdownBtn } /> */}
                            </select>
                            : <div className="filter-item selected">
                                <img
                                    src={CancelBtn}
                                    onClick={() => this.handleFiltersDeselect(entry[0])}
                                />
                                {entry[1]}
                            </div>
                    )
                })}
                <span onClick={this.handleFiltersUpdate}>Apply Filters</span>
            </FiltersRow>
        );
    };

    getFilterOptions(key) {
        return this.props.filterOptions[key].map((entry, index) => {

            return (
                <option value={entry[0]} disabled={!entry[1]}>{entry[0]}</option>
            )
        })
    };
}


const mapStateToProps = state => {
    return {
        filters: state.whoFilter.filters,
        filterOptions: state.whoOptions.options,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: (obj)=> dispatch(filterActions.changeFilter(obj)),
        // updateFilter:(obj) => dispatch(filterActions.updateFilter(obj)),
        deselectFilter:(obj) => dispatch(filterActions.deselectFilter(obj)),
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Filters);