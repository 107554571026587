import styled from "styled-components";

export const GridWrapper = styled.div`
    box-sizing: border-box;
    width: 100%; 
    height: 100%;
    padding: 2.5vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5vh;
`

export const BorderedTextButton = styled.button`
    padding: 0.8vh 0;
    width: 80%;

    color: #23647C;
    background-color: #FAFAF9;
    border: 1.5px solid #468AAB;
    border-radius: 0.8vw;

    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};

    &:hover {
        cursor: pointer;
        color: #FAFAF9;
        background-color: #468AAB;
    }
`

export const ContentContainer = styled.div`
    padding: 1.7vh 2.89vw 0;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const BreadcrumbContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const TitleRowContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

export const TopicTitle = styled.p`
    margin-top: 0.6vh;
    margin-bottom: 1.2vh;
    font-size: ${props=> props.theme.heading2.fontSize};
    font-weight: ${props=> props.theme.heading2.fontWeight};
    color: #164050;
`


export const TextButton = styled.a`
    color: #468AAB;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        color: #23647C;
    }
`

export const SummaryTitle = styled.span`
    align-self: flex-start;
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    color: #164050;

    width:100%;

    display:flex;
    align-items: center;
    justify-content: space-between;
`

export const TitleBackWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.clickable ? "#468AAB" : ""};

    ${props => !props.clickable ? '' : ' \
        &:hover { \
            cursor: pointer; \
            color: #23647C; \
        }'
    }
`

//---------- Common ---------

export const OverlayContainer = styled.div`
    position: fixed;
    top: 7vh;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background-color: rgba(247, 247, 247, 0.671); /*dim the background*/
    display: ${props => props.display};
    & > .spin-loader {
        margin: 35vh auto 0 auto;
        width: 3vw;
    }
`


export const TableContainer = styled.table`
    width: 100%;
    max-height: 27vh;

    // height: ${props => props.calcHeight ? '100%' : '27vh'};
    /* height: 100%;
    flex-grow: 1; */
    /* flex-flow: column;
    height: 100%; */

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;
    font-size: ${props => props.theme.heading6.fontSize};

    /* table-layout:fixed; */

    & tr > td, & > thead {
        border: ${props => props.isBordered ? '1px solid #23647C' : '1px solid #DBEAF3'};
    }
`

export const TableHead = styled.thead`
    width:100%;

    & tr {
        background-color: #23647C;
        color: #FAFAF9;
        text-align: left;
        width: fit-content;

    }
    & tr > th {
        padding: .5vh .4vw;
        font-weight: normal;
        white-space: nowrap;
    }
    & tr > th:last-child {
        width: 60%
    }
`

export const TableBody = styled.tbody`
    & tr {
        height: 3vh;
        text-align: left;
        color: #164050;
    }

    & tr > td {
        padding: .2vh .35vw;
        font-weight: normal;
    }

    & tr > td:first-of-type {
        text-align: center;
    }

    & tr > td:last-child {
        color: #23647C;
    }

    & tr > td:last-child:hover {
        cursor: pointer;
        color: #468AAB;
    }

    & tr:nth-of-type(even){
        background-color: #DBEAF3;
    }
`
export const TableDataDiv = styled.div`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const TableSingleDiv = styled.div`
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    color: #6C7073;
    padding: 2%;
    border: 1px solid #DBEAF3;

    & > p {
        margin: 0;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    font-size: ${props => props.theme.bodyText.fontSize};
`

export const ModalButton = styled.div`
    z-index: '20';
    fontSize: '.7rem';

    &:hover {
        cursor: pointer;
    }
`

export const TabSwitch = styled.span`
    width: 19vw;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    padding-right: 1vw;
    border: 1.2px solid #53bbdf;
    color: #53bbdf;
    border-radius: 48px;
    font-size: ${props=> props.theme.heading4.fontSize};
    height: 4vh;
`

export const TabSwitchOn = styled.span`
    width: 7.4vw;
    margin-left: ${props => {
        if (props.marginLeft !== undefined) {
            return props.marginLeft;
        }
        return 0;
    }};
    margin-right: ${props => {
        if (props.marginRight !== undefined) {
            return props.marginRight;
        }
        return 0;
    }};
    margin-top: -1px;
    border: 1.2px solid #53bbdf;
    border-radius: 48px;
    color: white;
    background-color: #53bbdf;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 3.9vh;
`




//--------------Common in only Graph---------

export const WorldMapContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;

    height: 100%;
    width: 100%;
`

export const SummaryTitleWrapper = styled.div`
    width:100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
`

export const WoldMapModalContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
    height: 100%;
    width: 100%;

    box-sizing: border-box;
`

export const ShadePlate = styled.div`
    /* flex-shrink: 0; */
    width: 26%;
    align-self: flex-end;
    display: flex;
    margin-right: 1rem;
`

export const ResetFilter = styled.div`
    ${TextButton}
    line-height: 3.5vh;
    font-size: ${props=> props.theme.heading4.fontSize};
`




//--------------Common in only Lists-----------



export const SubmitBtn = styled.div`
    background-color: #468AAB;
    width: 8vw;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 7px 15px;
    border-radius: 40px;
    font-size: ${props=> props.theme.heading3.fontSize};
    font-weight: ${props=> props.theme.heading3.fontWeight};

    cursor: pointer;
`

export const ListContainer = styled.section`
    height: 100%;
    min-height: 100vh;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight}; 
    display:flex;
    flex-direction: column;

    overflow: ${props => {
        if (props.loading) {
            return 'hidden';
        }
        return 'visible';
    }};
`

export const TagsWrapper = styled.div`
    margin-top: 2vh;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
`

export const SearchBar = styled.div`
    width: 68vw;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    padding: 5px 10px 5px 2vw;
    margin: 1vh 0 2vh;
    box-shadow: 3px 12px 20px 0px #589EC31A;

    background-color: #FAFAF9;

    & > input {
        height: 4vh;
        width: 65vw;
        background: transparent;
        border: none;
        font-size: ${props=> props.theme.heading4.fontSize};
        font-weight: ${props=> props.theme.heading4.fontWeight};
    }

    & > input:focus {
        outline: none;
    }
`

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    color: rgba(27, 27, 27, 0.8);
    width: 71.86vw;
    margin: 0 auto;

    & > p {
        color: rgb(53, 119, 143);
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
    }

    & > p:hover {
        color: rgba(150, 197, 216, 0.747);
    }
`


//---------- Filters---------

export const FiltersRow = styled.section`
    position: relative;
    display: flex;
    justify-content: flex-start;
    color: #468AAB;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};

    /* font-size: 0.683rem;
    font-weight: 400; */
    align-items: center;

    & > span {
        margin-left: 2vw;
        font-size: ${props=> props.theme.heading4.fontSize};
        font-weight: ${props=> props.theme.heading4.fontWeight};
    }

    & > span:hover {
        color: #23647C;
        cursor: pointer;
        /* color: rgb(163, 163, 163); */
    }

    & > .spin-loader {
        margin: 0 auto;
    }

    & > .filter-item {
        padding: 0.8vh 1vw;
        background-color: #FAFAF9;     
        box-shadow: 3px 8px 20px rgba(88, 158, 195, 0.15);
        border-radius: 30px;
        margin-right: 10px;
        min-width: 9vw;
        max-width: 15vw;
        text-align: center;
        border: none;
        color: #468AAB;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        outline: none;
        font-size: ${props=> props.theme.bodyText.fontSize};
        line-height: 1.5;

        > img {
            height: 10px;
            margin-right: 9px;
        }

        [disabled]{
            color: darkgray;
            background: #ccc;
        }
    }
    
    & > .filter-item:hover {
        background-color: #468AAB;
        color: white;
    }

    & > .filter-item:option{
        background-color: white;
        color:#468AAB;
    }

    & > .unselected:hover {
        cursor: pointer;
    }
    & > .selected {        
        background-color: #468AAB;
        color: white;

        & > img {
            cursor: pointer;
        }
    }
`

//---------- Coming Soon---------

export const ComingSoonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20vh auto 0;

    color: #164050;
    font-size: ${props=> props.theme.heading1.fontSize};
    font-weight: ${props=> props.theme.heading1.fontWeight};
`

//----------- Error Log-------

export const NoData = styled.div`
    height: 100%; 
    width: 100%;
    text-align: center;
    color: #808782;

    font-size: ${props=> props.theme.heading3.fontSize};
    font-weight: ${props=> props.theme.heading1.fontWeight};

`

//-------- Deselsect Filter Arrow----------
export const DeselsectFilterArrow = styled.div`
    margin: 0 10px 0 0;
    color: #468AAB;
    font-size: 16px;

    cursor: pointer;
`