import { LoaderModal, LoadingImage } from "components/styles";
import React from "react";
import PopupBackdrop from "./PopupsBackdrop";

import Loading from '../../assets/image/loading.png';

const Loader = ({ isVisible }) => {
    return (
        <React.Fragment>
            <PopupBackdrop
                isDisplay={isVisible}
            />
            <LoaderModal
                isDisplay={isVisible}
            >
                <LoadingImage src={Loading} alt="Loading....."/>
                <p>Analyzing Query</p>
            </LoaderModal>
        </React.Fragment>
    );
};

export default Loader;