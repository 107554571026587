import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Header, PagedTable } from "components";
import { HorizontalBar, Doughnut } from 'components/Charts';
import { HomeContainer, TopicTitle, Grid, LicensedProductsTile, FundingTile, OrganizationTile, SummaryTitle, TableContainerRecipient, TableHead, TableBody, TableDataDiv, ClinicalTrials, Publications, BorderedTextButton, BottomNav } from "./style.home";
import { ContentContainer, GridWrapper } from '../style.pages';
import { processNumberString } from "utils/Function/number";


export default function Home(props) {

    const navigate = useNavigate();

    const [ data, setData ] = useState({
        countryData: {
            labels: [""],
            datasets: [
                {
                    // label: "Vaccines",
                    backgroundColor: [
                        "#23647C",
                        "#468AAB",
                        "#4CA2C5",
                        "#53BBDF",
                        "#58CCF1",
                        "#6ED4F3",
                        "#7DC4D9",
                        "#87D1E7",
                        "#86DDF6",
                        "#B0EDFB",
                    ],
                    data: [0],
                },
            ],
        },
        fundingData: {
            datasets: [
                {
                    // label: "Vaccines",
                    backgroundColor: [
                        "#23647C",
                        "#468AAB",
                        "#4CA2C5",
                        "#53BBDF",
                        "#58CCF1",
                        "#6ED4F3",
                        "#7DC4D9",
                        "#87D1E7",
                        "#86DDF6",
                        "#B0EDFB",
                    ],
                    data: [0],
                },
            ],
        },
        organizationData: []
    });

    const handleChartData = (stateObj, resultObj) => {
        let labels = Object.keys(resultObj);
        let values = Object.values(resultObj);

        let newObj = new Object();
        newObj.labels = labels;
        newObj.datasets = [new Object()];
        newObj.datasets[0].label = stateObj.datasets[0].label;
        newObj.datasets[0].backgroundColor = [...stateObj.datasets[0].backgroundColor];
        newObj.datasets[0].data = values;
        return newObj;
    };

    useEffect(() => {
        const fetchData = async () => {
            const rawResponse = await fetch (
                "./home.json", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            );
            // Receive filtered results
            const results = await rawResponse.json();

            // Process country data
            let countryDataObj = {};
            Object.entries(results.licensedProductData).slice(0, 10).forEach((country) => {
                countryDataObj[country[0]] = country[1];
            });
            let newCountryData = handleChartData(
                data.countryData,
                countryDataObj
            );

            // Process country data
            let fundingDataObj = {};
            Object.entries(results.fundingData).slice(0, 10).forEach((funder) => {
            fundingDataObj[funder[0]] = funder[1];
            });
            let newFundingData = handleChartData(
                data.fundingData,
                fundingDataObj
            );

            setData(prev => { 
                return {
                    ...prev, 
                    countryData: newCountryData, 
                    fundingData: newFundingData,
                    organizationData: results.projectByRecipient.slice(0, 10)
                } 
            });
        };

        fetchData()
            .catch(console.error);
    }, []);

    return (
        <HomeContainer>
            <Header routes={props.history} />
            <ContentContainer>

                <Grid>
                    <TopicTitle style={{gridRow: "1 / 2", gridColumn: "1 / 20"}}>Business Intelligence</TopicTitle>

                    <LicensedProductsTile>
                        <GridWrapper style={{ justifyContent: "space-between" }}>
                            <div style={{maxHeight: "80%"}}>
                                <SummaryTitle>Licensed Products by Location</SummaryTitle>
                                <HorizontalBar 
                                    data={data.countryData}
                                    chartWidth={'100%'}
                                    chartHeight={'100%'}
                                    pageType = "Vaccine Availability"
                                    clickable={true}
                                    filterType={"Location"}
                                />
                            </div>
                            <BottomNav>
                                <BorderedTextButton
                                    onClick={() => {navigate('/licensedProducts/list')}}
                                >
                                    List view
                                </BorderedTextButton>
                                <BorderedTextButton 
                                    onClick={() => {navigate('/licensedProducts')}}
                                    style={{marginLeft: "5px"}}
                                >
                                    Graph view
                                </BorderedTextButton>
                            </BottomNav>
                        </GridWrapper>
                    </LicensedProductsTile>

                    <FundingTile>
                        <GridWrapper style={{ justifyContent: "space-between" }}>
                            <div style={{maxHeight: "80%"}}>
                                <SummaryTitle style={{ marginBottom: "10px"}}>Funding by Funder</SummaryTitle>
                                <Doughnut 
                                    data={data.fundingData}
                                    chartWidth={'100%'}
                                    chartHeight={'100%'}
                                    pageType = "Funding"
                                    clickable={true}
                                    filterType={"Funder"}
                                />
                            </div>
                            <BottomNav>
                                <BorderedTextButton
                                    onClick={() => {navigate('/funding/list')}}
                                >
                                    List view
                                </BorderedTextButton>
                                <BorderedTextButton 
                                    onClick={() => {navigate('/funding')}}
                                    style={{marginLeft: "5px"}}
                                >
                                    Graph view
                                </BorderedTextButton>
                            </BottomNav>
                        </GridWrapper>
                    </FundingTile>

                    <OrganizationTile>
                        <GridWrapper style={{ justifyContent: "space-between" }}>
                            <div style={{maxHeight: "80%"}}>
                                <SummaryTitle style={{ marginBottom: "10px"}}>Organizations</SummaryTitle>
                                {/* <TableContainerRecipient>
                                    <TableHead>
                                        <tr>
                                            <th>no. projects</th>
                                            <th>Total Funding</th>
                                            <th>Recipient Name</th>
                                        </tr>
                                    </TableHead>
                                    <TableBody>
                                        { generateProjectByRecipient() }
                                    </TableBody>
                                </TableContainerRecipient> */}
                                <PagedTable
                                    header={["no. projects", "Total Funding", "Recipient Name"]}
                                    data={data.organizationData}
                                    currentPage={1}
                                    pageCount={1}
                                    numOfRows={10}
                                    isBordered={false}
                                />
                            </div>
                            <BottomNav>
                                <BorderedTextButton
                                    onClick={() => { navigate('/organisations') }}
                                >
                                    Organizations
                                </BorderedTextButton>
                            </BottomNav>
                        </GridWrapper>
                    </OrganizationTile>

                    <TopicTitle 
                        style={{ gridRow: "11 / 12", gridColumn: "1 / 20" }}
                    >Research and Development</TopicTitle>
                    <ClinicalTrials></ClinicalTrials>
                    <Publications></Publications>
                </Grid>
            </ContentContainer>
        </HomeContainer>
    )
}
