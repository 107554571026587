import styled from "styled-components";
import { media } from 'utils/Static/index';


export const ComponentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 80%;
    gap: .5vw;
    color: #164050;
    /* font-size: ${props => props.theme.bodyText.fontSize}; */
    font-weight: ${props => props.theme.bodyText.fontWeight};
`

export const ComponentWrapper2 = styled.div`
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 95%;
    gap: .7vw;
    color: #164050;
    /* font-size: ${props => props.theme.bodyText.fontSize}; */
    font-weight: ${props => props.theme.bodyText.fontWeight};
`

export const ChartsHead = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: .1vw;

    & > p {
        margin: 0;
        font-size: ${props => props.theme.heading3.fontSize};
        font-weight: ${props => props.theme.heading3.fontWeight};
    }
`

export const ChartData = styled.button`
    width:100%;
    height: 40%;

    background-color: ${props => props.backgruondColor};
    border: ${props=> props.isBordered ? '2px solid #468AAB': 'none'};
    color: #164050;

    border-radius: 10px;

    font-size: ${props => props.theme.heading3.fontSize};
    font-weight: ${props => props.theme.heading3.fontWeight};
`

export const ChartWrapper = styled.div`
    width: 95%;
    box-sizing: border-box;
    display : flex;
    align-items: center;
    justify-content: space-between;

    & > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        span{
            width: fit-content;
            text-align: center;
            color: #164050;
        }
    }
`

export const ChartDataButton = styled.button`
    width:100%;
    height: 40%;
    padding: .4rem;

    background-color: ${props => props.backgroundColor};
    border: ${props=> props.isBordered ? '2px solid #23647C': 'none'};
    color: #164050;

    border-radius: 4px;

    font-size: ${props => props.theme.heading6.fontSize};
    font-weight: ${props => props.theme.heading3.fontWeight};
`

export const ChartData2 = styled.button`
    width: 73%;
    height: 40%;

    background-color: #86DDF6;
    border: ${props=> props.isBordered ? '2px solid #468AAB': 'none'};
    color: #FAFAF9;

    font-size: ${props => props.theme.heading3.fontSize};
    font-weight: ${props => props.theme.heading3.fontWeight};
`

export const PieChartSingleContainer = styled.span`
    min-width: 2.2vw;
    min-height:2.2vw;
    width: fit-content;
    padding: .8vw .7vw;
    border-radius: 100px;
    border: ${props=> props.isBordered ? '2px solid #23647C': 'none'};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props=> props.backgroundColor};
    color: white;

    & > p{
        font-size: ${props => props.theme.heading3.fontSize};
        margin: 0;
    }
`