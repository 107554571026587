import styled from "styled-components";
import { media } from 'utils/Static/index';


export const ChartContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : "100%")};
    width: ${(props) => (props.width ? props.width : "100%")};
`

export const BackdropContainer = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: grey;
    opacity: 0.1;   
`

export const PopupBackdropContainer = styled.div`
    position: fixed;
    z-index: ${props => props.zIndex ? props.zIndex : `100`};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #6C7073;
    opacity: 0.7;

    backdrop-filter: blur(10px);

    display: ${props=> props.isDisplay ? 'block' : 'none'};
`

export const LoaderModal = styled.div`
    position: fixed;
    left: 50%;
    top: 25%;

    width: 22%;
    height: 24%;

    z-index: 110;

    background-color: #FAFAF9;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 24px;

    color: #468AAB;

    padding: 2vh;

    transform: translateX(-50%);

    display: ${props => props.isDisplay ? 'flex' : 'none'};

    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.6vh;
    
`

export const LoadingImage = styled.img`
    width: 120px;
    height: 120px;

    animation: rotation 2s infinite linear;

    @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
`

export const DropdownWrapper = styled.form`
    display: inline;
    border: none;
    // flex-flow: column;
    // justify-content: flex-start;
`;

export const StyledSelect = styled.select`
    max-width: 50%;
    height: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
`;

export const StyledOption = styled.option`
    color: ${(props) => (props.selected ? "lightgrey" : "black")};
`;

export const FooterContainer = styled.div`
    box-sizing: border-box;
    position:  ${(props) => !props.relative && "fixed"};
    bottom: 0px;
    padding: 1.1vh 0;
    margin-top: auto;
    background-color: #23647C;
    color : #DBEAF3;
    width: 100%;
    height: 6.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`
export const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`
export const FooterHeading = styled.div`
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontSize};
    margin-bottom: 6px;
`
export const FooterLink = styled.a`
    text-decoration: none;
    font-weight: ${props=> props.theme.heading6.fontWeight};
    font-size: ${props=> props.theme.heading6.fontSize};
    line-height: 14px;
    color : #DBEAF3;

    &:hover {
        text-decoration: underline;
        color : #CFDBE3;
    }

    &.disabled {
        text-decoration: none;
        color : #DBEAF3;
    }
`
export const FooterLinkSeparator = styled.div`
    font-weight: ${props=> props.theme.heading6.fontWeight};
    font-size: ${props=> props.theme.heading6.fontSize};
    margin : 0px 8px;
`

export const HeaderComponent = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    min-height: 111px;
    width: 100%;
    align-self: flex-start;
    
    background: #F7F7F6;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    padding: 0 1.2vw;

    ${media.monitor} {
        min-height: 59px;
    }

    ${media.large_desktop} {
        min-height: 51px;
    }

    ${media.laptop} {
        min-height: 40px;
    }
`

export const LogoComponent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    #org-logo {
        height: 65px;
    }

    ${media.monitor} {
        #org-logo {
            height: 45px;
        }
    }

    ${media.large_desktop} {
        #org-logo {
            height: 34px;
        }
    }

    ${media.laptop} {
        #org-logo {
            height: 26.7px;
        }
    }
`

export const TitleImage = styled.img`
    height: 40px;
    margin-left: 5px;

    ${media.monitor} {
        height: 28px;
    }

    ${media.large_desktop} {
        height: 20px;
    }

    ${media.laptop} {
        height: 15.7px;
    }
`


export const NavigateItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5vw;
`

export const TopicBlock = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.isDisabled ? 'rgba(108, 112, 115, 0.5)' : '#23647C'};

    cursor: pointer;
    position: relative;

    &::after, &::before {
        --scale: 0;
        --tooltip-color: #DBEAF3;
        --arrow-size: 7px;

        position: absolute;
        left:50%;
        bottom: -.15rem;
        outline: none;
        /* z-index: 1; */

        max-height: 17vh;
        overflow-y: auto;

        transform: translateX(-50%) translateY(var(--translate-y,0)) scale(var(--scale));
        transition: 100ms transform;

    }
    
    &::before{
        --translate-y: calc(100% + var(--arrow-size));
        
        content: attr(data-tooltip);
        color:  #164050;
        padding: .4vw;
        width: max-content;
        max-width: 10vw;
        height: auto;
        background: var(--tooltip-color);
        
        font-size: ${props=> props.theme.heading6.fontSize};
        border-radius: 6px;

        transform-origin: top center;
    }

    &:hover::before,
    &:hover::after {
        --scale: 1;
        z-index: 1;
    }

    &::after{
        --translate-y: var(--arrow-size);

        content: '';
        border: 6px solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

`

export const BlockImage = styled.img`
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    padding: 0.2vw;
    opacity: ${props => props.isDisabled ? '0.5' : '1.0'};
    background-color: ${props => props.isDisabled ? 'rgba(219, 234, 243, 0.2)' : '#DBEAF3'};
    border-radius: 20px;

    ${props => props.isDisabled ? '' : ' \
        &:hover { \
            cursor: pointer; \
            border: 1px solid #53BBDF; \
        }'
    }

    ${media.monitor} {
        width: 44px;
        height: 44px;
        border-radius: 11px;
    }

    ${media.large_desktop} {
        width: 39.6px;
        height: 39.6px;
        border-radius: 10px;
    }

    ${media.laptop} {
        width: 30px;
        height: 30px;
        border-radius: 7px;
    }
`


export const SigninButton = styled.div`  // Change this to button or a in future when the desired functionality is added
    color: rgb(28, 59, 50);
    padding: 8px 25px;
    border-radius: 8px;
    &:hover {
        background-color: rgba(233, 233, 233, 0.418);
    }
`
export const RegisterButton = styled.div` // Change this to button or a in future when the desired functionality is added
    color: white;
    background-color: #1D7A2F;
    margin: 0 10px;
    padding: 8px 25px;
    border-radius: 7px;
    &:hover {
        background-color: rgba(26, 126, 39, 0.8);
    }
`
export const CustomModal = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    overflow: hidden;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 20px;
    padding: 1.5vw;
`

export const CustomWorldMapModal = styled.div`
    position: absolute;
    left: 15.5%;
    top: 9%;

    width: 65%;
    height: 75%;

    z-index: 270;

    background-color: #FAFAF9;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 24px;

    color: #468AAB;

    padding: 4vh;

    transform: ${props => props.isDisplay ? 'scale(1)' : 'scale(0)'};
    opacity: ${props => props.isDisplay ? '1' : '0'};


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.6vh;

    overflow: hidden;

    transition: all 0.2s ease-in-out;

    @keyframes popup{
        0%{
            transform: translate(-50%, -50%) scale(0);
            opacity: 0;
        }
        100%{
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    /* @keyframes popup{
        0%{
            transform: translate(-50%, -100%);
        }
        70%{
            transform: translate(-50%, -40%);
        }
        80%{
            transform: translate(-50%, -35%);
            opacity: 1;
        }
        100%{
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    } */
`

export const WorldMapTitle = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    font-size : ${props => props.theme.heading4.fontSize};
`

export const PaginationGrid = styled.div`
    margin: 10px 0px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    

`
export const PaginationGridUl = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #468AAB;

    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: 500;
    font-family: 'Libre Franklin', Arial, Helvetica, sans-serif;
    font-style: normal;

    .on{
            background-color: #468AAB;
            box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
            color: #FAFAF9;
        }    
`
export const PaginationGridLi = styled.li`
    list-style: none;
    cursor: pointer;
    /* width: 1.7vw; */
    padding: ${props => props.changePading};
    /* height: 3.4vh; */
    /* line-height: 4px; */
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0px;
    border-radius: 50%;
`

export const PaginationGridItem = styled.li`
    list-style: none;
    margin: 2px;
`

export const PaginationGridButton = styled.button`
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-size: ${props=> props.theme.heading4.fontSize};
    margin: 0px 5px;

    &:disabled{
        color:#C4C4C4;
        cursor: not-allowed;
    }

`

//-------- Beradcrumb--------------

export const Breadcrumb = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: 0.57vh 0 0vh 0;
    
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontSize};
    font-style: normal;
`
export const Link = styled.span`
    margin-left: 5px;
    color: #468AAB;
    cursor: pointer;

    &:hover {
        color: #23647C;
    }

    &::after{
        content : '>';
        margin-left: 5px; 
    }
`

export const Typography = styled.span`
    margin-left: 5px;
    
    color: #142026;
    cursor: pointer;
`

//---------------TabSwitch-----------------------

export const TabSwitchContainer = styled.section`
    border-radius: 48px;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    background: #FAFAF9;

    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
    
    color: #468AAB;

    display: flex; 
    justify-content: space-between;

    & .tab-Switch-on{
        padding: 0.7vh 1vw;
        background-color: #468AAB;
        color: #FAFAF9;
        
        display: flex;
        justify-content: center;
        align-items: center;
    
        cursor: pointer;
    }
    
    & .border-gr{
        border-radius: 48px 0px 0px 48px;
    }
    
    & .border-li{
        border-radius: 0px;
    }
    
    & .border-in{
        border-radius: 0px 48px 48px 0px;
    }
    
    & .tab-Switch-off{
        margin: 0;
        padding: 0.7vh 1vw;
        color: #468AAB;
        cursor: pointer;
    
        display: flex;
        align-items: center;
    }
`
export const TabItems = styled.span`
    margin: 0;
    color: #468AAB;
    cursor: pointer;

    display: flex;
    align-items: center;
`
export const TabDisable = styled.span`
    margin: 0;
    padding: 0.7vh 1vw;
    padding-right : ${(props) => (props.tabSwitchOn == 'insights' ? "0px" : "14px")};
    display: flex;
    align-items: center;
    color: #C4C4C4;;
    cursor: not-allowed;
`

//-------------Tabs WHO PQ -------------
export const TabsRow = styled.section`
    flex-wrap: nowrap;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    height: 8vh;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
`

export const TabsLabel = styled.div`
    padding: 1.5vh 1.2vw;
    background-color: #FAFAF9;
    box-shadow: 3px 5px 15px rgb(88 158 195 / 15%);
    border-radius: 12px;

    font-family: 'Libre Franklin', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: ${props=> props.theme.heading4.fontSize};
    line-height: .8rem;

    white-space: nowrap;

    color: #164050;

    & p{
        margin: 4px;
    }

    & p > a{
        text-decoration: none;
        color: inherit;
        font-size: ${props=> props.theme.heading4.fontSize};
        font-family: 'Libre Franklin', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: normal;
    }
`

//------------------Tags----------------

export const TagsContainer = styled.div`
`

export const TagsHeading = styled.p`
    margin: 0 0 2px 0;
    color: #468AAB;
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
`

export const TagsContentShort = styled.p`
    margin: 0;
    padding: 1vh 1.15vw;
    background-color: #EEFCFE;
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    max-width: 15vw;
    /* white-space: nowrap; */
    color: #164050;
    /* cursor: pointer; */
    position: relative;
`;


export const TagsContent = styled.p`
    margin: 0;
    padding: 1vh 1.15vw;
    background-color: #EEFCFE;
    /* text-overflow: ellipsis; */
    max-width: 10vw;
    /* white-space: nowrap; */
    color: #164050;
    /* cursor: pointer; */
    position: relative;

    &::after, &::before{
        --scale: 0;
        --tooltip-color: #DBEAF3;
        --arrow-size: 7px;

        position: absolute;
        left:50%;
        bottom: -.2rem;
        outline: none;
        /* z-index: 1; */

        max-height: 17vh;
        overflow-y: auto;

        
        transform: translateX(-50%) translateY(var(--translate-y,0)) scale(var(--scale));
        transition: 100ms transform;

    }
    
    &::before{
        --translate-y: calc(100% + var(--arrow-size));
        
        content: attr(data-tooltip);
        color:  #164050;
        padding: .7vw;
        width: max-content;
        max-width: 20vw;
        height: auto;
        background: var(--tooltip-color);
        
        font-size: ${props=> props.theme.heading6.fontSize};
        border-radius: 6px;

        transform-origin: top center;
    }

    &:hover::before,
    &:hover::after{
        --scale: 1;
        z-index: 1;
    }

    &::after{
        --translate-y: var(--arrow-size);

        content: '';
        border: 6px solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
`

export const PaginationWrapper = styled.div`
    width: 100%;
    height: 26vh;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: .5vw;
`
export const PaginationWrapperLineChart = styled.div`
    width: 100%;
    height: 120%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: .5vw;
`

export const PageClick = styled.button`
    background: none;
	color: inherit;
	border: none;
	padding: 3.5px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-size: ${props=> props.theme.heading6.fontSize};

    &:hover {
        color: #23647C;
    }

    &:disabled {
        color: #C4C4C4;
        cursor: not-allowed;
    }
`

export const NextPageClick = styled.button`
    &:disabled{
        color:#C4C4C4;
        cursor: not-allowed;
    }
`