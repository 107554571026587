import React, { Component } from "react";
import { FiltersContainer } from "./style";
import { ResetFilter } from "pages/style.pages";
import { isEqual } from "lodash";
import Filter from "./Filter";

import { connect } from "react-redux";
import { defaultState, filterActions } from 'store/funding/filtersReducer';



class FiltersRow extends Component {
    filtersPresentation = new Map([
        ["Location", "Location"],
        ["Active", "Active"],
        ["Type", "Funder type"],
        ["Year", "Year started"],
        ["Funder", "Funded by"]
    ]);

    render() {
        return (
            <div style={{height: "3.5vh", zIndex: "10"}}>
                <FiltersContainer>
                    {Object.entries(this.props.filterValues).map((entry) => {
                        const filterName = entry[0];
                        return (
                            <Filter
                                key={entry[0]}
                                filterLabel={this.filtersPresentation.get(entry[0])}
                                filterName={entry[0]}
                                filterValue={entry[1]}
                                options={this.props.filterOptions[filterName]}
                                clearTimeOutVaccine={this.props.clearTimeOutVaccine}
                                setTimeOutVaccine={this.props.setTimeOutVaccine}
                                ifSelected={this.props.ifSelected}
                            >
                                {entry[0]}
                            </Filter>
                        )
                    })}
                    { 
                        !isEqual(defaultState.filters, this.props.filterValues) &&
                            <ResetFilter onClick={this.props.ResetFilter}>Reset Filters</ResetFilter>
                    }
                </FiltersContainer>
            </div>
        )
    };
};

const mapStateToProps = state => {
    return {
        filterValues: state.fundingFilter.filters,
        filterOptions: state.fundingOptions.options,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ResetFilter: (obj) => dispatch(filterActions.clearFilter(obj)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FiltersRow);