import React from 'react';
import { useParams } from 'react-router-dom';

/**
 * Higher Order Component to resolve router props issue for now
 * @param {*} WrappedComponent 
 * @returns 
 */

const withRouter = WrappedComponent => props => {
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            params={params}
        />
    );
};

export default withRouter;