import React, { Component } from "react";
import { Loader, Header, Footer, Pagination, TabSwitch, Breadcrumbs, Tags, Tabs, Input } from "components";
import { BiSearch } from "react-icons/bi";
import { processNumberString } from "utils/Function/number";

import { connect } from 'react-redux';
import { updateOptions } from 'store/funding/optionsReducer';

import { API_PORT, API_PATH, API_MODE } from "services/libraryAPI";

import { ContentContainer, FlexContainer, ListContainer, OverlayContainer, SearchBar, TagsWrapper, TitleRowContainer, TopicTitle, BreadcrumbContainer, TextButton, SubmitBtn } from "pages/style.pages";
import { FundingCardWrapper, } from '../style.funding';

import { FILTER_DELAY } from "utils/Constants";
import FiltersRow from "../filter/FiltersRow";


class FundingListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            searchText: "",
            stats:[],
            loading: true,
            currentPage: 1,
            totalPage: 1
        };

        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.paginate = this.paginate.bind(this);
    };

    queryFilteredData = (newPage=this.state.currentPage) => {

        const pageToQuery = (newPage == this.state.currentPage ? 1 : newPage);

        this.setState(
            () => ({
                loading: true,
            }),
            async () => {
                let filters = JSON.parse(JSON.stringify(this.props.filters));
                switch (filters["Active"]) {
                    case "Active":
                        filters["Active"] = "active";
                        break;
                    case "Inactive":
                        filters["Active"] = "inactive";
                        break;
                    default:
                        filters["Active"] = "";
                        break;
                }
                filters["Search"] = this.state.searchText;

                try {
                    const rawResponse = await fetch(
                        `${API_MODE}://${API_PATH}:${API_PORT}/v1/funding`,
                        {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({filters, "pageNum" : pageToQuery}),
                        }
                    );

                    // Receive filtered results
                    const results = await rawResponse.json();
                    console.log(results)

                    // Update options
                    this.handleOptionUpdate("Location", results.options.locations)
                    this.handleOptionUpdate("Year", results.options.years);
                    this.handleOptionUpdate("Funder", results.options.funders);
                    this.handleOptionUpdate("Active", results.options.Active);
                    this.handleOptionUpdate("Type", results.options.Type);

                    // Update results onto page
                    let content = results.content;
                    this.setState(() => ({
                        projects: content,
                        stats: results.stats,
                        totalPage: results.totalPageCount,
                        loading: false,
                        currentPage: pageToQuery
                    }));
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                }
            }
        );
    };

    handleOptionUpdate = (option, value) => {
        this.props.updateOptions({optionKey: option, optionValue: value});
    };

    handleSearchInput = (e) => {
        this.setState({ searchText: e.target.value });
    };

    handleSearchSubmit = () => {
        this.queryFilteredData();
    };

    componentDidMount = () => {
        this.queryFilteredData();
    };

    // Should be implemented in Redux Thunk later
    componentDidUpdate = (prevState) => {
        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevState.filters)) {
            this.setState(
                () => (
                    { ifSelected: true }
                ));
            this.fetchCall();
        }
    };

    fetchCall = () => {
        this.timeOut = setTimeout(() => {
            this.queryFilteredData();
            this.setState(
                () => (
                    { ifSelected: false }
                ));
        }, FILTER_DELAY);
    };


    paginate = (number) => {
        this.queryFilteredData(number);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    render = () => {
        return (
            <ListContainer loading={this.state.loading}>
                <Loader isVisible={this.state.loading} />
                <Header routes={this.props.history} />

                <ContentContainer>
                    <BreadcrumbContainer>
                        <Breadcrumbs />
                        <TextButton href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">
                            Share additional Data
                        </TextButton>
                    </BreadcrumbContainer>

                    <TitleRowContainer>
                        <TopicTitle>Funding</TopicTitle>

                        {/* Tab Switch */}
                        <TabSwitch tabSwitchOn={'list'} pageType={'funding'} />
                    </TitleRowContainer>

                    <Tabs stats={this.state.stats}/>

                    {/* Statistical Summary Grid */}
                    <FlexContainer>

                        {/* Search Bar */}
                        <SearchBar>
                            <Input
                                value={this.state.searchText}
                                onChangeCallback={this.handleSearchInput}
                                placeholder="Search by Project Name or Keyword"
                                keyDownCallback={this.handleSearchSubmit}
                            />
                            <SubmitBtn onClick={this.handleSearchSubmit} id="submit-btn">
                                <BiSearch />
                                <span>Search</span>
                            </SubmitBtn>
                        </SearchBar>

                        {/* Filters Row */}
                        <FiltersRow
                            routes={this.props.history}
                            setTimeOutVaccine={this.fetchCall}
                            clearTimeoutVaccine={this.stopFetch}
                        />

                        <main style={{marginTop: "2vh"}}>{this.generateCards()}</main>

                        <Pagination
                            numberOfPages={this.state.totalPage}
                            currentPage={this.state.currentPage}
                            paginate={this.paginate}
                        />
                    </FlexContainer>
                </ContentContainer>

                <Footer relative={this.state.projects.length > 0} />
            </ListContainer>
        );
    }

    generateCards = () => {
        const tagMap = {
            "Total Funding": "funding",
            "Funder": "funder",
            "Recipient": "recipient",
            "Type": "type",
            "Start Year": "start_year",
            "Location": "location",
        };

        return this.state.projects.length > 0 ? (
            this.state.projects.map((item, index) => {
                return (
                <FundingCardWrapper>
                    <h1>{item["title"]}</h1>
                    <TagsWrapper>
                        {Object.keys(tagMap).map((tagKey) => {
                            const value = item[tagMap[tagKey]]; 
                            return (
                                <Tags key={tagKey} data={
                                    (tagMap[tagKey] === "funding" && typeof value === "number")
                                        ? processNumberString(value)
                                        : (value ?? "Not availability")
                                } tagsKey={tagKey} />
                            );
                        })}
                    </TagsWrapper>
                </FundingCardWrapper>
                );
            })
        ) : (
            <h1>No Vaccines Returned for the Given Filters</h1>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        filters: state.fundingFilter.filters,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(FundingListView);