import { PaginationWrapperLineChart, PageClick} from '../styles';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

const LineChartPagination = (props) => {


    return(
        <PaginationWrapperLineChart>
            {props.numberOfPage != 1 && <PageClick disabled={props.currentPage === 1} onClick={() => { props.handlePageClick(props.currentPage - 1) }}><BsFillCaretLeftFill /></PageClick>}
            <div style={{flexGrow: '1', alignSelf:'flex-start', height: '99%'}}>{props.children}</div>
            {props.numberOfPage != 1 && <PageClick disabled={props.currentPage == props.numberOfPage} onClick={() => { props.handlePageClick(props.currentPage + 1) }}><BsFillCaretRightFill /></PageClick>}
        </PaginationWrapperLineChart>
    );
}

export default LineChartPagination;