import React, { Component } from "react";
import { Loader, Header, Footer, Pagination, TabSwitch, Breadcrumbs, Tabs, Tags, Input } from "components";
import { FlexContainer, ListContainer, OverlayContainer, SearchBar, TagsWrapper, SubmitBtn, TitleRowContainer, TopicTitle, BreadcrumbContainer, TextButton, ContentContainer } from "pages/style.pages";
import { CardWrapperVaccine, TagsWrapperVaccine } from '../style.licensedProduct';
import DropdownPDF from "./vaccineDropdown";
import FiltersRow from "../filter/FiltersRow";
import { BiSearch } from "react-icons/bi";

import { updateOptions } from 'store/vaccineAvailability/optionsReducer';
import { connect } from 'react-redux';

import { API_PORT, API_PATH, API_MODE } from "services/libraryAPI";

import { FILTER_DELAY } from "utils/Constants";



class VaccineAvailabilityList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vaccines: [],
            loading: true,
            stats: {},
            searchText: "",
            currentPage: 1,
            totalPage: 1,
            ifSelected : false,
        };

        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.paginate = this.paginate.bind(this);
    };

    queryFilteredData = (newPage=this.state.currentPage) => {

        const pageToQuery = (newPage == this.state.currentPage ? 1 : newPage);

        this.setState (
            () => ({
                loading: true,
            }),
            async () => {
                let filters = JSON.parse(JSON.stringify(this.props.filters));
                switch (filters["Who_PQ"]) {
                    case "Prequalified":
                        filters["Who_PQ"] = "true";
                        break;
                    case "Not Prequalified":
                        filters["Who_PQ"] = "false";
                        break;
                    default:
                        filters["Who_PQ"] = ""
                        break;
                }
                filters["Search"] = this.state.searchText;

                try {
                    const rawResponse = await fetch (
                        `${API_MODE}://${API_PATH}:${API_PORT}/v1/vaccineAvailability`,
                        {
                            method: "POST",
                            headers: {
                            Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({filters, "pageNum" : pageToQuery}),
                        }
                    );

                    // Receive filtered results
                    const results = await rawResponse.json();

                    // Update sponsors options
                    let location = [];
                    let manufacturerList = [];
                    let vaccineType = [];
                    let whoPqList = [];

                    results.options.locations.map((entry) => {
                        location.push(entry);
                    })

                    results.options.manufacturers.map((entry) => {
                        manufacturerList.push(entry);
                    });

                    results.options.vaccinetypes.map((entry)=>{
                        vaccineType.push(entry)
                    });
                    
                    results.options.Who_PQ.map((entry)=> {
                        whoPqList.push(entry);
                    })

                    //Update Options
                    this.handleOptionUpdate("Manufacturer", manufacturerList);
                    this.handleOptionUpdate("Type", vaccineType);
                    this.handleOptionUpdate("Location", location);
                    this.handleOptionUpdate("Who_PQ", whoPqList);

                    // Update results onto page
                    let content = Object.entries(results.content);
                    this.setState(() => ({
                        vaccines: content,
                        stats: results.stats,
                        totalPage: results.totalPageCount,
                        loading: false,
                        currentPage: pageToQuery
                    }));
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                }
            }
        );
    };

    handleOptionUpdate = (option, value) => {
        this.props.updateOptions({optionKey: option, optionValue: value});
    };

    handleSearchInput = (e) => {
        this.setState({ searchText: e.target.value });
    };

    handleSearchSubmit = () => {
        this.queryFilteredData();
    };

    componentDidMount = () => {
        this.queryFilteredData();
    };

    // Should be implemented in Redux Thunk later
    componentDidUpdate = (prevState) => {
        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevState.filters)) {
            this.setState(
                () => (
                    { ifSelected: true }
                ));
            this.fetchCall();
        }
    };

    fetchCall = () => {
        this.timeOut = setTimeout(() => {
            this.queryFilteredData();
            this.setState(
                () => (
                    { ifSelected: false }
                ));
        }, FILTER_DELAY);
    };

    stopFetch = () => {
        clearTimeout(this.timeOut);
    };

    paginate = (number) => {
        this.queryFilteredData(number);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    render = () => {
        return (
            <ListContainer loading={this.state.loading}>
                <Loader isVisible={this.state.loading} />
                <Header routes={this.props.history} />
                <ContentContainer>
                    <BreadcrumbContainer>
                        <Breadcrumbs />
                        <TextButton href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">Share Additional Data</TextButton>
                    </BreadcrumbContainer>

                    <TitleRowContainer>
                        <TopicTitle>Vaccine Availability</TopicTitle>

                        {/* Tab Switch */}
                        <TabSwitch pageType={`licensedProducts`} tabSwitchOn={'list'} insights />
                    </TitleRowContainer>

                    <Tabs stats={this.state.stats}/>

                    {/* Statistical Summary Grid */}
                    <FlexContainer>
                        <SearchBar>
                            <Input
                                value={this.state.searchText}
                                onChangeCallback={this.handleSearchInput}
                                placeholder="Search by Vaccine Name or Keyword"
                                keyDownCallback={this.handleSearchSubmit}
                            />
                            <SubmitBtn onClick={this.handleSearchSubmit}>
                                <BiSearch />
                                <span>Search</span>
                            </SubmitBtn>
                        </SearchBar>

                        {/* Filters Row */}
                        <FiltersRow
                            routes={this.props.history}
                            setTimeOutVaccine={this.fetchCall}
                            clearTimeOutVaccine={this.stopFetch}
                            ifSelected={this.state.ifSelected}
                        />

                        <main style={{marginTop: "2vh"}}>{this.generateTrialCards()}</main>

                        <Pagination
                            numberOfPages={this.state.totalPage}
                            currentPage={this.state.currentPage}
                            paginate={this.paginate}
                        />

                    </FlexContainer>
                </ContentContainer>

                

                <Footer relative={this.state.vaccines.length > 0} />

            </ListContainer>
        )
    };

    generateTrialCards = () => {
        const tagMap = {
            Type: "Vaccine_type",
            Manufacturer: "Manufacturer",
            WHO_Prequalified: "PQ",
        };

        return this.state.vaccines.length > 0 ? 
            (
                this.state.vaccines.map((item, index) => {
                    return (
                    <CardWrapperVaccine>
                        <div>
                            <h1>{item[1]["Product"]}</h1>
                            <span>
                                <DropdownPDF Data={item[1]["Links"]} />
                            </span>
                        </div>
                        <TagsWrapper>
                            { Object.keys(tagMap).map((key, index) => {
                                return (
                                    <Tags key={index} data={item[1][tagMap[key]]} tagsKey={key} />
                                );
                            }) }
                            <TagsWrapperVaccine>
                                <p>Countries</p>
                                <div>
                                {
                                    Object.entries(item[1]["Links"]).map(([country, link]) => {
                                        return <p key={country}>{country}</p>
                                    })
                                }
                                </div>
                            </TagsWrapperVaccine>
                        </TagsWrapper>
                    </CardWrapperVaccine>
                    )
                })
            ) : (
                <h1>No Vaccines Returned for the Given Filters</h1>
            )
    };
};

const mapStateToProps = (state) => {
    return {
        filters: state.vaccineFilter.filters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccineAvailabilityList);