import { ViewMore } from "pages/ClinicalTrials/style.clinicalTrials";
import styled from "styled-components";
import { gridItem, viewMoreStyle, cardWrapperStyle, } from 'utils/Static/static.css';

export const FundingContainer = styled.section`
    width:100%;
    height: 100%;
    min-height: 100vh;
    
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    display: flex;
    flex-direction: column;
`

export const FundingGrid = styled.main`

    display: grid;
    flex-grow: 1;

    grid-template-columns: repeat(20, 1fr);
    /* grid-template-rows: repeat(15, 3.94%); */
    grid-template-rows:  ${props => props.setHeight? `repeat(24, 2.4%)` : `repeat(15, 3.94%)` };

    margin: 1.5vh 1.72vw 0px;
    /* height: 72vh; */
    height: ${props => props.setHeight? `115vh` : `72vh` };
    gap: 2vh;
`
export const FundingGeneralInfo = styled.section`
    /* ${gridItem}
    grid-row: 1 / 3;
    grid-column: 1 / 12;
    overflow: auto;
    
    span {
        height: 3vh;
        font-size: 24px;
    } */

    ${gridItem}
    grid-row: 1 / 3;
    grid-column: 1 / 8;

    &:hover {
        background-color: #DBEAF3;
        cursor: pointer;
    }
`

export const SummaryTitleFunding = styled.section`
    align-self: flex-start;
    font-size: ${props => props.theme.heading2.fontSize};
    font-weight: ${props => props.theme.heading2.fontWeight};
    color: #23647C;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`

export const TotalFunding = styled.section`
    ${gridItem}
    grid-row: 3 / 7;
    grid-column: 1 / 8; 
`
export const TotalFundingAmount = styled.div`
    width: max-content;
    padding: 1.6vh 2.87vw;
    background-color: #23647C;;
    color: white;
    text-align: center;

    font-size: ${props => props.theme.heading1.fontSize};
    /* text-align: center; */
`

export const MapContainer = styled.section`
    ${gridItem}
    grid-row: 7 / 16;
    grid-column: 1 / 8; 

    position: relative;
`

export const MapContainer2 = styled.section`
    ${gridItem}
    grid-row: 16 / 25;
    grid-column: 1 / 8; 

    position: relative;
`

export const ByFunder = styled.section`
    ${gridItem}
    grid-row: 1 / 9;
    grid-column: 8 / 15; 

`

export const TableContainer = styled.table`
    width: 100%;
    box-sizing: border-box;
    max-height: 27vh;

    height: ${props => props.calcHeight ? '100%' : '27vh'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;
    font-size: ${props => props.theme.heading6.fontSize};

    & td{
        border: 1px solid #DBEAF3;
    }

`

export const TableContainerRecipient = styled.table`
    width: 100%;
    box-sizing: border-box;
    max-height: 25vh;

    height: ${props => props.calcHeight ? '100%' : '24vh'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;
    font-size: ${props => props.theme.heading6.fontSize};

    & td{
        border: 1px solid #DBEAF3;
    }

`

export const TableHead = styled.thead`
    width:100%;

    & tr{
        background-color: #23647C;
        color: #FAFAF9;
        text-align: left;
        width: fit-content;
    }
    & tr > th {
        padding: .4vh .35vw;
        font-weight: normal;
        white-space: nowrap;
    }
`

export const TableBody = styled.tbody`
    & tr{
        text-align: left;
        color: #164050;
    }
    & tr > td {
        padding: .2vh .2vw;
        font-weight: normal;
    }

    & tr > td:not(:last-of-type) {
        text-align: center;
    }

    ${props => props.selectable ? '\
            & tr > td:last-child { \
                color: #23647C; \
            } \
            & tr > td:last-child:hover { \
                cursor: pointer; \
                color: #468AAB; \
            } \
        ' : ''
    }

    & tr:nth-of-type(even){
        background-color: #DBEAF3;
    }
`
export const TableDataDiv = styled.div`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const FundingByYear = styled.section`
    ${gridItem}
    grid-row: ${props => props.moveChart ? `16 / 23` : `9 / 16`};
    grid-column: 8 / 15; 
`

export const ProjectByYear = styled.section`
    ${gridItem}
    grid-row: 9 / 16;
    grid-column: 8 / 15; 
`

export const ProjectGrant = styled.section`
    ${gridItem}
    grid-row: 1 / 7;
    grid-column: 15 / 21; 
`

export const FundingGrant = styled.section`
    ${gridItem}
    grid-row: 7 / 14;
    grid-column: 15 / 21; 
`

export const ProjectRecipient = styled.section`
    ${gridItem}
    grid-row: ${props => props.moveTable ? `14 / 21` : `7 / 14` };
    grid-column: 15 / 21; 
`
export const FundingViewMore = styled.button`
    ${viewMoreStyle}
    grid-row: ${props => props.moveButton ? `21 / 23` : `14 / 16` };
    grid-column: 15 / 21;

    ${props => props.disabled ? ' \
        color: #C4C4C4; \
        border: 1px solid #C4C4C4; \
        pointer-events: none; \
    ' : ''
    }
`
// export const FundingType = styled.section`
//     ${gridItem}
//     grid-row: 3 / 8;
//     grid-column: 7 / 12;
//     // padding: 0 20px;
// `
// export const FunderCategory = styled.section`
//     ${gridItem}
//     grid-row: 3 / 8;
//     grid-column: 6 / 12;
// `
// export const ProjectBeingFunded = styled.section`
//     ${gridItem}
//     grid-row: 1 / 13;
//     grid-column: 12 / 17;
//     overflow-y: scroll;
// `
// export const Countries = styled.section`
//     ${gridItem}
//     grid-row: 8 / 15;
//     grid-column: 7 / 12;
// `
// export const Year = styled.section`
//     ${gridItem}
//     grid-row: 3 / 8;
//     grid-column: 1 / 7;
// `
// export const FundedBy = styled.section`
//     ${gridItem}
//     grid-row: 8 / 15;
//     grid-column: 1 / 7;
// `
// export const FundingViewMore = styled.section`
//     ${viewMoreStyle}
//     grid-row: 13 / 15;
//     grid-column: 12 / 17;
// `
// export const ProjectsList = styled.div`
//     display: grid;
//     grid-template-columns: 1fr 3fr;
//     grid-gap: 10px;
//     gap: 1.5vh;
//     margin: 0 auto;
//     padding: 2.5vh 0;
//     width: 25vw;
// `

// export const FundingAmount = styled.div`
//     font-size: 18px;
//     font-weight: 800;
//     text-align: left;
//     color: rgba(44, 197, 218, 0.7);
// `
// export const ProjectName = styled.div`
//     font-size: 18px;
//     font-weight: 500;
//     color: rgba(79,117,153,.9);
//     text-overflow: ellipsis;
//     overflow: hidden;
// `


export const FundingCardWrapper = styled.section`
    ${cardWrapperStyle}

    & > h1 {
        margin: 0 0 .8vh 0;
        color: #164050;
        font-weight: ${props => props.theme.heading3.fontWeight};
        font-size: ${props => props.theme.heading3.fontSize};
    }
`