import React, { useState } from 'react';
import { SummaryTitle, TableSingleDiv } from "pages/style.pages";
import { PagedTable } from 'components';


export default function ByRecipientTile({ projectsByRecipientData }) {

    const [ currentPage, setCurrentPage ] = useState(1);

    const NUM_OF_ROWS = 5;

    const pageCountofTable = Math.ceil(projectsByRecipientData.length / NUM_OF_ROWS);

    return (
        <>
            <SummaryTitle>
                {
                    pageCountofTable > 1 ?
                        `Projects by recipient (${currentPage}/${pageCountofTable})`
                        : `Projects by recipient`
                }
            </SummaryTitle>
            <PagedTable
                header={["no. projects", "Total Funding", "Recipient Name"]}
                data={projectsByRecipientData}
                currentPage={currentPage}
                pageCount={pageCountofTable}
                numOfRows={NUM_OF_ROWS}
                handleTablePage={(pageNumber) => { setCurrentPage(pageNumber) }}
                isBordered={false}
            />
            {
                projectsByRecipientData.length == 1 &&
                    <TableSingleDiv>
                        <p>
                            More Information Coming Soon
                        </p>
                    </TableSingleDiv>
            }
        </>
        
    )
}
