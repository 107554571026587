import { PaginationWrapper, PageClick} from '../styles';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

const ComponentTablePagination = (props) => {
    return(
        <PaginationWrapper>
            {props.numberOfPage != 1 && <PageClick disabled={props.currentPage === 1} onClick={() => { props.handlePageClick(props.currentPage - 1) }}><BsFillCaretLeftFill /></PageClick>}
            <div style={{flexGrow: '1', alignSelf:'flex-start'}}>{props.children}</div>
            {props.numberOfPage != 1 && <PageClick disabled={props.currentPage == props.numberOfPage} onClick={() => { props.handlePageClick(props.currentPage + 1) }}><BsFillCaretRightFill /></PageClick>}
        </PaginationWrapper>
    );
}

export default ComponentTablePagination;