import { BackdropContainer } from "./styles";

const Backdrop = (props) => {
    return (
        <BackdropContainer
            onClick={props.changeBackdropState}
        />
    );
}

export default Backdrop;