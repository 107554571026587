import React, { useState, useEffect } from 'react';
import { HomePage, InfoArea, InfoInput, SolidButton, TitleContainer, WiserLogo, CompanyLogo, InputContainer, Prompt, DiseaseTableContainer, DiseasesItemContainer, DiseaseItem, BorderedTextButton, PackageCard } from './style.Auth';
import { Dropdown, Element } from './components/Dropdown';
import _ from "lodash";



export default function DiseaseSelection(props) {

    const { values, handleChange } = props;

    const { diseases } = values;

    useEffect(() => {
        const fetchData = async () => {
            const rawResponse = await fetch (
                "./diseaseSelection.json", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            );
            // Receive filtered results
            const diseaseResult = await rawResponse.json();

            // Set up selection states for diseases
            let newDiseaseObj = _.cloneDeep(diseaseResult);
            Object.keys(diseaseResult).map(disease => {
                newDiseaseObj[disease]["selected"] = false;
                const diseases = newDiseaseObj[disease]["diseases"];
                Object.keys(diseases).map(disease => {
                    diseases[disease]["selected"] = false;
                });
            });
            handleChange("diseases", newDiseaseObj);
        };

        fetchData()
            .catch(console.error);
    }, []);

    const gotoPreviousStep = e => {
        e.preventDefault();
        props.prevStep();
    };

    const gotoNextStep = e => {
        e.preventDefault();
        props.nextStep();
    };


    const handleItemChecked = (label) => {
        const newParentDisease = {...diseases[label], ["selected"]: !diseases[label]["selected"] };
        const newDiseaseObj = { ...diseases, [label]: newParentDisease };

        handleChange("diseases", newDiseaseObj);
    };

    const handleSubItemChecked = (label, subLabel) => {
        const newParentDisease = {
            ...diseases[label], 
            ["diseases"]: {
                ...diseases[label]["diseases"],
                [subLabel]: {
                    ...diseases[label]["diseases"][subLabel],
                    ["selected"]: !diseases[label]["diseases"][subLabel]["selected"]
                }
            } };
        const newDiseaseObj = { ...diseases, [label]: newParentDisease };

        handleChange("diseases", newDiseaseObj);
    };

    return (
        <HomePage>
            <InfoArea>
                <Prompt>
                    <span>
                        Help us customize your dashboard
                    </span>
                    <span>
                        Which disease areas do you primarily work with? (Choose as many as you like)
                    </span>
                </Prompt>
                
                <DiseaseTableContainer>
                    { getDiseaseList() }
                </DiseaseTableContainer>
                
                <SolidButton onClick={gotoNextStep}>Perfect! Continue</SolidButton>
            </InfoArea>
        </HomePage>
    )

    function getDiseaseList() {
        return Object.entries(diseases).map(prtDisease => {
            const [ label, info ] = prtDisease;
            return (
                <Dropdown label={label} info={info} handleCheckboxClick={handleItemChecked}>
                    {
                        Object.entries(info.diseases).map(disease => {
                            const [ subLabel, subInfo ] = disease;
                            return <Element label={label} subLabel={subLabel} info={subInfo} handleCheckboxClick={handleSubItemChecked}/>
                        })
                    }
                </Dropdown>
            )
        })
    }
};