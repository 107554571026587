import { theme } from './static.theme';

export const gridItem = `
    border-radius: 15px;
    text-align: left;
    font-weight: 300;
    color: #468AAB;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    background-color: #FAFAF9;
`  // Grid item configuration

export const viewMoreStyle = `
    font-size: 1.083rem;
    font-weight: 300;
    color: #468AAB;
    background: none;
    border: 2px solid #468AAB;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    
    &:hover {
        cursor: pointer;
        color: #FAFAF9;
        background-color: #468AAB;
    }
`
export const cardWrapperStyle = `
    padding: 3vh 4vw;
    box-shadow: 0px 2px 2px rgb(196 196 196 / 20%);
    border-radius: 18px;
    margin-bottom: 2.0vh;
    width: 70vw;
    box-sizing: border-box;
    background-color: #FAFAF9;

    & > h2 {
        margin: 0 0 .8vh 0;;
        color: #6C7073;
        font-weight: ${theme.bodyText.fontWeight};
        font-size: ${theme.bodyText.fontSize};
    }

    & > p {
        margin: 0 0 .8vh 0;;
        color: #164050;
        font-weight: ${theme.bodyText.fontWeight};
        font-size: ${theme.bodyText.fontSize};
    }
`

export const homeInputStyle = `
    border: none; 
    outline: none; 
    height: 4vh; 
    padding-left: 20px; 
    color: #164050; 

    background-color: inherit; 
    cursor: pointer;
`

export const filterItemStyle = `
    padding: 1.5vh 1vw;
    background-color: ${props => props.selected ? '#468aab' : '#fafaf9'};
    box-shadow: 3px 4px 20px rgba(88, 158, 195, 0.15);
    border-radius: 30px;
    margin-right: 10px;
    min-width: 9vw;
    max-width: 15vw;
    text-align: center;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.selected ? '#468aab' : '#ffffff'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;

    &:div {
        line-height: 1.5;
    }

    &:hover {
        background-color: #468aab;
        color: white;
        cursor: ${props => props.selected ? 'auto' : 'pointer'};
    }
`

export const TextButton = `
    color: #468AAB;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        color: #23647C;
    }
`

export const BorderedTextButtonStyle = `
    text-align: center;
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};
    padding: 4px 15px;
    border-radius: 15px;
`