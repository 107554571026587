import { ChartData, ChartsHead, ComponentWrapper2 } from "./style.graphComponent";

const LineChartComponent = ({ pageType , isBordered, dataValue, color, lable}) => {
    return (
        <ComponentWrapper2>
            <ChartsHead>
                <span>All &nbsp;</span>
                <p>{dataValue}</p>
                <span>&nbsp;{`${pageType} clinical trials occurred in`}</span>
            </ChartsHead>
            <ChartData
                isBordered={isBordered}
                backgruondColor={color}
            >
                {lable}
            </ChartData>

        </ComponentWrapper2>
    );
};

export default LineChartComponent;