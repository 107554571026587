import React from "react";

const Checkbox = ({ id, value, onChange }) => {
    return (
        <label>
            <input type="checkbox" checked={value} onChange={() => {onChange(id)}} />
        </label>
        );
};

export default Checkbox;