import { useState, useEffect } from 'react';
import { TabsRow, TabsLabel } from 'components/styles';
import { processNumberString } from "utils/Function/number";

const Tabs = (props) => {
    const [stats, setStats] = useState(() => ({
        Prequalified: "20",
        Vaccine_Availability: "12"
    }));

    return (
        <TabsRow>
            {
                props.linkStats && props.linkStats.length > 0 ?
                    props.linkStats.map((item, index) => {
                        const link = Object.entries(item);
                        const [ label, value ] = link[0];
                        return (
                            <TabsLabel key={index}>
                                <p>
                                    <a href={`${value}`} target="_blank">
                                        {label}
                                    </a>
                                </p>
                            </TabsLabel>
                        );
                    })    
                    :<div></div>
            }
            {props.stats ?
                Object.entries(props.stats).map((value, index) => {
                    return (
                        <TabsLabel key={index}>
                            <p>
                                <span style={{ fontWeight: '600' }}>
                                    { processNumberString(value[1] ?? 0) }
                                </span>
                                &nbsp;&nbsp;
                                { value[0].split('_').join(" ") }
                            </p>
                        </TabsLabel>
                    )
                }) : (
                    <TabsLabel>
                        <p>
                            <span style={{ fontWeight: '600' }}>
                                23
                            </span>
                            &nbsp;&nbsp;
                            Prequalified
                        </p>
                    </TabsLabel>
                )
            }

        </TabsRow>
    );
}

export default Tabs;