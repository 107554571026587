import { configureStore } from '@reduxjs/toolkit';

import clinicalTrialsFilterReducer from 'store/clinicalTrials/filtersReducer';
import clinicalTrialsOptionReducer from 'store/clinicalTrials/optionsReducer';

import vaccineFiltersReducer from "store/vaccineAvailability/filtersReducer";
import vaccineOptionsRducer from "store/vaccineAvailability/optionsReducer";

import whoFilterReducer from 'store/whoPq/filtersReducer';
import whoOptionReducer from 'store/whoPq/optionsReducer';

import fundingFilterReducer from 'store/funding/filtersReducer';
import fundingOptionReducer from 'store/funding/optionsReducer';

import organisationFilterReducer from 'store/organisation/filtersReducer';
import organisationOptionReducer from 'store/organisation/optionsReducer';


const store = configureStore ({
    reducer: {
        vaccineFilter: vaccineFiltersReducer,
        vaccineOptions: vaccineOptionsRducer,

        clinicalFilter: clinicalTrialsFilterReducer,
        clinicalOptions: clinicalTrialsOptionReducer,

        whoFilter: whoFilterReducer,
        whoOptions: whoOptionReducer,

        fundingFilter: fundingFilterReducer,
        fundingOptions: fundingOptionReducer,

        organisationFilter: organisationFilterReducer,
        organisationOptions: organisationOptionReducer,
    }
});

export default store;