import React, { useEffect, useReducer } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { OrgCodeAuth, SignupController, AccountInfo, PurchasedItems, DiseaseSelection, LocationSelection, LogInForm } from "pages/Auth";
import { Home } from "pages/Dashboard";
import { LicensedProduct_Graph, LicensedProduct_List, WHOprequalified } from "pages/LicensedProducts";
import { AllOrganisations, Organisation } from "pages/Organizations";
import { Funding_Graphs, Funding_List } from "pages/Funding";

import { AuthProvider } from "contexts/AuthContext";
import PrivateRoute from "components/PrivateRoute";


export function App() {

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path="/login" element={ <LogInForm /> } />
                    <Route exact path="/signup" element={ <SignupController /> } />
                    
                    <Route path="/" element={ <PrivateRoute><Home /></PrivateRoute> } />

                    <Route exact path="/licensedProducts" element={ <PrivateRoute><LicensedProduct_Graph /></PrivateRoute> } />
                    <Route exact path="/licensedProducts/list" element={ <PrivateRoute><LicensedProduct_List /></PrivateRoute> } />
                    <Route exact path="/licensedProducts/whoPrequalified" element={ <PrivateRoute><WHOprequalified /></PrivateRoute> } />

                    <Route path="/organisations" exact element={ <PrivateRoute><AllOrganisations /></PrivateRoute> } />
                    <Route path="/organisations/:id" element={ <PrivateRoute><Organisation /></PrivateRoute> } />

                    <Route path="/funding" exact element={ <PrivateRoute><Funding_Graphs /></PrivateRoute> } />
                    <Route path="/funding/list" exact element={ <PrivateRoute><Funding_List /></PrivateRoute> } />
                </Routes>
            </AuthProvider>
        </Router>
    );
}