import React from "react";


class Input extends React.Component {

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this); // better performance in complex
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.props.keyDownCallback();
        }
    };

    render() {
        return (
            <input
                className={this.props.className}
                type={this.props.type}
                value={this.props.value}
                onChange={this.props.onChangeCallback}
                placeholder={this.props.placeholder}
                onKeyDown={this.handleKeyDown} 
            />
        )
    };
};

export default Input;