import React, { Component } from "react";
import Filters from "./Filters";

import { updateOptions } from 'store/whoPq/optionsReducer';
import { connect } from 'react-redux';


import { VscTriangleUp, VscTriangleDown } from "react-icons/vsc";

import { API_PORT, API_PATH, API_MODE } from "services/libraryAPI";

import { Loader, Header, Footer, Pagination, Breadcrumbs, Tabs, Input } from "components";

import { ContentContainer, FlexContainer, SearchBar, OverlayContainer, TitleRowContainer, TopicTitle, SubmitBtn } from 'pages/style.pages';
import { WhoPrequalified, Table, Tbody, Thead, TdDiv, ThDiv } from './style.whoPrequalified';

import { BiSearch } from "react-icons/bi";


class WHOprequalified extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // filters: store.getState().whoPqModule.filters,
            loading: false,
            searchText: "",
            PQ_Table: [],
            PQ_Stats: {},
            currentPage: 1,
            totalPage: 1,
        };


        this.sortInDescendingString = this.sortInDescendingString.bind(this);
        this.sortInAscendingString = this.sortInAscendingString.bind(this);
        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.paginate = this.paginate.bind(this);
    };

    queryFilteredData = (newPage=this.state.currentPage) => {

        const pageToQuery = (newPage == this.state.currentPage ? 1 : newPage);

        this.setState(
            () => ({
            loading: true
            }), 
            async () => {
                let filters = JSON.parse(JSON.stringify(this.props.filters));
                filters["Search"] = this.state.searchText;

                try {
                    const rawResponse = await fetch(`${API_MODE}://${API_PATH}:${API_PORT}/v1/whoPqVaccine`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({filters, "pageNum" : pageToQuery})
                    });

                    // Receive filtered results
                    const results = await rawResponse.json();
                    
                    let types = [];
                    for (const entry of results.options["Type"]) {
                        types.push(entry);
                    }
                    this.handleOptionUpdate("Type", types);

                    let manufacturerList = [];
                    for (const entry of results.options["Manufacturer"]) {
                        manufacturerList.push(entry);
                    }
                    this.handleOptionUpdate("Manufacturer", manufacturerList);

                    let presentationList = [];
                    for (const entry of results.options["Presentation"]) {
                        presentationList.push(entry);
                    }
                    this.handleOptionUpdate("Presentation", presentationList);

                    let pharmaceuticalFormList = [];
                    for (const entry of results.options["PharmaceuticalForm"]) {
                        pharmaceuticalFormList.push(entry);
                    }
                    this.handleOptionUpdate("PharmaceuticalForm", pharmaceuticalFormList);

                    let noOfDoses = [];
                    for (const entry of results.options["Doses"]) {
                        noOfDoses.push(entry);
                    }

                    noOfDoses.sort(function (a, b) { return a[0] - b[0] });
                    this.handleOptionUpdate("NoOfDoses", noOfDoses);

                    this.setState({
                        loading: false,
                        PQ_Table: results.content,
                        PQ_Stats: results.stats,
                        currentPage: pageToQuery,
                        totalPage: results.totalPageCount
                    });
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                }
        });
    };

    // handleTypesUpdate(types) {
    //     const action = getOptionUpdateAction("Type", types);
    //     store.dispatch(action);
    // };

    // handleManufacturerListUpdate(manufacturers) {
    //     const action = getOptionUpdateAction("Manufacturer", manufacturers);
    //     store.dispatch(action);
    // };

    // handlePresentationListUpdate(presentations) {
    //     const action = getOptionUpdateAction("Presentation", presentations);
    //     store.dispatch(action);
    // };

    // handlePharmaceuticalFormListUpdate(pharmaceuticalForms) {
    //     const action = getOptionUpdateAction("PharmaceuticalForm", pharmaceuticalForms);
    //     store.dispatch(action);
    // };

    // handleNoOfDosesUpdate(noOfDoses) {
    //     const action = getOptionUpdateAction("NoOfDoses", noOfDoses);
    //     store.dispatch(action);
    // };

    handleOptionUpdate(option, value) {
        this.props.updateOptions({optionKey: option, optionValue: value})
    }


    sortInAscendingDoses() {
        const sortedEnteries = this.state.PQ_Table;
        sortedEnteries.sort((a, b) => a.NoOfDoses - b.NoOfDoses);
        this.setState({ PQ_Table: sortedEnteries })
    }

    sortInDescendingDoses() {
        const sortedEnteries = this.state.PQ_Table;
        sortedEnteries.sort((a, b) => b.NoOfDoses - a.NoOfDoses);
        this.setState({ PQ_Table: sortedEnteries })
    }

    sortInAscendingDate() {
        const sortedEnteries = this.state.PQ_Table;
        sortedEnteries.sort(function (a, b) {
            const x = a.PrequalifiedDate.split('/').reverse().join('');
            const y = b.PrequalifiedDate.split('/').reverse().join('');
            return x > y ? 1 : x < y ? -1 : 0;
        });
        this.setState({ PQ_Table: sortedEnteries })
    }

    sortInDescendingDate() {
        const sortedEnteries = this.state.PQ_Table;
        sortedEnteries.sort(function (a, b) {
            const x = a.PrequalifiedDate.split('/').reverse().join('');
            const y = b.PrequalifiedDate.split('/').reverse().join('');
            return x > y ? -1 : x < y ? 1 : 0;
        });
        this.setState({ PQ_Table: sortedEnteries })
    }

    sortInAscendingString(e) {
        const sortedEnteries = this.state.PQ_Table;
        sortedEnteries.sort((a, b) => {
            if (a[e] && b[e]) {
                let x = a[e].toLowerCase();
                let y = b[e].toLowerCase();

                if (x < y)
                    return -1;
                if (x > y)
                    return 1;
            }
            return 0;
        });
        this.setState({ PQ_Table: sortedEnteries })
    }

    sortInDescendingString(e) {
        const sortedEnteries = this.state.PQ_Table;
        sortedEnteries.sort((a, b) => {
            if (a[e] && b[e]) {
                let x = a[e].toLowerCase();
                let y = b[e].toLowerCase();

                if (x < y)
                    return 1;
                if (x > y)
                    return -1;
            }
            return 0;
        });
        this.setState({ PQ_Table: sortedEnteries })
    }

    handleSearchInput(e) {
        this.setState({ searchText: e.target.value });
    };

    handleSearchSubmit() {
        this.queryFilteredData();
    };

    componentDidMount() {
        this.queryFilteredData();
    };

    paginate = (number) => {
        this.queryFilteredData(number);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    render() {
        return (
            <WhoPrequalified >
                <Loader isVisible={this.state.loading} />
                <Header routes={this.props.history} />

                <ContentContainer>
                    <Breadcrumbs />

                    <TitleRowContainer>
                        <TopicTitle>WHO Prequalified</TopicTitle>
                    </TitleRowContainer>

                    <Tabs stats={this.state.PQ_Stats} />

                    <FlexContainer>

                        {/* Search Bar */}
                        <SearchBar>
                            <Input
                                value={this.state.searchText}
                                onChangeCallback={this.handleSearchInput}
                                placeholder="Search by Vaccine Name or Keyword"
                                keyDownCallback={this.handleSearchSubmit}
                            />
                            <SubmitBtn onClick={this.handleSearchSubmit}>
                                <BiSearch />
                                <span>Search</span>
                            </SubmitBtn>
                        </SearchBar>

                        <Filters updateFilters={this.queryFilteredData} />
                        <div style={{ boxSizing: 'border-box', width: '120%', height: '100%', overflowX: 'auto', marginLeft: '-10%' }}>
                            <Table>
                                <Thead>
                                    <tr>
                                        <th>
                                            <ThDiv>
                                                <span>Date</span>
                                                <span >
                                                    <VscTriangleUp onClick={this.sortInAscendingDate.bind(this)} />
                                                    <VscTriangleDown onClick={this.sortInDescendingDate.bind(this)} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                        <th>
                                            <ThDiv>
                                                <span>Commercial Name</span>
                                                <span >
                                                    <VscTriangleUp onClick={() => this.sortInAscendingString('CommercialName')} />
                                                    <VscTriangleDown onClick={() => this.sortInDescendingString('CommercialName')} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                        <th>
                                            <ThDiv>
                                                <span>Type</span>
                                                <span >
                                                    <VscTriangleUp onClick={() => this.sortInAscendingString('Type')} />
                                                    <VscTriangleDown onClick={() => this.sortInDescendingString('Type')} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                        <th>
                                            <ThDiv>
                                                <span>Manufacturer</span>
                                                <span >
                                                    <VscTriangleUp onClick={() => this.sortInAscendingString('Manufacturer')} />
                                                    <VscTriangleDown onClick={() => this.sortInDescendingString('Manufacturer')} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                        <th>
                                            <ThDiv>
                                                <span>Presentation</span>
                                                <span >
                                                    <VscTriangleUp onClick={() => this.sortInAscendingString('Presentation')} />
                                                    <VscTriangleDown onClick={() => this.sortInDescendingString('Presentation')} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                        <th>
                                            <ThDiv>
                                                <span>Doses</span>
                                                <span >
                                                    <VscTriangleUp onClick={this.sortInAscendingDoses.bind(this)} />
                                                    <VscTriangleDown onClick={this.sortInDescendingDoses.bind(this)} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                        <th>
                                            <ThDiv>
                                                <span>Pharmaceutical Form</span>
                                                <span >
                                                    <VscTriangleUp onClick={() => this.sortInAscendingString('PharmaceuticalForm')} />
                                                    <VscTriangleDown onClick={() => this.sortInDescendingString('PharmaceuticalForm')} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                        <th>
                                            <ThDiv>
                                                <span>Responsible NRA</span>
                                                <span >
                                                    <VscTriangleUp onClick={() => this.sortInAscendingString('ResponsibleNRA')} />
                                                    <VscTriangleDown onClick={() => this.sortInDescendingString('ResponsibleNRA')} />
                                                </span>
                                            </ThDiv>
                                        </th>
                                    </tr>
                                </Thead>
                                <Tbody>
                                    {this.getTdEnteries()}
                                </Tbody>
                            </Table>
                        </div>

                        <Pagination
                            numberOfPages={this.state.totalPage}
                            currentPage={this.state.currentPage}
                            paginate={this.paginate}
                        />

                    </FlexContainer>
                </ContentContainer>


                

                <Footer relative={this.state.PQ_Table.length > 0} />
            </WhoPrequalified>
        )
    };

    getTdEnteries = () => {

        return (
            this.state.PQ_Table.length > 0 ?
                this.state.PQ_Table.map((data, index) => {
                    return (
                        <tr key={data._id}>
                            <td><TdDiv>{data.PrequalifiedDate}</TdDiv></td>
                            <td><TdDiv>{data.CommercialName}</TdDiv></td>
                            <td><TdDiv>{data.Type}</TdDiv></td>
                            <td><TdDiv>{data.Manufacturer}</TdDiv></td>
                            <td><TdDiv>{data.Presentation}</TdDiv></td>
                            <td><TdDiv>{data.NoOfDoses}</TdDiv></td>
                            <td><TdDiv>{data.PharmaceuticalForm}</TdDiv></td>
                            <td><TdDiv>{data.ResponsibleNRA}</TdDiv></td>
                        </tr>
                    );
                })
                : <></>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        filters: state.whoFilter.filters,
        // filterOptions: state.vaccineOptions.options,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WHOprequalified);