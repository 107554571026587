import React, { Component } from "react";
import {
    FilterWrapper,
    FilterLabel,
    FilterIcon,
    FilterInput, 
    OptionsMenu,
    OptionItem
} from "./style";

import { connect } from "react-redux";
import { filterActions } from 'store/vaccineAvailability/filtersReducer';


class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterState: false,
        };

        this.handleFilterSelect = this.handleFilterSelect.bind(this);
        this.handleFilterDeselect = this.handleFilterDeselect.bind(this);
    };

    close = () => {
        this.setState(() => ({
            filterState: false,
        }));
    };

    componentDidUpdate(){
        const { filterState } = this.state;

        setTimeout(() => {
            if (filterState) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0)
    };

    handleFilterSelect = (key, value) => {
        this.setState(() => ({
            filterState: false
        }), () => {
            this.props.changeFilter({ key: key, value: value });
        });
    };

    handleFilterDeselect = (key) => {
        this.setState(() => ({
            filterState: false
        }), () => {
            this.props.deselectFilter({ key: key });
        });
    };

    changeFilterState = () => {
        this.setState((prevState) => ({
            filterState: !prevState.filterState,
        }), () => {
            if (this.state.filterState === false) {
                //Over Here We will call SetTimeOut only if there is a change in filterState;
                if (this.props.ifSelected)
                    this.props.setTimeOutVaccine();
            } else {
                this.props.clearTimeOutVaccine();
            }
        });
    };

    optionRemoveMap = new Map([
        ["Location", "All Locations"],
        ["Type", "All Types"],
        ["Manufacturer", "All Manufacturers"],
        ["Who_PQ", "All Vaccines"]
    ]);

    render = () => {
        const { filterLabel, filterName, filterValue, options } = this.props;

        const ifOpen = this.state.filterState, ifApplied = filterValue !== "";

        return (
            <FilterWrapper ifOpen={ifOpen} ifApplied={ifApplied}>
                <FilterLabel onClick={this.changeFilterState}>
                    <FilterInput
                        name={filterLabel}
                        placeholder={filterLabel}
                        type="text"
                        value={filterValue || filterLabel}
                        autocomplete={false}
                        readOnly={true}
                    />
                    <FilterIcon ifOpen={ifOpen} ifApplied={ifApplied}/>
                </FilterLabel>
                <OptionsMenu>
                    {
                        (ifApplied) &&
                        <OptionItem
                            key="cancel-option"
                            onClick={() => this.handleFilterDeselect(filterName)}
                            ifApplied={ifApplied}
                            ifSelectable={true}
                        >
                            <option>{this.optionRemoveMap.get(filterName)}</option>
                        </OptionItem>
                    }
                    {this.getFilterOptions(filterName, options, ifApplied)}
                </OptionsMenu>
            </FilterWrapper>
        )
    };


    getFilterOptions = (name, options, ifApplied) => {
        return options.map((entry) => {
            const [ option, selectable ] = entry;
            const ifSelectable = Boolean(selectable);
            return (
                <OptionItem
                    key={option}
                    onClick={
                        ifSelectable ?
                            () => this.handleFilterSelect(name, option)
                            : undefined
                    }
                    ifApplied={ifApplied}
                    ifSelectable={ifSelectable}
                >
                    <option value={option}>
                        {option}
                    </option>
                </OptionItem>
            )
        });
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
        deselectFilter:(obj) => dispatch(filterActions.deselectFilter(obj)),
    };
};

export default connect(null, mapDispatchToProps)(Filter);