import { TagsContainer, TagsContent, TagsHeading, TagsContentShort } from 'components/styles';

const Tabs = (props) => {

    return (
        props.data ?
            props.data.length > 34 ?
                <TagsContainer>
                    <TagsHeading>{props.tagsKey.split('_').join(' ')}</TagsHeading>
                    <TagsContent data-tooltip={props.data}>{props.data.slice(0, 20) + '...'}</TagsContent>
                </TagsContainer>
                :
                <TagsContainer>
                    <TagsHeading>{props.tagsKey.split('_').join(' ')}</TagsHeading>
                    <TagsContentShort>{props.data}</TagsContentShort>
                </TagsContainer>
            : <TagsContainer>
                <TagsHeading>{props.tagsKey.split('_').join(' ')}</TagsHeading>
                <TagsContentShort>Not available</TagsContentShort>
            </TagsContainer>
    );
}

export default Tabs;