import styled from "styled-components";
import { gridItem, media, BorderedTextButtonStyle } from 'utils/Static/index';


export const BorderedTextButton = styled.div`
    ${BorderedTextButtonStyle}
    border: 1px solid #468AAB;
    color: #468AAB;

    &:hover {
        border: 1px solid #23647C80;
        color: #468AAB80;
        cursor: pointer;
    }
`

export const HomeContainer = styled.div`
    width:100%;
    height: 100vh;
    
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    display: flex;
    flex-direction: column;
`

export const TopicTitle = styled.p`

    margin-top: 0.6vh;
    margin-bottom: 1.2vh;
    font-size: ${props=> props.theme.heading2.fontSize};
    font-weight: ${props=> props.theme.heading2.fontWeight};
    color: #164050;
`

export const Grid = styled.main`
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(19, 1fr);
    grid-template-rows: repeat(20, 4vh);
    margin: 1.5vh 1.72vw 0;
    gap: 2vh;

    height: 70vh;
`


export const LicensedProductsTile = styled.section`
    ${gridItem}
    grid-row: 2 / 11;
    grid-column: 1 / 7;
`

export const FundingTile = styled.section`
    ${gridItem}
    grid-row: 2 / 11;
    grid-column: 7 / 14;
`

export const OrganizationTile = styled.section`
    ${gridItem}
    grid-row: 2 / 11;
    grid-column: 14 / 20;
`

export const SummaryTitle = styled.span`
    align-self: flex-start;
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    color: #164050;

    width:100%;

    display:flex;
    align-items: center;
    justify-content: space-between;
`

export const BottomNav = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

export const TableContainerRecipient = styled.table`
    width: 100%;
    box-sizing: border-box;
    max-height: 25vh;

    height: ${props => props.calcHeight ? '100%' : '24vh'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;
    font-size: ${props => props.theme.heading6.fontSize};

    & td{
        border: 1px solid #DBEAF3;
    }
`

export const TableHead = styled.thead`
    width:100%;

    & tr {
        background-color: #23647C;
        color: #FAFAF9;
        text-align: left;
        width: fit-content;

    }
    & tr > th {
        padding: .5vh .4vw;
        font-weight: normal;
        white-space: nowrap;
    }
`


export const TableBody = styled.tbody`
    & tr {
        height: 3vh;
        text-align: left;
        color: #164050;
    }

    & tr > td {
        padding: .2vh .35vw;
        font-weight: normal;
    }

    & tr > td:first-of-type {
        text-align: center;
    }

    & tr > td:last-child {
        color: #23647C;
    }

    & tr > td:last-child:hover {
        cursor: pointer;
        color: #468AAB;
    }

    & tr:nth-of-type(even){
        background-color: #DBEAF3;
    }
`

export const TableDataDiv = styled.div`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`
export const ClinicalTrials = styled.section`
    ${gridItem}
    grid-row: 12 / 20;
    grid-column: 1 / 10;
`

export const Publications = styled.section`
    ${gridItem}
    grid-row: 12 / 20;
    grid-column: 10 / 20;
`