import React, { Component } from 'react';
import { Header, Footer, Pagination, TabSwitch, Breadcrumbs, Tabs, Tags, Input, Loader } from "components";

import { ListContainer, SearchBar, SubmitBtn, TitleRowContainer,TagsWrapper, TopicTitle, BreadcrumbContainer, TextButton, ContentContainer } from "pages/style.pages";
import { CardContainer, CardContent, FlexContainerManufacture, LearnMoreButton, OrganisationCard, TagsWrapperOrganisation } from '../style.organisation';

import FiltersRow from "../filter/FiltersRow";
import { BiSearch } from "react-icons/bi";

import { updateOptions } from 'store/organisation/optionsReducer';
import { connect } from 'react-redux';

import { API_PORT, API_PATH, API_MODE } from "services/libraryAPI";

import { FILTER_DELAY } from "utils/Constants";

import { Navigate } from "react-router-dom";


class AllOrganisations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            loading: false,
            searchText: "",
            currentPage: 1,
            totalPage: 9,
            ifSelected: false,
            data:[],
        };

        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.paginate = this.paginate.bind(this);
    }


    queryFilteredData = (newPage=this.state.currentPage) => {

        const pageToQuery = (newPage == this.state.currentPage ? 1 : newPage);

        this.setState (
            () => ({
                loading: true,
            }),
            async () => {
                let filters = JSON.parse(JSON.stringify(this.props.filters));
                
                // filters["Search"] = this.state.searchText;

                try {
                    const rawResponse = await fetch (
                        `${API_MODE}://${API_PATH}:${API_PORT}/v1/manufacturer/`,
                        {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({filters}),
                        }
                    );

                    // Receive filtered results
                    const results = await rawResponse.json();

                    // Update sponsors options
                    this.handleOptionUpdate("Location", results.options.locationOptions);
                    this.handleOptionUpdate("Type", results.options.typeOptions);

                    this.setState(() => ({
                        data: results.content,
                        loading: false,
                    }));
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                }
            }
        );
    };

    handleOptionUpdate = (option, value) => {
        this.props.updateOptions({optionKey: option, optionValue: value});
    };

    handleSearchInput = (e) => {
        this.setState({ searchText: e.target.value });
    };

    handleSearchSubmit = () => {
        this.queryFilteredData();
    };

    componentDidMount = () => {
        this.queryFilteredData();
    };

    // Should be implemented in Redux Thunk later
    componentDidUpdate = (prevState) => {
        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevState.filters)) {
            this.setState(
                () => (
                    { ifSelected: true }
                ));
            this.fetchCall();
        }
    };

    fetchCall = () => {
        this.timeOut = setTimeout(() => {
            this.queryFilteredData();
            this.setState(
                () => (
                    { ifSelected: false }
                ));
        }, FILTER_DELAY);
    };

    stopFetch = () => {
        clearTimeout(this.timeOut);
    };

    paginate = (number) => {
        this.queryFilteredData(number);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    clickOrganisationCard = (e) => {
        this.setState(() => ({
            redirectTo: e
        }))
    }

    render = () => {
        return (
            <ListContainer loading={this.state.loading}>
                <Loader isVisible={this.state.loading} />
                <Header routes={this.props.history} />
                <ContentContainer>
                    <BreadcrumbContainer>
                        <Breadcrumbs />
                        <TextButton href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">Share Additional Data</TextButton>
                    </BreadcrumbContainer>
                    

                    <TitleRowContainer>
                        <TopicTitle>Organizations</TopicTitle>

                        {/* Tab Switch */}
                        {/* <TabSwitch pageType={`vaccineAvailability`} tabSwitchOn={'list'} insights /> */}
                    </TitleRowContainer>

                    {/* <Tabs stats={this.state.stats}/> */}

                    {/* Statistical Summary Grid */}
                    <FlexContainerManufacture>
                        <SearchBar style={{margin: '2vh auto'}}>
                            <Input
                                // value={this.state.searchText}
                                // onChangeCallback={this.handleSearchInput}
                                placeholder="Search by Organization Name or Keyword"
                                // keyDownCallback={this.handleSearchSubmit}
                            />
                            <SubmitBtn
                                // onClick={this.handleSearchSubmit}
                            >
                                <BiSearch />
                                <span>Search</span>
                            </SubmitBtn>
                        </SearchBar>

                        {/* Filters Row */}
                        <div style={{ margin: '0 12vw'}}>
                            <FiltersRow
                                
                                routes={this.props.history}
                                setTimeOutVaccine={this.fetchCall}
                                clearTimeOutVaccine={this.stopFetch}
                                ifSelected={this.state.ifSelected}
                            />
                        </div>

                        <CardContainer>
                            {this.generateOrganisationCard()}
                        </CardContainer>

                        {/* <Pagination
                            numberOfPages={this.state.totalPage}
                            currentPage={this.state.currentPage}
                            paginate={this.paginate}
                        /> */}

                    </FlexContainerManufacture>
                </ContentContainer>

                <Footer relative={true} />
                {/* <Footer relative={this.state.vaccines.length > 0} /> */}

                {this.state.redirectTo && (
                    <Navigate to={`/organisations/${this.state.redirectTo}`} replace={true} />
                )}
            </ListContainer>
        );
    }

    generateOrganisationCard = () => {
        const tagMap = {
            Employee: "Employee",
            Licenced_vaccines: "Licenced vaccines",
            Clinical_trials: "Clinical trials",
        };

        return this.state.data.length > 0 ? 
        (
            this.state.data.map((item, index) => {
                return (
                    <OrganisationCard
                        key={index}
                    >
                        <h4>{item["Company_Name"]}</h4>
                        <p>{item["Company_Type"]}</p>
                        <CardContent>{item["Description"]}</CardContent>

                        <TagsWrapper>
                            {/* {Object.keys(tagMap).map((key, index) => {
                                return (
                                    <Tags key={index} data={item[1][tagMap[key]]} tagsKey={key} />
                                );
                            })} */}
                            <TagsWrapperOrganisation>
                                <p>Locations</p>
                                <div>
                                    {/* Location Tags */}
                                    {/* {
                                        Object.entries(item["Headquarters"]).map(([country, link]) => {
                                            return <p key={country}>{country}</p>
                                        })
                                    } */}
                                    <p>{item["Headquarters"]["country"] ?? "Not available"}</p>
                                </div>
                            </TagsWrapperOrganisation>

                            <Tags data={item["Company_Size"]} tagsKey={'Employee'} />
                            <Tags data={item["Trials_Count"]} tagsKey={'Licensed vaccines'}/>
                            <Tags data={item["Vaccines_Count"]} tagsKey={'Clinical trials'} />
                        </TagsWrapper>

                        <LearnMoreButton
                            onClick={()=>this.clickOrganisationCard(item["Company_Name"])}
                        >
                            Learn More
                        </LearnMoreButton>
                    </OrganisationCard>
                )
            })
        ) : (
            <h1>No Vaccines Returned for the Given Filters</h1>
        )
    };
};


const mapStateToProps = (state) => {
    return {
        filters: state.organisationFilter.filters,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllOrganisations);