import React, { Component } from "react";
import { FooterContainer, FooterHeading, FooterLink, FooterLinkSeparator, LinkContainer } from "./styles";

class Footer extends Component {
    render() {
        return (
            <FooterContainer relative={this.props.relative}>
                {/* Footer: Company & copyright information */}
                <FooterHeading>
                    GLOBAL ACTION ALLIANCE, INC
                </FooterHeading>
                <LinkContainer>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        About GAA
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Privacy Policy
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Terms and Conditions
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Located in Washington DC
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink href="mailto:hansilla.alaigh@globalactionalliance.net">
                        Contact Us at contact@globalactionalliance.net
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">
                        Share additional data
                    </FooterLink>
                </LinkContainer>
            </FooterContainer>
        )
    }
}

export default Footer;