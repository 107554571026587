import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LicensedProducts, VaccineGrid, VaccineGeneralInfo, ManufacturedBy, Continent, Country, WhoPq, Location, VaccineType, VaccineViewMore } from '../style.licensedProduct';
import { TextButton, ContentContainer, OverlayContainer, ShadePlate, SummaryTitle, BorderedTextButton, TitleRowContainer, TopicTitle, BreadcrumbContainer, ShareData, GridWrapper, DeselsectFilterArrow, TitleBackWrapper, ModalButton, WoldMapModalContainer, WorldMapContainer } from "pages/style.pages";
import { Header, Footer, Loader, WorldMap, WorldMapModal, ZoomWorldMap, ComponentTablePagination, BarChartPagination, Breadcrumbs, TabSwitch, PagedTable } from 'components';

import { Pie, Bar, HorizontalBar } from 'components/Charts';

import FiltersRow from "../filter/FiltersRow";

import { API_PORT, API_PATH, API_MODE } from "services/libraryAPI";

import { updateOptions } from 'store/vaccineAvailability/optionsReducer';
import { filterActions } from 'store/vaccineAvailability/filtersReducer';

import { MdOutlineZoomOutMap } from "react-icons/md";

import { IoCaretBack } from "react-icons/io5";

import { FILTER_DELAY } from "utils/Constants";
import { Navigate } from "react-router-dom";

import { ManufacturedByTile } from './tiles';




const colorLabel = [
    "#23647C",
    "#468AAB",
    "#4CA2C5",
    "#53BBDF",
    "#6ED4F3",
    "#86DDF6",
    "#B0EDFB",
    "#FAFAF9",
    "#DBEAF3",
];


class LicensedProduct_Graph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                countOfAllVaccines: 0,
                countOfFilteredVaccines: 0,
                vaxByManufacturers: [],
                vaxByCountry: [],
                allManufacturerCount: 0,
                mapData: [],
            },
            countryDataBar: {
                labels: ["Country"],
                datasets: [
                    {
                        label: "Vaccines",
                        backgroundColor: [
                            "#23647C",
                            "#468AAB",
                            "#4CA2C5",
                            "#53BBDF",
                            "#58CCF1",
                            "#6ED4F3",
                            "#7DC4D9",
                            "#87D1E7",
                            "#86DDF6",
                            "#B0EDFB",
                        ],
                        data: [0],
                    },
                ],
            },
            continentDataBar: {
                labels: ["Continent"],
                datasets: [
                    {
                        label: "Vaccines",
                        backgroundColor: [
                            "#6FEFB5",
                            "#6BE5D7",
                            "#97EAEE",
                            "#86DDF6",
                            "#7BD1ED",
                            "#60D9DB",
                        ],
                        data: [0, 0],
                    },
                ],
            },
            typeDataPie: {
                labels: ["QIV", "TIV"],
                datasets: [
                    {
                        data: [1, 1],
                        backgroundColor: [
                            "#6BE5D7",
                            "#B0EDFB",
                            "#6ED4F3",
                            "#53BBDF",
                            "#4CA2C5",
                        ],
                    },
                ],
            },
            whoPQDataPie: {
                labels: ["Prequalified", "Not Prequalified"],
                datasets: [
                    {
                        data: [1, 1],
                        backgroundColor: ["#B0EDFB", "#86DDF6", "#53BBDF"],
                    },
                ],
            },
            loading: true,
            ifSelected : false,
            currentPage: 1,
            isShowModal: false,
            totalContentCountry: 8,
            currentPageCountry: 1,
            redirect: false
        }
    }

    queryFilteredData = () => {
        this.setState (
            () => ({
                loading: true,
            }), 
            async () => {
                let filters = JSON.parse(JSON.stringify(this.props.filters));
                switch (filters["Who_PQ"]) {
                    case "Prequalified":
                        filters["Who_PQ"] = "true";
                        break;
                    case "Not Prequalified":
                        filters["Who_PQ"] = "false";
                        break;
                    default:
                        filters["Who_PQ"] = ""
                        break;
                }

                try {
                    const rawResponse = await fetch (
                        `${API_MODE}://${API_PATH}:${API_PORT}/v1/vaccineAvailability/summary`,
                        {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(filters),
                        }
                    );

                    // Receive filtered results
                    const results = await rawResponse.json();

                    // Update sponsors options
                    let location = [];
                    let manufacturerList = [];
                    let vaccineType = [];
                    let whoPqList = [];

                    results.options.locations.map((entry) => {
                        location.push(entry);
                    });

                    results.options.manufacturers.map((entry) => {
                        manufacturerList.push(entry);
                    });

                    results.options.vaccinetypes.map((entry)=>{
                        vaccineType.push(entry)
                    });
                    
                    results.options.Who_PQ.map((entry)=> {
                        whoPqList.push(entry);
                    });

                    //Option Update
                    this.handleOptionUpdate("Manufacturer", manufacturerList);
                    this.handleOptionUpdate("Type", vaccineType);
                    this.handleOptionUpdate("Location", location);
                    this.handleOptionUpdate("Who_PQ", whoPqList);

                    // Process country data
                    let countryDataObj = {};
                    results.summary.vaxByCountry.forEach((country) => {
                        countryDataObj[country[0]] = country[1];
                    });
                    let newCountryDataBar = this.handleChartData (
                        this.state.countryDataBar,
                        countryDataObj
                    );

                    // Process Continent data
                    let continentDataObj = {};
                    results.summary.vaxByContinent.forEach((continent) => {
                        continentDataObj[continent[0]] = continent[1];
                    });
                    const newContinentDataBar = this.handleChartData (
                        this.state.continentDataBar,
                        continentDataObj
                    );

                    // Process Types
                    const newTypeDataPie = this.handleChartData (
                        this.state.typeDataPie,
                        results.summary.vaxByType
                    );

                    const newWhoPQ = this.handleChartData (
                        this.state.whoPQDataPie,
                        results.summary.vaxPrequalification
                    );

                    this.setState(() => ({
                        data: results.summary,
                        countryDataBar: newCountryDataBar,
                        continentDataBar: newContinentDataBar,
                        typeDataPie: newTypeDataPie,
                        whoPQDataPie: newWhoPQ,
                        loading: false,
                    }));
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                }
            }
        )
    }

    handleChartData = (stateObj, resultObj) => {
        let labels = Object.keys(resultObj);
        let values = Object.values(resultObj);

        let newObj = new Object();
        newObj.labels = labels;
        newObj.datasets = [new Object()];
        newObj.datasets[0].label = stateObj.datasets[0].label;
        newObj.datasets[0].backgroundColor = [...stateObj.datasets[0].backgroundColor];
        newObj.datasets[0].data = values;
        return newObj;
    };

    handleOptionUpdate = (option, value) => {
        this.props.updateOptions({optionKey: option, optionValue: value});
    };
    
    handleLineChartView = (pageNumber) => {
        this.setState((prevState) => ({ currentPageCountry: pageNumber }))
    };

    componentDidMount = () => {
        this.queryFilteredData();
    };

    // Should be implemented in Redux Thunk later
    componentDidUpdate = (prevState) => {
        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevState.filters)) {
            this.setState(
                () => (
                    { ifSelected: true }
                ));
            this.fetchCall();
        }
    };

    fetchCall = () => {
        this.timeOut = setTimeout(() => {
            this.queryFilteredData();
            this.setState(
                () => (
                    { ifSelected: false }
                ));
        }, FILTER_DELAY);
    };

    stopFetch = () => {
        clearTimeout(this.timeOut);
    };

    WorldMapColorMount = (e) => {
        let percentValue = null;

        if (e.countryValue !== null) {
            percentValue = (e.countryValue / e.maxValue) * 100;
        }

        if (percentValue > 90) {
            return colorLabel[0];
        } else if (percentValue <= 90 && percentValue > 75) {
            return colorLabel[1];
        } else if (percentValue <= 75 && percentValue > 60) {
            return colorLabel[2];
        } else if (percentValue <= 60 && percentValue > 45) {
            return colorLabel[3];
        } else if (percentValue <= 45 && percentValue > 30) {
            return colorLabel[4];
        } else if (percentValue <= 30 && percentValue > 15) {
            return colorLabel[5];
        } else if (percentValue <= 15 && percentValue >= 0) {
            return colorLabel[6];
        } else {
            return colorLabel[8];
        }
    };

    handleFilterDeselect = (key) => {
        this.props.deselectFilter({ key: key });
    };

    handleZoomClick = () => {
        this.setState((prevState) => ({ showWorldMap: !prevState.showWorldMap }));
    };

    handleMapClick = (value) => {
        this.props.changeFilter({key: 'Location', value: `${value}`});
    };

    getStartYearData = (dataObj) => {
        if (dataObj.datasets[0].data.length > 1) {
            const endingIndex = this.state.currentPageCountry * this.state.totalContentCountry;
            const startingIndex = endingIndex - this.state.totalContentCountry;
            const labels = dataObj.labels.slice(startingIndex, endingIndex);
            let newObj = JSON.parse(JSON.stringify(dataObj));
            newObj.datasets[0].data = dataObj.datasets[0].data.slice(startingIndex, endingIndex);
            newObj.labels = labels;

            return newObj;
        }

        return dataObj;
    };

    render() {
        const whoPqFilterSelected = this.props.filters.Who_PQ;
        const typeFilterSelected = this.props.filters.Type;
        const locationFilterSelected = this.props.filters.Location;
        const ifLocationFilterSelected = this.props.filters.Location && this.state.data.vaxByCountry.length === 1;

        const numberOfPage = Math.ceil(this.state.countryDataBar.labels.length / this.state.totalContentCountry);

        return (
            <LicensedProducts>
                <Loader isVisible={this.state.loading} />
                <Header routes={this.props.history} />
                <ContentContainer>
                    <BreadcrumbContainer>
                        <Breadcrumbs />
                        <TextButton href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">Share Additional Data</TextButton>
                    </BreadcrumbContainer>

                    <TitleRowContainer>
                        <TopicTitle>Vaccine Availability</TopicTitle>
                        <TabSwitch tabSwitchOn={'graph'} pageType={`licensedProducts`} insights />
                    </TitleRowContainer>

                    <FiltersRow
                        routes={this.props.history}
                        setTimeOutVaccine={this.fetchCall}
                        clearTimeOutVaccine={this.stopFetch}
                        ifSelected={this.state.ifSelected}
                    />

                    <WorldMapModal
                        isVisible={this.state.showWorldMap}
                        handleCrossClick = {this.handleZoomClick}
                    >
                        <WoldMapModalContainer>
                            <ZoomWorldMap
                                data={this.state.data.mapData}
                                colorLabel={colorLabel}
                                getContextColor={this.WorldMapColorMount}
                            />
                        </WoldMapModalContainer>
                    </WorldMapModal>

                    <VaccineGrid>

                        <VaccineGeneralInfo onClick={() => {this.props.history.push(`/licensedProducts/list`)}}>
                            <GridWrapper style={{ justifyContent: 'center' }}>
                                <SummaryTitle>
                                    <span style={{paddingRight: ".7vw", textAlign: "left"}}>See all</span>
                                    <span
                                        style={{
                                            color: "#23647C",
                                            fontWeight: "400",
                                            fontSize: "1.71rem",
                                            paddingRight: ".7vw",
                                            textAlign: "left"
                                        }}
                                    >
                                        {this.state.data.countOfFilteredVaccines}
                                    </span>
                                    <span
                                        style={{
                                            overflowY: "auto",
                                            maxHeight: "5vh",
                                            lineHeight: "normal",
                                            width: "55%",
                                            flexGrow: 1,
                                        }}
                                    >
                                        licensed vaccines
                                    </span>
                                </SummaryTitle>
                            </GridWrapper>
                        </VaccineGeneralInfo>

                        <ManufacturedBy>
                            <GridWrapper>
                                <ManufacturedByTile
                                    manufacturerFilter={this.props.filters.Manufacturer} 
                                    manufacturerData={this.state.data.vaxByManufacturers}
                                />
                            </GridWrapper>
                        </ManufacturedBy>

                        <Continent>
                            <GridWrapper>
                                <SummaryTitle>Vaccine Availability by Continent</SummaryTitle>
                                <HorizontalBar
                                    data={this.state.continentDataBar}
                                    chartWidth={'100%'}
                                    chartHeight={'90%'}
                                    pageType = "Vaccine Availability"
                                />
                            </GridWrapper>
                        </Continent>

                        <Location>
                            <GridWrapper style={{ overflow: 'hidden' }}>
                                <SummaryTitle>
                                    <TitleBackWrapper onClick={() => { this.handleFilterDeselect('Location') }} clickable={locationFilterSelected !== ""}>
                                        {locationFilterSelected !== "" && <DeselsectFilterArrow><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                        <span>Vaccine Availability by Location</span>
                                    </TitleBackWrapper>
                                    <ModalButton onClick={this.handleZoomClick}><MdOutlineZoomOutMap/></ModalButton>
                                </SummaryTitle>
                                <WorldMapContainer>
                                    <WorldMap
                                        data={this.state.data.mapData}
                                        colorLabel={colorLabel}
                                        getContextColor={this.WorldMapColorMount}
                                        clickable={true}
                                        onClickCountry= {this.handleMapClick}
                                    />
                                </WorldMapContainer>
                                <ShadePlate>{this.getShadePlate()}</ShadePlate>
                            </GridWrapper>
                        </Location>

                        <Country>
                            <GridWrapper>
                                <SummaryTitle>
                                    <TitleBackWrapper 
                                        onClick={() => { this.handleFilterDeselect('Location') }} 
                                        clickable={locationFilterSelected !== ""}
                                    >
                                        {
                                            locationFilterSelected !== "" && 
                                            <DeselsectFilterArrow>
                                                <IoCaretBack style={{paddingTop: "20%"}}/>
                                            </DeselsectFilterArrow>
                                        }
                                        <span> Vaccine Availability by Country</span>
                                    </TitleBackWrapper>
                                </SummaryTitle>
                                <BarChartPagination
                                    currentPage={this.state.currentPageCountry}
                                    numberOfPage={numberOfPage}
                                    handlePageClick={this.handleLineChartView}
                                >
                                    <Bar
                                        pageType={`Vaccine Availability`}
                                        data={this.getStartYearData(this.state.countryDataBar)}
                                        width={'98%'}
                                        height={'90%'}
                                        isBordered={ifLocationFilterSelected}
                                        clickable={true}
                                        filterType={"Location"}
                                    />
                                </BarChartPagination>
                            </GridWrapper>
                        </Country>

                        <WhoPq>
                            <GridWrapper>
                                <SummaryTitle>
                                    <TitleBackWrapper onClick={() => { this.handleFilterDeselect('Who_PQ') }} clickable={whoPqFilterSelected !== ""}>
                                        {whoPqFilterSelected !== "" && <DeselsectFilterArrow><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                        <span>{`Vaccine(s) by WHO Prequalification`}</span>
                                    </TitleBackWrapper>
                                </SummaryTitle>
                                <Pie
                                    data={this.state.whoPQDataPie}
                                    chartWidth={'70%'}
                                    chartHeight={'60%'}
                                    isBordered={whoPqFilterSelected}
                                    pageType = "Vaccine Availability"
                                    clickable={true}
                                    filterType={`Who_PQ`}
                                />
                                <BorderedTextButton
                                    onClick={() => { this.setState(() => ({
                                        redirect: true
                                    })) }}>
                                    Take me to Prequalified list
                                </BorderedTextButton>
                            </GridWrapper>
                        </WhoPq>

                        <VaccineType>
                            <GridWrapper>
                                <SummaryTitle>
                                    <TitleBackWrapper onClick={() => { this.handleFilterDeselect('Type') }} clickable={typeFilterSelected !== ""}>
                                        {typeFilterSelected !== "" && <DeselsectFilterArrow><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                        <span>{`Vaccine(s) by Type`}</span>
                                    </TitleBackWrapper>
                                </SummaryTitle>
                                <Pie
                                    data={this.state.typeDataPie}
                                    charWidth={'70%'}
                                    chartHeight={'75%'}
                                    isBordered={typeFilterSelected}
                                    pageType = "Vaccine Availability"
                                    clickable={true}
                                    filterType={`Type`}
                                />
                            </GridWrapper>
                        </VaccineType>

                        <VaccineViewMore
                            disabled
                            onClick={() => {
                                this.props.history.push(`/vaccineAvailability/list`);
                            }}
                        >
                            View more
                        </VaccineViewMore>

                    </VaccineGrid>
                </ContentContainer>
                <Footer relative />

                {this.state.redirect && (
                    <Navigate to={`/licensedProducts/whoPrequalified`} replace={true} />
                )}
            </LicensedProducts>
        )
        
    }

    getShadePlate = () => {
        return colorLabel.map((value, index) => {
            return (
                <span
                    key={index}
                    style={{
                        backgroundColor: `${value}`,
                        width: "1.3vw",
                        height: "1.5vh",
                    }}
                />
            );
        });
    };
}

const mapStateToProps = (state) => {
    return {
        filters: state.vaccineFilter.filters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
        deselectFilter: (obj) => dispatch(filterActions.deselectFilter(obj)),
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensedProduct_Graph);