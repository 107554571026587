const API_MODE = "https";
const API_PATH = "influenza-wiser.herokuapp.com";
const API_PORT = "443";

// const API_PATH = "localhost";
// const API_PORT = "8080";
// const API_MODE = "http";

export {
    API_PATH,
    API_PORT,
    API_MODE
};
