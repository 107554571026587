import { useNavigate } from "react-router-dom";
import { TabDisable, TabItems, TabSwitchContainer } from "./styles";

const TabSwitch = (props) => {
    const navigate = useNavigate();

    return (
        <TabSwitchContainer>

            <span
                className={props.tabSwitchOn == "graph" ? "tab-Switch-on border-gr" : "tab-Switch-off"}
                onClick={() => {
                    navigate(`/${props.pageType}/`);
                }}
            >
                Graph View
            </span>

            {props.tabSwitchOn == "insights" && <TabItems> | </TabItems>}

            <span
                className={props.tabSwitchOn == "list" ? "tab-Switch-on border-li" : "tab-Switch-off"}
                onClick={() => {
                    navigate(`/${props.pageType}/list`);
                }}
            >
                List View
            </span>

            {props.tabSwitchOn == "graph" && <TabItems> | </TabItems>}

            {props.insights ?
                <TabDisable tabSwitchOn={props.tabSwitchOn}>
                    Insights
                </TabDisable> : <span
                    className={props.tabSwitchOn == "insights" ? "tab-Switch-on border-in" : "tab-Switch-off"}
                    onClick={() => {
                        navigate(`/${props.pageType}/insights`);
                    }}
                >
                    Insights
                </span>
            }

        </TabSwitchContainer>
    );
}

export default TabSwitch;