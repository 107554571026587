import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
    filters: {
        Location: "",
        Type: "",
        Manufacturer: "",
        Who_PQ: "",
    }
};

const filters = createSlice({
    name: 'filters/vaccine',
    initialState: defaultState,
    reducers: {

        changeFilter: (state, action) => { 
            // const newState = JSON.parse(JSON.stringify(state));
            // newState.filters[action.payload.key] = action.payload.value;
            state.filters[action.payload.key] = action.payload.value;
            // state.filters = sortEntries(state.filters);
        },

        deselectFilter: (state, action) => { 
            // const newState = JSON.parse(JSON.stringify(state));
            // newState.filters[action.payload.key] = "";
            state.filters[action.payload.key] = "";
            // state.filters = sortEntries(state.filters);
        },

        updateFilter: (state, action) => {
            state = action.payload.filters;
        }, 

    }
})


export const filterActions = filters.actions;
export default filters.reducer;