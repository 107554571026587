import { ChartDataButton, ChartWrapper, ComponentWrapper2 } from "./style.graphComponent";

const LineChartComponentFunding = ({ isBordered, dataValue, color, label }) => {
    return (
        <ComponentWrapper2>
            <ChartWrapper>
                <div>
                    <span >{`total ${dataValue[0].label == 'total' && 'projects'} in ${label[0]}`}</span>
                    <ChartDataButton backgroundColor={dataValue[0].backgroundColor} isBordered={isBordered}>{dataValue[0].data[0]}</ChartDataButton>
                </div>
                <div>
                    <span>{`total ${dataValue[1].label} in ${label[0]}`}</span>
                    <ChartDataButton backgroundColor={dataValue[1].backgroundColor} isBordered={isBordered}>{dataValue[1].data[0]}</ChartDataButton>
                </div>
                <div>
                    <span >{`total ${dataValue[2].label} in ${label[0]}`}</span>
                    <ChartDataButton backgroundColor={dataValue[2].backgroundColor} isBordered={isBordered}>{dataValue[2].data[0]}</ChartDataButton>
                </div>
            </ChartWrapper>
        </ComponentWrapper2>
    );
};

export default LineChartComponentFunding;