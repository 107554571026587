import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import GlobalStyles from'./globalStyles.js';
import { theme } from './utils/Static/index';
import store from './store/index';



ReactDOM.render (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Provider store={store}>
            <App />
        </Provider>
    </ ThemeProvider>,
    document.getElementById('root')
);