export const theme = {
    heading1: {
        fontSize: '1.71rem',
        fontWeight: '400',
    },

    heading2: {
        fontSize: '1.083rem',
        fontWeight: '300',
    },

    heading3: {
        fontSize: '0.92rem',
        fontWeight: '500',
    },

    heading4: {
        fontSize: '0.75rem',
        fontWeight: '300',
    },

    heading5: {
        fontSize: '0.416667rem',
        fontWeight: '300',
    },

    heading6: {
        fontSize: '0.583rem',
        fontWeight: '300',
    },

    heading7: {
        fontSize: '0.73rem',
        fontWeight: '400',
    },

    bodyText: {
        fontSize: '0.583333rem',
        fontWeight: '400',
    }

}