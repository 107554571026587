import { React, Component } from "react";
import WiserLogo from "assets/image/wiser-logo.png";
import HeaderLogo from "assets/image/header-logo.png";


import { HeaderComponent, LogoComponent, DashboardLogo, DashboardTitle } from "./style.components";

{
  /* Header Section: Logo, Name, Sign in options */
}
class Header extends Component {

    constructor(props) {
        super(props);
        this.handleIconClick = this.handleIconClick.bind(this);
    };

    handleIconClick() {
        this.props.routes.push("/");
    };

    render() {
        return (
            <HeaderComponent>
                <LogoComponent onClick={this.handleIconClick}>
                    <DashboardLogo src={WiserLogo} alt="Logo" />
                    <DashboardTitle src={HeaderLogo} alt="LifeScience WISE-R" />
                </LogoComponent>
            </HeaderComponent>
        );
    }
}



export default Header;
