// import { UPDATE_FILTER, CHANGE_FILTER, DESELECT_FILTER } from "./actionTypes";
import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
    filters: {
        Type: "",
        Manufacturer: "",
        Presentation: "",
        PharmaceuticalForm : "",
        NoOfDoses : "",
    }
};


const filters = createSlice({
    name: 'filters/whoPrequalified',
    initialState: defaultState,
    reducers: {

        changeFilter: (state, action) => { 
            // const newState = JSON.parse(JSON.stringify(state));
            // newState.filters[action.payload.key] = action.payload.value;
            state.filters[action.payload.key] = action.payload.value;
            // state.filters = sortEntries(state.filters);
        },

        deselectFilter: (state, action) => { 
            // const newState = JSON.parse(JSON.stringify(state));
            // newState.filters[action.payload.key] = "";
            state.filters[action.payload.key] = "";
            // state.filters = sortEntries(state.filters);
        },

        updateFilter: (state, action) => {
            state = action.payload.filters;
        }, 

    }
})


export const filterActions = filters.actions;
export default filters.reducer;