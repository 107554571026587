import React, { useState, useEffect } from 'react';
import { HomePage, InfoArea, InfoInput, SolidButton, TitleContainer, WiserLogo, CompanyLogo, InputContainer, Prompt, DiseaseTableContainer, DiseasesItemContainer, DiseaseItem, BorderedTextButton, PackageCard } from './style.Auth';
import { Dropdown, Element } from './components/Dropdown';
import _ from "lodash";


export default function LocationSelection(props) {

    const { values, handleChange } = props;

    const { locations } = values;

    useEffect(() => {
        const fetchData = async () => {
            const rawResponse = await fetch (
                "./locationSelection.json", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            );
            // Receive filtered results
            const locationResult = await rawResponse.json();

            // Set up selection states for locations
            let newLocationObj = _.cloneDeep(locationResult);
            Object.keys(locationResult).map(location => {
                newLocationObj[location]["selected"] = false;
                const countries = newLocationObj[location]["countries"];
                Object.keys(countries).map(country => {
                    countries[country]["selected"] = false;
                });
            });
            handleChange("locations", newLocationObj);
        };

        fetchData()
            .catch(console.error);
    }, []);

    const gotoPreviousStep = e => {
        e.preventDefault();
        props.prevStep();
    };

    const gotoNextStep = e => {
        e.preventDefault();
        props.nextStep();
    };

    const handleItemChecked = (label) => {
        const newContinent = {...locations[label], ["selected"]: !locations[label]["selected"] };
        const newLocationObj = { ...locations, [label]: newContinent };

        handleChange("locations", newLocationObj);
    };

    const handleSubItemChecked = (label, subLabel) => {
        console.log(label, subLabel)
        const newContinent = {
            ...locations[label], 
            ["countries"]: {
                ...locations[label]["countries"],
                [subLabel]: {
                    ...locations[label]["countries"][subLabel],
                    ["selected"]: !locations[label]["countries"][subLabel]["selected"]
                }
            } };
        const newLocationObj = { ...locations, [label]: newContinent };

        handleChange("locations", newLocationObj);
    };

    return (
        <HomePage>
            <InfoArea>
                <Prompt>
                    <span>
                        Almost there
                    </span>
                    <span>
                        Which disease areas do you primarily work with? (Choose as many as you like)
                    </span>
                </Prompt>
                
                <DiseaseTableContainer>
                    { getLocationList() }
                </DiseaseTableContainer>
                
                <SolidButton onClick={gotoNextStep}>Create custom dashboard</SolidButton>
            </InfoArea>
        </HomePage>
    )

    function getLocationList() {
        return Object.entries(locations).map(continent => {
            const [ label, info ] = continent;
            return (
                <Dropdown label={label} info={info} handleCheckboxClick={handleItemChecked}>
                    {
                        Object.entries(info.countries).map(country => {
                            const [ subLabel, subInfo ] = country;
                            return <Element label={label} subLabel={subLabel} info={subInfo} handleCheckboxClick={handleSubItemChecked}/>
                        })
                    }
                </Dropdown>
            )
        })
    };

};