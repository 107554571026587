import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { filterActions } from 'store/vaccineAvailability/filtersReducer';

import { PagedTable } from "components";
import { SummaryTitle, TitleBackWrapper, DeselsectFilterArrow, BorderedTextButton } from "pages/style.pages";
import { IoCaretBack } from "react-icons/io5";



function ManufacturedBy({ manufacturerFilter, manufacturerData, changeFilter, deselectFilter }) {

    console.log(manufacturerData)

    const manufacturerDataReversed = Array.from(manufacturerData, item => [ item[1], item[0] ]);

    const [ currentPage, setCurrentPage ] = useState(1);
    const navigate = useNavigate();

    const NUM_OF_ROWS = 7;
    const pageCount = Math.ceil(manufacturerDataReversed.length / NUM_OF_ROWS);
    const ifManufacturerFilterSelected = manufacturerFilter && manufacturerDataReversed.length === 1;

    const handleItemClick = (value) => {
        changeFilter({key: 'Manufacturer', value: `${value}`});
    };

    return (
        <>
            <SummaryTitle>
                <TitleBackWrapper onClick={() => { deselectFilter({ key: 'Manufacturer' }) }} clickable={manufacturerFilter !== ""}>
                    { manufacturerFilter !== "" && <DeselsectFilterArrow><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow> }
                    { `Manufacturers by Vaccine Availability ${pageCount > 1 ? `(${currentPage}/${pageCount})` : ""}` }
                </TitleBackWrapper>
            </SummaryTitle>
            <PagedTable
                header={["No. of Vaccines", "Manufacture"]}
                data={manufacturerDataReversed}
                currentPage={currentPage}
                pageCount={pageCount}
                numOfRows={NUM_OF_ROWS}
                handleTablePage={(pageNumber) => { setCurrentPage(pageNumber) }}
                isBordered={ifManufacturerFilterSelected}
                handleItemClick={handleItemClick}
            />
            {
                manufacturerFilter !== "" &&
                <BorderedTextButton
                    onClick={() => { navigate(`/organisations/${manufacturerFilter}`) }}
                >
                    Take me to their page
                </BorderedTextButton>
            }
        </>
    )

}

const mapDispatchToProps = (dispatch) => {
    return {
        deselectFilter: (obj) => dispatch(filterActions.deselectFilter(obj)),
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
    };
};

export default connect(null, mapDispatchToProps)(ManufacturedBy);