
import styled from "styled-components";
import { gridItem, viewMoreStyle, cardWrapperStyle, media } from 'utils/Static/static.css';

export const ClinicalTrialsContainer = styled.div`
    width:100%;
    height: 100vh;
    
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    display: flex;
    flex-direction: column;
`

export const ClinicalGrid = styled.main`
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(15, 3.94%);
    margin: 1.5vh 1.72vw 0;
    gap: 2vh;

    height: 70vh;
`

export const GeneralInfo = styled.section`
    ${gridItem}
    grid-row: 1 / 3;
    grid-column: 1 / 9;

    &:hover {
        background-color: #DBEAF3;
        cursor: pointer;
    }
`

export const SummaryTitleClinical = styled.div`
    align-self: flex-start;
    font-size: ${props => props.theme.heading2.fontSize};
    font-weight: ${props => props.theme.heading2.fontWeight};
    color: #23647C;

    width:100%;

    display:flex;
    align-items: center;
    justify-content: flex-start;
`

export const Locations = styled.section`
    ${gridItem}
    grid-row: 3 / 11;
    grid-column: 1 / 9;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;

    /* & > div {
        width: 96%;
        height: 35vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -1.6rem;
    } */
`

export const PopulationAge = styled.section`
    ${gridItem}
    grid-row: 11 / 16;
    grid-column: 1 / 5;
`
export const PatientPopulation = styled.section`
    ${gridItem}
    grid-row: 11 / 16;
    grid-column: 5 / 9;
`

export const Phase = styled.section`
    ${gridItem}
    grid-row: 1 / 7;
    grid-column: 15 / 21;
`

export const YearlyCount = styled.section`
    ${gridItem}
    grid-row: 7 / 14;
    grid-column: 15 / 21;

    /* & > canvas {
        margin-top: 30px;
        height: 30px;
    } */
`

export const TrialsBySponsor = styled.section`
    ${gridItem}
    grid-row: 9 / 16;
    grid-column: 9 / 15;
`

export const TrialsSponsors = styled.section`
    ${gridItem}
    grid-row: 1 / 9;
    grid-column: 9 / 15;
`

export const SponsorsWrapper = styled.div`
    /* height: 25vh; */
    width: 100%;
    /* overflow-y: hidden; */
    margin: 0;
    padding: 1px;
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
`
export const SponsoredTrials = styled.div`
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: 600;
    text-align: left;
    color: #53bbdf;
`
export const SponsorName = styled.div`
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: 400;
    color: #164050;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const ViewMore = styled.button`
    ${viewMoreStyle}
    grid-row: 14 / 16;
    grid-column: 15 / 21;

    ${props => props.disabled ? ' \
            color: #C4C4C4; \
            border: 1px solid #C4C4C4; \
            pointer-events: none; \
        ' : ''
    }
`

export const CardWrapperClinicalTrials = styled.section`
    ${cardWrapperStyle}

    & > h1 {
        margin: 0 0 .8vh 0;
        color: #164050;
        font-weight: ${props => props.theme.heading3.fontWeight};
        font-size: ${props => props.theme.heading3.fontSize};
    }
`

export const TableContainer = styled.table`
    width: 100%;
    max-height: 27vh;

    height: ${props => props.calcHeight ? '100%' : '27vh'};
    /* height: 100%;
    flex-grow: 1; */
    /* flex-flow: column;
    height: 100%; */

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;
    font-size: ${props => props.theme.heading6.fontSize};

    /* table-layout:fixed; */

    & td {
        border: ${props => props.isBordered ? '2px solid black' : '1px solid #DBEAF3' };
    }
`
