import styled from "styled-components";
import { media } from 'utils/Static/index';

export const HeaderComponent = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    min-height: 111px;
    width: 100%;
    align-self: flex-start;

    background: #F7F7F6;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    padding: 0 1.2vw;

    ${media.monitor} {
        min-height: 59px;
    }

    ${media.large_desktop} {
        min-height: 51px;
    }

    ${media.laptop} {
        min-height: 40px;
    }
`


export const LogoComponent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    #org-logo {
        height: 65px;
    }

    ${media.monitor} {
        #org-logo {
            height: 45px;
        }
    }

    ${media.large_desktop} {
        #org-logo {
            height: 34px;
        }
    }

    ${media.laptop} {
        #org-logo {
            height: 26.7px;
        }
    }
`

export const DashboardLogo = styled.img`
        height: 65px;

    ${media.monitor} {
        height: 45px;
    }

    ${media.large_desktop} {
        height: 34px;
    }

    ${media.laptop} {
        height: 26.7px;
    }
`

export const DashboardTitle = styled.img`
    height: 40px;
    margin-left: 5px;

    ${media.monitor} {
        height: 28px;
    }

    ${media.large_desktop} {
        height: 20px;
    }

    ${media.laptop} {
        height: 15.7px;
    }
`