import React from "react";
import { useNavigate } from "react-router-dom";
import { ChartContainer } from "./style.charts";
import { Chart as ChartJS } from 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2'
import useWindowDimensions from "hooks/useWindowDimensions";

import { filterActions as FundingFilterActions } from 'store/funding/filtersReducer';
import { useDispatch } from 'react-redux';


const DoughnutChart = ({ data, pageType, chartHeight, chartWidth, filterType, clickable }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { height, width } = useWindowDimensions();

    const resizeMap = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 21;
        if (height >= 1210 && width >= 1800)
            return 19;
        if (height >= 920 && width >= 1380)
            return 18;
        if (height >= 720 && width >= 1024)
            return 14;
        if (height >= 620 && width >= 768)
            return 12;
        return 8;
    };

    const handleClick = (label) => {
        switch (pageType) {
            case "Funding":
                dispatch(FundingFilterActions.changeFilter({key : `${filterType}`, value : label}));
                navigate(`/funding/`);
                break;
            default:
                break;
        }
    };

    return (
        <ChartContainer width={chartWidth} height={chartHeight}>
                <Doughnut
                    data={data}
                    height={'95%'}
                    options={{
                        indexAxis: 'y',
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        maintainAspectRatio: false,
                        animation: {
                            duration: 0
                        },
                        onClick:(event, el) => {
                            if (el && clickable) {
                                const label = data["labels"][[el[0]["index"]]];
                                if (label !== "Not available") {
                                    handleClick(label);
                                }
                            }
                        },
                        onHover: (event, el) => {
                            if (clickable) {
                                const target = event.native ? event.native.target : event.target;
                                target.style.cursor = el[0] ? 'pointer' : 'default';
                            }
                        },
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: "right",
                            }
                        }
                    }}
                />
        </ChartContainer>
    );
}

export default DoughnutChart;