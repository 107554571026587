import styled from "styled-components";
import { gridItem, viewMoreStyle, } from 'utils/Static/index';

//--------------Manufacturer Page---------------


export const ManufacturerContainer = styled.section`
    width:100%;
    height: 100%;
    min-height: 100vh;
    
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    display: flex;
    flex-direction: column;
`

export const ManufactureGrid = styled.main`
    flex-grow: 1;
    margin: 0.5vh 1vw 0;
    height: 77vh;
    
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(14, 4.5%);
    gap: 1.9vh;
`
export const ManufactureAbout = styled.section`
    ${gridItem}
    grid-column: 1 / 6;
    grid-row: 1 / 4;
`

export const AboutContent = styled.p`
    margin: 0 4%;
    font-family: 'Libre Franklin';

    font-weight: ${props => props.theme.heading7.fontWeight};
    font-size: ${props => props.theme.heading6.fontSize};

    color: #164050;

    overflow: auto;
`

export const ManufactureContact = styled.section`
    ${gridItem}

    grid-column: 1 / 6;
    grid-row: 4 / 10;
`

export const ManufactureNews = styled.section`
    ${gridItem}

    grid-column: 1 / 6;
    grid-row: 10 / 15;
`

export const NewsContainer = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    gap: 2px;
    width: 95%;
`

export const NewsBlock = styled.div`
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const NewsInfo = styled.div`
    border-bottom: 2px solid #C4C4C4; 
    width: auto;
    flex-grow: 1;

    & P{
        margin: 8px 0;
    }

    & p:first-of-type{
        color: #6C7073;
        font-size: ${props => props.theme.heading6.fontSize};
    }

    & p:last-of-type{
        color: #23647C;
        font-size: ${props => props.theme.heading4.fontSize};
    }
`
export const NewsButton = styled.button`
    background: none;
    width: 24%;
    height: 50%;
    color: #23647C;
    border-radius: 40px;
    border: 1px solid #23647C;
`

export const ManufactureLicensed = styled.section`
    ${gridItem}

    grid-column: 6 / 11;
    grid-row: 1 / 7;
`

export const ManufactureMaps = styled.section`
    ${gridItem}

    grid-column: 6 / 11;
    grid-row: 7 / 15;
`

export const ManufactureFunding = styled.section`
    ${gridItem}

    grid-column: 11 / 17;
    grid-row: 1 / 5;
`

export const TotalFundingAmount = styled.div`
    width: max-content;
    margin: auto 0;
    padding: 1vh 1vw;
    background-color: #23647C;;
    color: white;
    border-radius: 8px;
    text-align: center;

    font-size: ${props => props.theme.heading1.fontSize};
    /* text-align: center; */
`

export const ManufactureTrials = styled.section`
    ${gridItem}

    grid-column: 11 / 17;
    grid-row: 5 / 13;
`

export const ManufactureViewMore = styled.button`
    ${viewMoreStyle}

    grid-column: 11 / 17;
    grid-row: 13 / 15;

    ${props => props.disabled ? ' \
        color: #C4C4C4; \
        border: 1px solid #C4C4C4; \
        pointer-events: none; \
    ' : ''
    }
`

export const TableContainerLicensed = styled.table`
    width: 100%;
    /* height: 86%; */
    box-sizing: border-box;
    height: ${props => props.calcHeight ? '100%' : '20vh'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;

    & td{
        border: 1px solid #DBEAF3;
    }

`

export const TableContainer = styled.table`
    width: 100%;
    /* height: 86%; */
    box-sizing: border-box;
    height: ${props => props.calcHeight ? '100%' : '30vh'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;

    & td{
        border: 1px solid #DBEAF3;
    }

`


export const TableHead = styled.thead`
    width:100%;

    & tr{
        background-color: #23647C;
        color: #FAFAF9;
        text-align: center;
        width: fit-content;
    }
    & tr > th {
        padding: .2vh .4vw;
        font-weight: normal;
        white-space: nowrap;
    }

    & tr > th:last-of-type{
        text-align: left;
    }
`

export const TableBody = styled.tbody`
    & tr {
        text-align: center;
        color: #164050;
    }

    & tr > td:last-of-type {
        text-align: left;
    }

    & tr > td {
        padding: .3vh .5vw;
        font-weight: normal; 
    }

    & tr:nth-of-type(even) {
        background-color: #DBEAF3;
    }

    & a {
        text-decoration: inherit;
        color: inherit;
        cursor: auto;
        color: #23647C;
    }

    & a:hover {
        color: #468AAB;
        cursor: pointer;
    }
`
export const TableHeadLicensed = styled.thead`
    width:100%;

    & tr{
        background-color: #23647C;
        color: #FAFAF9;
        text-align: center;
    }
    & tr > th {
        padding: .5vh .4vw;
        font-weight: normal;
        white-space: nowrap;
    }

    & tr > th:first-of-type{
        text-align: left;
    }
`

export const TableBodyByLicensed = styled.tbody`
    & tr{
        text-align: center;
        color: #164050;
        
    }

    & tr > td:first-of-type{
        text-align: left;
    }

    & tr > td {
        padding: .3vh .5vw;
        font-weight: normal; 
    }


    & tr:nth-of-type(even){
        background-color: #DBEAF3;
    }
`

export const TableDataDiv = styled.div`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

//------------------All Manufacturers Page------------

export const FlexContainerManufacture = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    color: rgba(27, 27, 27, 0.8);
    width: 100%;

    & > p {
        color: rgb(53, 119, 143);
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
    }

    & > p:hover {
        color: rgba(150, 197, 216, 0.747);
    }
`

export const CardContainer = styled.main`
    margin: 2vh;
    display: flex;
    align-items: center;
    gap: 2vh;
    flex-wrap: wrap;
`

export const OrganisationCard = styled.section`
    width: 21%;
    height: 58vh;
    max-height: 65vh;
    padding: 20px;

    display: flex;
    align-items:flex-start;
    flex-direction: column;
    gap: 1.5vh;

    background: #FAFAF9;
    box-shadow: 0px 2px 2px rgba(196, 196, 196, 0.2);
    border-radius: 24px;

    & > h4{
        margin: 0;
        
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: ${props => props.theme.heading3.fontWeight};
        font-size: ${props => props.theme.heading3.fontSize};
        
        color: #23647C;
    }

    & > p:first-of-type{
        margin: 0;
        width:100%;
        box-sizing: border-box;
        max-height: 10vh;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
        color: #6C7073;
    }
`

export const CardContent = styled.p`
    margin: 0;
    font-size: ${props => props.theme.heading6.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    overflow: auto;

    color: #164050;

    width:100%;
    height: 120px;
`

export const TagsWrapperOrganisation = styled.div`
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    & > p:first-child {
        margin: 0 0 2px 0;
        color: #468AAB;
        font-size: ${props => props.theme.heading5.fontSize};
        font-weight: ${props => props.theme.heading5.fontWeight};
    }

    & > div {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.8rem;
    }

    & > div > p {
        margin: 0;
        padding: 1vh 1.15vw;
        background-color: #EEFCFE;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 10vw;
        white-space: nowrap;
        color: #164050;
    }
`
export const LearnMoreButton = styled.button`
    margin-top: auto;
    width: 100%;

    background: none;
    padding: 8px;

    border: 1px solid #468AAB;
    border-radius: 20px;
    color: #468AAB;


    &:hover{
        cursor: pointer;
        color: #FAFAF9;
        background-color: #468AAB;
    }
`