const sortFilterHelper = (filters) => {
    return Object.entries(filters)
        .sort(([,a],[,b]) => a-b)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
};

export const sortFilters = (sortOrder, filters) => {
    var ordering = {};
    for (var i=0; i<sortOrder.length; i++)
        ordering[sortOrder[i]] = i;

    let selected = new Object(), unselected = new Object();

    Object.keys(filters).forEach((key) => {
        if (filters[key] !== "") {
            selected[key] = ordering[key];
        } else {
            unselected[key] = ordering[key];
        }
    });

    let results = new Object();
    Object.keys(sortFilterHelper(selected)).forEach((key) => {
        results[key] = filters[key];
    });
    Object.keys(sortFilterHelper(unselected)).forEach((key) => {
        results[key] = filters[key];
    });
    return results
};