import React, { useState } from 'react';
import { CheckboxItem, ArrowIcon, Menu } from './style';
import { Checkbox } from "components";

export function Dropdown( props ) {
    const { label, info, handleCheckboxClick } = props;
    const [ open, setOpen ] = useState(false);

    function handleClick() {
        setOpen(prevState => !prevState);
    }

    return (
        <>
            <CheckboxItem style={{maxWidth: "50%"}}>
                <span>
                    <ArrowIcon ifOpen={open} onClick={handleClick} />
                    {label}
                </span>
                <Checkbox id={label} value={info["selected"]} onChange={() => {handleCheckboxClick(label)}} />
            </CheckboxItem>
            <Menu ifOpen={open}>
                { props.children }
            </Menu>
        </>
    )
}

export function Element(props) {
    const {label, subLabel, info, handleCheckboxClick} = props;
    return (
        <div style={{marginLeft: "10px"}}>
            <CheckboxItem>
                {subLabel}
                <Checkbox id={label} value={info["selected"]} onChange={() => {handleCheckboxClick(props.label, props.subLabel)}} />
            </CheckboxItem>
        </div>
    )
}