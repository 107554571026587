import { ChartData2, ChartsHead, ComponentWrapper2 } from "./style.graphComponent";

const HorizontalChartComponent = ({ pageType , isBordered, dataValue, color, label}) => {
    const getLanguage = (pageType, label) => {
        switch (pageType) {
            case "Vaccine Availability":
                return `vaccine(s) are licensed in`
            default:
                return `${pageType} are sponsored by`;
        }
    };

    return (
        <ComponentWrapper2>
            <ChartsHead>
                <span>All &nbsp;</span>
                <p>{dataValue}</p>
                <span>&nbsp;{`${pageType} are in`}</span>
            </ChartsHead>
            <ChartData2
                isBordered={isBordered}
                backgruondColor={color}
            >
                {label}
            </ChartData2>
        </ComponentWrapper2>
    );
};

export default HorizontalChartComponent;