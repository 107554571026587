import { Backdrop } from 'components';
import { useState } from 'react';

import { DropdownVaccinePDF, DropdownVaccineWrapper, DropdownVaccineList } from '../style.licensedProduct';
import { VscTriangleUp, VscTriangleDown, VscTriangleRight } from "react-icons/vsc";


const DropdownPDF = ({ Data }) => {
    const [show, setShow] = useState(false);
    const count = Object.keys(Data).length;

    const updateShow = () => {
        setShow((prevShow) => !prevShow);
    };

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div style={{ position: 'relative' }}>
            {
                count > 1 ?
                <>
                    {show && <Backdrop changeBackdropState={updateShow} />}
                        <DropdownVaccineWrapper show={show}>
                            <span onClick={updateShow}>
                                <span>
                                    PDF
                                </span>
                                { !show && <VscTriangleDown style={{ fontSize: '.85rem' }} />}
                                { show && <VscTriangleUp style={{ fontSize: '.85rem' }} />}
                            </span>
                            <DropdownVaccinePDF show={show}>
                                <DropdownVaccineList>
                                    {
                                        Object.entries(Data).map(([country, link]) => {
                                            return <a href={link} target="_blank" >{country}</a>
                                        })
                                    }
                                </DropdownVaccineList>
                            </DropdownVaccinePDF>
                        </DropdownVaccineWrapper >
                </>
                : <DropdownVaccineWrapper show={show} onClick={() => openInNewTab(`${Object.values(Data)[0]}`)}>
                    <span >
                        <span>
                            PDF
                        </span>
                        <VscTriangleRight style={{ fontSize: '.85rem' }} />
                    </span>
                </DropdownVaccineWrapper>
            }
        </div>
    );
};

export default DropdownPDF;