import React, { useState } from 'react';
import AccountDetailsForm from './AccountDetailsForm';
import PurchasedItemsForm from './PurchasedItemsForm';
import DiseaseSelectionForm from './DiseaseSelectionForm';
import LocationSelectionForm from './LocationSelectionForm';

export default function SignupForm() {

    const [ step, setStep ] = useState(1);

    const [ values, setValues ] = useState({
        name: '',
        email: '',
        password: '',
        diseases: {},
        locations: {}
    });

      // Proceed to next step
    const nextStep = () => {
        setStep(prevStep => prevStep + 1);
    };

    // Go back to prev step
    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    };

    const handleFieldUpdate = (input, newValue) => {
        setValues(prevState => {
            return {...prevState, [input]: newValue}
        });
    };

    switch (step) {
        case 1:
            return (
                <AccountDetailsForm
                    nextStep={nextStep}
                    handleChange={handleFieldUpdate}
                    values={values}
                />
            );
        case 2:
            return (
                <PurchasedItemsForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleFieldUpdate}
                    values={values}
                />
            );
        case 3:
            return (
                <DiseaseSelectionForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleFieldUpdate}
                    values={values}
                />
            );
        case 4:
            return (
                <LocationSelectionForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleFieldUpdate}
                    values={values}
                />
            );
        default:
            break;
    }
}
