import styled from "styled-components";
import backgroundImage from 'assets/image/background.png';
import { media, homeInputStyle, BorderedTextButtonStyle } from 'utils/Static/index';
import Input from "components/Input";


// --------- Shared Components ---------

export const HomePage = styled.div`
    background-image: url(${backgroundImage});
    background-size: 100% 100%;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
    height: 100vh;
    color: #FAFAF9;

    display: flex;
    align-items: center;
`;

export const InfoArea = styled.main`
    padding: 3vh 2vw;
    margin: 0 auto;
    background-color: #2F81A020;
    backdrop-filter: blur(9px);
    border-radius: 10px;
    border: 2px solid #2F81A040;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const InputContainer = styled.div`
    margin: 2vh auto 1vh;
    width: 78%;
`

export const BorderedTextButton = styled.div`
    ${BorderedTextButtonStyle}
    width: 85%;
    margin: 5px auto;
    border: ${props => props.selected ? "2px solid #23647C" : "2px solid #2F81A0"};
    color: ${props => props.selected ? "#FAFAF9" : "#53BBDF"};
    background-color: ${props => props.selected && "#23647C"};
`

export const SolidButton = styled.div`
    background-color: #468AAB;
    color: #FAFAF9;
    text-align: center;
    line-height: 4vh;
    border-radius: 9px;
    width: 75%;
    margin-top: 3vh;
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};

    &:hover {
        background-color: #23647C;
        cursor: pointer;
    }
`;

export const Error = styled.div`
    margin: 0 auto;
    color: #F42B4B;
    text-align: center;
`;


// --------- Org Auth Page ---------

export const TitleContainer = styled.div`
    margin: 5vh 0;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

export const WiserLogo = styled.img`
    height: 84.5px;

    ${media.monitor} {
        height: 58.5px;
    }

    ${media.large_desktop} {
        height: 45px;
    }

    ${media.laptop} {
        height: 34.7px;
    }
`;

export const CompanyLogo = styled.img`
    height: 48px;
    margin-left: 5px;

    ${media.monitor} {
        height: 33.6px;
    }

    ${media.large_desktop} {
        height: 24px;
    }

    ${media.laptop} {
        height: 18.84px;
    }
`;

export const InfoInput = styled(Input)`
    margin: 0.5vh 0 0.5vh;
    padding: 0 0 0 7%;
    height: 4.8vh;
    width: 100%;
    box-sizing: border-box;
    backdrop-filter: blur(15px);
    background: #F7F7F620;
    border-radius: 60px;
    border: none;
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};
    color: #FAFAF9;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #FAFAF9;
        opacity: 0.6;
    }
`;


// --------- Purchased Package Page ---------

export const Prompt = styled.div`
    margin: 1vh auto;
    color: #FAFAF9;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${props=> props.theme.heading3.fontSize};
    font-weight: 200;
    margin-bottom: 2vh;

    & > span: first-child {
        font-size: ${props=> props.theme.heading1.fontSize};
        font-weight: ${props=> props.theme.heading1.fontWeight};
        margin-bottom: 1.5vh;
    }
`;

export const PackagesContainer = styled.div`
    display: flex;
`;

export const PackageCard = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #EEFCFE50;
    border-radius: 8px;
    width: 10vw;
    height: 25vh;
    padding: 6px;
    margin: 0 0.5vw;
    justify-content: space-between;
`



// --------- Diseases Selection Page ---------

export const DiseaseTableContainer = styled.div`
    display: flex;
    flex-flow: column wrap;

    width: 50vw;
    height: 40vh;
    border: 1px solid #EEFCFE50;
    padding: 30px;
    box-sizing: border-box;
`

export const DiseasesItemContainer = styled.div`
    border: 1px solid red;
    width: 50%;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
`

export const DiseaseItem = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 50%;
`