import React from "react";
import { CustomWorldMapModal, WorldMapTitle } from "../styles";

import PopupBackdrop from "../Popups/PopupsBackdrop";
import { MdOutlineClose } from "react-icons/md";


const WorldMapModal = (props) => {
    return (
        <>
            <PopupBackdrop
                isDisplay={props.isVisible}
                zIndex={250}
            />

            <CustomWorldMapModal
                isDisplay={props.isVisible}
            >
                <WorldMapTitle>
                    <span>Trials by country </span>
                    <span onClick={props.handleCrossClick} style={{cursor:'pointer'}}><MdOutlineClose/></span>
                </WorldMapTitle>
                {props.children}
            </CustomWorldMapModal>
        </>
    );
};

export default WorldMapModal;