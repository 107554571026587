import styled from "styled-components";
import { gridItem, viewMoreStyle, cardWrapperStyle, media } from 'utils/Static/index';
// import backgroundImage from '../assets/image/background.png'


export const LicensedProducts = styled.section`
    width:100%;
    height: 100%;
    min-height: 100vh;
    
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    display: flex;
    flex-direction: column;
`

export const VaccineGrid = styled.main`
    flex-grow: 1;
    margin: 1.5vh 1.72vw 0;
    height: 72vh;
    
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(14, 4.5%);
    gap: 2vh;

    ${media.laptop}{
        margin: 1vh 1vw;
        height: 100vh;
        gap: 1.7vh;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(22, 2fr);
    }

    ${media.mobile}{
        height: 200vh;
        gap: 1vh;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(41, 2fr);
    }
`
export const VaccineGeneralInfo = styled.section`
    ${gridItem}
    grid-column: 1 / 6;
    grid-row: 1 / 3;

    span {
        /* height: 3vh; */
        font-size: ${props => props.theme.heading2.fontSize};
        font-weight: ${props => props.theme.heading2.fontWeight};
    }

    ${media.laptop} {
        grid-column: 1 / 5;
        grid-row: 1 / 3;
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 1 / 3;
    }

    &:hover {
        background-color: #DBEAF3;
        cursor: pointer;
    }

`
export const ManufacturedBy = styled.section`
    ${gridItem}

    grid-column: 1 / 6;
    grid-row: 3 / 10;

    ${media.laptop} {
        grid-column: 1 / 5;
        grid-row: 3 / 10;
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 3 / 10;
    }
`
export const ManufacturersList = styled.div`
    height: 58%;
    overflow-y: auto;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 0.4fr 3fr;
    gap: 8px;
    margin: 0 auto;
    padding: 2vh;
    width: 24vw;
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: 400;
    
    ${media.mobile}{
        width: 80vw;
        margin: 0 20px;
        align-self: flex-start;
    }
`
export const ManufactureCount = styled.div`
    color: #53BBDF;

`
export const ManufactureName = styled.div`
    color: #23647C;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    cursor: pointer;

    &:hover {
        color: rgb(171, 193, 206);
    }
`
export const Continent = styled.section`
    ${gridItem}
    grid-column: 1 / 6;
    grid-row: 10 / 15;

    ${media.laptop} {
        grid-column: 1 / 5;
        grid-row: 10 / 15;
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 10 / 15;
    }
`
export const Location = styled.section`
    ${gridItem}

    grid-column: 6 / 12;
    grid-row: 1 / 9;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;

    ${media.laptop}{
        grid-column: 5 / 10;
        grid-row: 1 / 9; 
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 15 / 23; 
    }
`
export const ShadePlateVaccine = styled.div`
    width: 26%;
    align-self: flex-end;
    display: flex;
    margin-right: 1rem;
`
export const Country = styled.section`
    ${gridItem}
    grid-column: 6 / 12;
    grid-row: 9 / 15;

    ${media.laptop}{
        grid-column: 5 / 10;
        grid-row: 9 / 15; 
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 23 / 29; 
    }
`

export const WhoPq = styled.section`
    ${gridItem}
    grid-column: 12 / 17;
    grid-row: 1 / 7;

    ${media.laptop}{
        grid-column: 1 / 5;
        grid-row: 15 / 21; 
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 29 / 35; 
    }
`

export const VaccineType = styled.section`
    ${gridItem}

    grid-column: 12 / 17;
    grid-row: 7 / 13;

    ${media.laptop}{
        grid-column: 5 / 10;
        grid-row: 15 / 21; 
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 35 / 40; 
    }
`

export const VaccineViewMore = styled.button`
    ${viewMoreStyle}
    grid-column: 12 / 17;
    grid-row: 13 / 15;

    ${props => props.disabled ? ' \
        color: #C4C4C4; \
        border: 1px solid #C4C4C4; \
        pointer-events: none; \
    ' : ''
    }

    ${media.laptop}{
        grid-column: 1 / 10;
        grid-row: 21 / 23;
    }

    ${media.mobile}{
        grid-column: 1 / 5;
        grid-row: 40 / 42; 
    }
`
export const CardWrapperVaccine = styled.section`

    ${cardWrapperStyle}
    & > div {
        display: flex;
      /* align-items: center; */
        width: 100%;
    }

    & > div > h1 {
        margin: 0 0 2vh 0;
        color: #23647C;
        font-weight: ${props => props.theme.heading3.fontWeight};
        font-size: ${props => props.theme.heading3.fontSize};
    }
    & > div > a:link {
        text-decoration: none;
        margin: 0 0 1vh 0;
        color: #23647C;
        font-family: Arial, Helvetica, sans-serif;

        cursor: pointer;
    }
    & > div > a:visited {
        color: rgb(171, 193, 206);
    }
    & > div > a:hover {
        color: rgb(171, 193, 206);
    }

    & > div:first-child {
        justify-content: space-between;
    }
`

export const TagsWrapperVaccine = styled.div`
    // width: 100%;
    // margin-top: 5px;
    display: flex;
    flex-direction: column;

    & > p:first-child {
        margin: 0 0 2px 0;
        color: #468AAB;
        font-size: ${props => props.theme.heading5.fontSize};
        font-weight: ${props => props.theme.heading5.fontWeight};
    }

    & > div {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.8rem;
    }

    & > div > p {
        margin: 0;
        padding: 1vh 1.15vw;
        background-color: #EEFCFE;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 10vw;
        white-space: nowrap;
        color: #164050;
    }
`

export const DropdownVaccineWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: ${props => props.show ? '7vw' : '3.5vw'};
    min-width: 60px;
    padding: .35vw .5vw;

    color:#468AAB;
    font: inherit;
    font-size: ${props => props.theme.heading4.fontSize};
    /* z-index: 6; */

    & > span {
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        gap: 4px;
        cursor: pointer;

        span{
            padding: ${props => props.show ? '0 .6vw' : '0'};
            transition: 0.2s;
        }
    }

    box-shadow: ${props => props.show ? '3px 12px 20px rgba(88, 158, 195, 0.15)' : '0'};
    border-radius: ${props => props.show ? '10px 10px 0px 0px' : '0'};
    background: ${props => props.show ? '#DBEAF3' : 'transparent'};

    transition: 0.2s;
`

export const DropdownVaccinePDF = styled.div`
    position: absolute;
    left: 0;
    z-index: 20;
    
    width: ${props => props.show ? '7vw' : '3.5vw'};
    display: ${props => props.show ? 'block' : 'none'};
    margin-top: .35vw;
    min-width: 60px;
    padding: 0 0.5vw;
    max-height: 30vh;

    box-shadow: ${props => props.show ? '3px 12px 20px rgba(88, 158, 195, 0.15)' : '0'};
    border-radius: ${props => props.show ? '0px 0px 10px 10px' : '0'};
    background: #DBEAF3;

    overflow: hidden;
`

export const DropdownVaccineList = styled.div`

    border-top: 1px solid #53BBDF;
    padding: 0.4vh 0.3vw 1vh;
    max-height: 28vh;
    overflow-y: auto;
    

    &> a, a:visited, a:active{
        color: #23647C;
        font: inherit;
        font-weight: ${props => props.theme.heading7.fontWeight};
        font-size: ${props => props.theme.heading7.fontSize};
        border-radius: 6px;

        display: block;
        text-decoration: none;
        padding: .4vh .5vw;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & >  a:hover{
        background: #EEFCFE;
        padding: .4vh .4vh;
    }

    /* &::-webkit-scrollbar{
        width: 0.4rem;
    } */

    &::-webkit-scrollbar-track {
        /* background-color: rgb(221, 221, 221);
        border-radius: 100vw; */
        margin: 0.4rem 0;
    }

    /* &::-webkit-scrollbar-thumb {
        background-color: rgb(170, 170, 170);
        border-radius: 100vw;
    } */

    /* &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(190, 190, 190);
    } */
`