import styled from "styled-components";
import { homeInputStyle } from 'utils/Static/index';

import DownUnselectedIcon from "assets/image/down-unselected.png";
import DownSelectedIcon from "assets/image/down-selected.png";
import UpUnselectedIcon from "assets/image/up-unselected.png";
import UpSelectedIcon from "assets/image/up-selected.png";


export const FiltersContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-start;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
`

export const FilterWrapper = styled.div`
    box-shadow: 3px 12px 20px rgb(88 158 195 / 15%);
    background: transparent;
    border-radius: ${props => props.ifOpen ? '20px' : '25px'};

    max-height: ${props => props.ifOpen ? '34vh' : '3.5vh'};
    margin-right: 1vw;
    min-width: 5vw;
    padding: 0 1vw;
    padding-bottom: ${props => props.ifOpen ? '5px' : '0px'};
    background-color: ${props => props.ifApplied ? '#468AAB' : '#FAFAF9'};
    overflow: hidden;

    transition: max-height 0.2s;

    & input {
        color: ${props => props.ifApplied ? '#FAFAF9' : '#468AAB'};
    }

    &:hover {
        background-color: ${props => 
            (!props.ifApplied && !props.ifOpen) && '#DBEAF3' ||
            (props.ifApplied && !props.ifOpen) && '#23647C'
        };
        cursor: pointer;
    }
`

export const FilterLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

export const FilterIcon = styled.img.attrs(({ifApplied, ifOpen}) => ({
    src: 
    ifApplied ? 
        (ifOpen ? UpSelectedIcon : DownSelectedIcon)
        : (ifOpen ? UpUnselectedIcon : DownUnselectedIcon)
}))`
    width: 10px;
    height: 6px;
`

export const FilterInput = styled.input`
    ${homeInputStyle}
    padding: 0;
    text-align: left;
    width: 80%;
    height: 3.5vh;

    &::placeholder{
        color: inherit
    }
`

export const OptionsMenu = styled.ul`
    list-style: none;
    padding: 10px 20px 10px 10px;
    margin: 0;
    width: 100%;
    max-width: 15vw;
    max-height: 25vh;

    border-top: 1px solid #6AB1DA;

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
        /* background-color: rgb(221, 221, 221);
        border-radius: 100vw; */
        margin-top: 0.4em;
    }

    /* &::-webkit-scrollbar-thumb {
        background-color: rgb(170, 170, 170);
        border-radius: 100vw;
    } */

    /* &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(190, 190, 190);
    } */
`

export const OptionItem = styled.li`
    width: 85%;
    padding: 2px 1px 2px 5px;
    cursor: ${props => props.ifSelectable ? "pointer" : "default"};
    color: ${
        props => props.ifApplied ? 
            (props => props.ifSelectable ? "#FAFAF9" : "#23647C")
            : (props => props.ifSelectable ? "#468AAB" : "#C4C4C4")
    };
    overflow-x: hidden;

    &:hover {
        background-color: ${
            props => props.ifApplied ? 
                (props => props.ifSelectable ? "#23647C" : "inherit")
                : (props => props.ifSelectable ? "#DBEAF3" : "inherit")
        };
        border-radius: 6px;
    }
`