import { createSlice } from '@reduxjs/toolkit';


const defaultState = {
    options: {
        Type: [],
        Manufacturer: [],
        Presentation: [],
        PharmaceuticalForm : [],
        NoOfDoses : [],
    }
};


const options = createSlice({
    name: 'option/whoPrequalified',
    initialState: defaultState,
    reducers: {
        updateOptions: (state, action) => {
            state.options[action.payload.optionKey] = action.payload.optionValue;
        }
    }
})


export const { updateOptions } = options.actions;

export default options.reducer;

