import React from "react";
import { ChartContainer } from "components/styles";
import { Line } from "react-chartjs-2";
import { processNumberString, numFormatter } from "utils/Function/number";
import useWindowDimensions from "hooks/useWindowDimensions";
import { LineChartComponentFunding, LineChartComponent } from "./GraphComponents";

import { filterActions as CTFilterActions } from 'store/clinicalTrials/filtersReducer';
import { filterActions as FundingFilterActions } from 'store/funding/filtersReducer';
import { useDispatch } from 'react-redux';


const LineChart = ({ isBordered, data, pageType, chartHeight, chartWidth, clickable, filterType}) => {
    
    const dispatch = useDispatch();
    const { height, width } = useWindowDimensions();

    let phaseValue = isBordered ? isBordered : '';
    const zeroPresent = data ? data.datasets[0].data.length == 1 : false ;

    const resizeMap = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 21;
        if (height >= 1210 && width >= 1800)
            return 19;
        if (height >= 920 && width >= 1380)
            return 15;
        if (height >= 720 && width >= 1024)
            return 11;
        if (height >= 620 && width >= 768)
            return 9;
        return 7;
    };

    const handleClick = (e) => {
        // dispatch(CTFilterActions.changeFilter({ key : `${filterType}`, value : e }));
        switch (pageType) {
            case "Clinical Trials":
                dispatch(CTFilterActions.changeFilter({ key : `${filterType}`, value : e }));
                break;
            case "Funding":
                dispatch(FundingFilterActions.changeFilter({ key : `${filterType}`, value : e }));
                break;
            default:
                break;
        }
    }

    const resizeFont = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 22;
        if (height >= 1210 && width >= 1800)
            return 15;
        if (height >= 920 && width >= 1380)
            return 10;
        if (height >= 720 && width >= 1024)
            return 8;
        if (height >= 620 && width >= 768)
            return 7;
        return 6;
    };

    const resizeLegend = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 40;
        if (height >= 1210 && width >= 1800)
            return 20;
        if (height >= 920 && width >= 1380)
            return 10;
        if (height >= 720 && width >= 1024)
            return 8;
        if (height >= 620 && width >= 768)
            return 7;
        return 6;
    };


    return (

        <ChartContainer width={chartWidth} height={chartHeight}>
            {
                data && phaseValue == "" && !zeroPresent ?
                    <Line
                        data={data}
                        // height={'100%'}
                        options={{
                            layout: { padding: { top: 10, bottom: 0 } },
                            tooltips: {
                                callbacks: {
                                    label: function(tooltipItem, data) {
                                        switch (pageType) {
                                            case "Funding":
                                                const typeLabel = data.datasets[tooltipItem["datasetIndex"]]["label"];
                                                return `${typeLabel}: ${processNumberString(tooltipItem["value"])}`;
                                            case "Clinical Trials":
                                                return `${processNumberString(tooltipItem["value"])} trials`;
                                            default:
                                                return tooltipItem["value"];
                                        }
                                    },
                                }
                            },
                            elements: {
                                line: {
                                    borderWidth: 1
                                },
                                point: {
                                    radius: 2,
                                    borderWidth: 1,
                                    hitRadius: 1,
                                    hoverBorderWidth: 1,
                                    pointStyle: "none"
                                }
                            },
                            onClick: (event, el) => {
                                if (clickable) {
                                    
                                    const index = el[0]._index;
                                    const yearLables = el[0]._xScale.ticks;
                                    handleClick(yearLables[index]);
                                }
                            },
                            onHover: (event, el) =>{
                                if(clickable) {
                                    const target = event.native ? event.native.target : event.target;
                                    target.style.cursor = el[0] ? 'pointer' : 'default';
                                }
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        fontColor: "#164050",
                                        fontSize: resizeMap(height, width),
                                        callback: (dataLabel, index, ticks) => {
                                            return numFormatter(dataLabel)
                                        }
                                    },
                                    grid: {
                                        tickColor: "#164050"
                                    }
                                }],
                                xAxes: [{
                                    ticks: {
                                        fontColor: "#164050",
                                        fontSize: resizeMap(height, width),
                                        beginAtZero: false,
                                        maxRotation: 0,
                                        minRotation: 0
                                    },
                                    grid: {
                                        tickColor: "#164050"
                                    }
                                }],
                            },
                            legend: {
                                display: pageType == 'Funding',
                                position: 'bottom',
                                labels: {
                                    fontSize: resizeFont(height, width),
                                    boxWidth: resizeLegend(height, width),
                                    fontColor: "#164050",
                                }
                            },
                            maintainAspectRatio: false,
                            responsive: true,
                        }}
                    />
                    :
                    pageType == "Funding" ? 
                        <LineChartComponentFunding
                            pageType={pageType}
                            isBordered={phaseValue !== ""}
                            dataValue={data.datasets}
                            color={'#B2EFE8'}
                            label={data.labels}
                        />
                        :
                        <LineChartComponent
                            pageType={pageType}
                            isBordered={phaseValue !== ""}
                            dataValue={data.datasets[0].data[0]}
                            color={'#B2EFE8'}
                            lable={data.labels[0]}
                    />
            }
            
        </ChartContainer>
    );
};

export default LineChart;