import React from 'react';
import { ComponentTablePagination } from "./";
import { TableContainer, TableHead, TableBody, TableDataDiv } from "pages/style.pages"

export default function PagedTable({ header, data, currentPage, pageCount, numOfRows, handleTablePage, isBordered, handleItemClick }) {
    const contentLength = data.length - ((currentPage * numOfRows) - numOfRows);

    return (
        <ComponentTablePagination
            currentPage={currentPage ?? 1}
            numberOfPage={pageCount ?? 1}
            handlePageClick = {handleTablePage}
        >
            <TableContainer 
                calcHeight={pageCount !== 1 && contentLength <= 5 } 
                isBordered={isBordered}
            >
                <TableHead>
                    <tr>
                    {
                        header.map((item) => {
                            return <th>{item}</th>
                        })
                    }
                    </tr>
                </TableHead>
                <TableBody>
                    { generateTableBody() }
                </TableBody>
            </TableContainer>
        </ComponentTablePagination>
    )

    function generateTableBody() {
        const endingIndex = currentPage * numOfRows;
        const startingIndex = endingIndex - numOfRows;
    
        return data.slice(startingIndex, endingIndex).map((row, index) => {
            return (
                <tr key={index}>
                    {
                        row.map((item, rowIndex) => {
                            return (
                                <td 
                                    onClick={ ()=>{ rowIndex===row.length-1 ? handleItemClick(item) : null}}
                                >
                                    <TableDataDiv>
                                        {item}
                                    </TableDataDiv>
                                </td>
                            )
                        })
                    }
                </tr>
            );
        });
    }

}