import { createSlice } from '@reduxjs/toolkit';
import { sortFilters } from "utils/Function/sortFilters";


export const defaultState = {
    filters: {
        Location: "",
        Active: "",
        Type: "",
        Year: "",
        Funder: ""
    }
};

const filterOrder = ["Location", "Active", "Type", "Year", "Funder"];

const filters = createSlice ({
    name: 'filters/funding',
    initialState: defaultState,
    reducers: {

        changeFilter: (state, action) => {
            state.filters[action.payload.key] = action.payload.value;
            state.filters = sortFilters(filterOrder, state.filters);
        },

        deselectFilter: (state, action) => {
            state.filters[action.payload.key] = "";
            state.filters = sortFilters(filterOrder, state.filters);
        },

        clearFilter: (state, action) => {
            state.filters = defaultState.filters;
        }

    }
})

export const filterActions = filters.actions;
export default filters.reducer;