import React, { Component } from "react";
import { Header, Footer, Breadcrumbs, Tabs, Loader, ComponentTablePagination } from "components";
import { ContentContainer, OverlayContainer, BreadcrumbContainer, TextButton, TitleRowContainer, TopicTitle, GridWrapper, SummaryTitle, WorldMapContainer, ShadePlate, NoData } from "pages/style.pages";
import { AboutContent, ManufactureAbout, ManufactureContact, ManufactureFunding, NewsContainer, TotalFundingAmount, ManufactureGrid, ManufactureLicensed, ManufactureMaps, ManufactureNews, ManufacturerContainer, ManufactureTrials, ManufactureViewMore, TableContainer, TableBody, TableHead, TableDataDiv, TableBodyByLicensed, TableHeadLicensed, NewsBlock, NewsInfo, NewsButton, TableContainerLicensed } from '../style.organisation';

import withRouter from "hooks/withRouter";
import { processNumberString } from "utils/Function/number";

import { API_PORT, API_PATH, API_MODE } from "services/libraryAPI";

import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";


const colorLabel = [
    "#23647C",
    "#468AAB",
    "#4CA2C5",
    "#53BBDF",
    "#6ED4F3",
    "#86DDF6",
    "#B0EDFB",
    "#FAFAF9",
    "#DBEAF3",
];

class Organisation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            manufacturer: "Sanofi",
            loading: false,

            AboutContent: "",
            mapData: [],
            clinicalTrialsCount: 0,
            clinicalTrials: [],
            vaccineAvailabilityCount: 0,
            vaccineAvailability: [],
            contacts: [],
            news: [],
            TotalFundingAmount: 0,
            annualReport: "",
            linkedInLink: "",
            websiteLink: "",

            currentPage: 1,
            tableContent: 6,
            currentPageLicensed: 1,
            tableContentLicensed: 4,
            currentPageContacts: 1,
            tableContentContacts: 6
        };

        this.handleTablePage = this.handleTablePage.bind(this);
        this.handleTablePageLicensed = this.handleTablePageLicensed.bind(this);
    };

    queryFilteredData = (id) => {
        this.setState(
            () => ({
                loading: true,
            }), async () => {

            // const pageNum = 1;
            try {
                const rawResponse = await fetch(`${API_MODE}://${API_PATH}:${API_PORT}/v1/manufacturer/more`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        organization: id 
                    })
                });

                // Receive filtered results
                const results = await rawResponse.json();
                
                this.setState({
                    loading: false,
                    mapData: results.mapData,
                    AboutContent: results.about,
                    clinicalTrialsCount: results.clinicalTrialsCount,
                    clinicalTrials: results.clinicalTrials,
                    vaccineAvailabilityCount: results.vaccinesCount,
                    vaccineAvailability: results.vaccines,
                    TotalFundingAmount: results.fundingAmt,
                    annualReport: results.annual_report,
                    websiteLink: results.website_link,
                    linkedInLink: results.linkedin_link,
                    manufacturer: `${id}`,
                    contacts: results.contacts ?? [],
                    currentPage: 1,
                    currentPageLicensed: 1,
                    currentPageContacts: 1
                });
            } catch (err) {
                console.error(`Error Occurred during fetch: ${err.message}`);
            }
        });
    };

    componentDidMount() {
        const id = this.props.params.id;
        this.queryFilteredData(id);
    };

    handleTablePage = (pageNumber) => {
        this.setState((prevState) => ({ currentPage: pageNumber }));
    };

    handleTablePageLicensed = (pageNumber) => {
        this.setState((prevState) => ({ currentPageLicensed: pageNumber }));
    };

    handleTablePageContacts = (pageNumber) => {
        this.setState((prevState) => ({ currentPageContacts: pageNumber }));
    };

    WorldMapColorMount = (e) => {
        let percentValue = null;

        if (e.countryValue !== null) {
            percentValue = (e.countryValue / e.maxValue) * 100;
        }

        if (percentValue > 90) {
            return colorLabel[0];
        } else if (percentValue <= 90 && percentValue > 75) {
            return colorLabel[1];
        } else if (percentValue <= 75 && percentValue > 60) {
            return colorLabel[2];
        } else if (percentValue <= 60 && percentValue > 45) {
            return colorLabel[3];
        } else if (percentValue <= 45 && percentValue > 30) {
            return colorLabel[4];
        } else if (percentValue <= 30 && percentValue > 15) {
            return colorLabel[5];
        } else if (percentValue <= 15 && percentValue >= 0) {
            return colorLabel[6];
        } else {
            return colorLabel[8];
        }
    };

    render = () => {
        const pageCountofTableContacts = Math.ceil(this.state.contacts.length / this.state.tableContentContacts);
        const contentLengthContacts = this.state.contacts.length - ((this.state.currentPageContacts * this.state.tableContentContacts) - this.state.tableContentContacts);

        const pageCountofTable = Math.ceil(this.state.clinicalTrials.length / this.state.tableContent);
        const contentLength = this.state.clinicalTrials.length - ((this.state.currentPage * this.state.tableContent) - this.state.tableContent);

        const pageCountofTableLicensed = Math.ceil(this.state.vaccineAvailability.length / this.state.tableContentLicensed);
        const contentLengthLicensed = this.state.vaccineAvailability.length - ((this.state.currentPageLicensed * this.state.tableContentLicensed) - this.state.tableContentLicensed);

        return (
            <ManufacturerContainer>
                <Loader isVisible={this.state.loading} />
                <Header routes={this.props.history} />
                <ContentContainer>
                    <BreadcrumbContainer>
                        <Breadcrumbs />
                        <TextButton href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">Share Additional Data</TextButton>
                    </BreadcrumbContainer>

                    <TitleRowContainer>
                        <TopicTitle>{ this.state.manufacturer }</TopicTitle>
                    </TitleRowContainer>

                    <Tabs
                        stats={{}}
                        linkStats={[
                            { "View Annual Report": this.state.annualReport },
                            { "Take me to LinkedIn": this.state.linkedInLink },
                            { [`Take me to ${this.state.manufacturer} website`]: this.state.websiteLink},
                            { [`See all ${this.state.vaccineAvailabilityCount} Influenza Vaccines`]: "#"},
                            { [`See all ${this.state.clinicalTrialsCount} Influenza Clinical Trials`]: "#"},
                        ]}
                    />
                    
                    <ManufactureGrid>
                        <ManufactureAbout>
                            <GridWrapper>
                                <SummaryTitle>About</SummaryTitle>
                                {this.state.AboutContent && this.state.AboutContent.length > 0 ?
                                    <AboutContent>{this.state.AboutContent} </AboutContent>
                                    :
                                    <NoData>No Data Present</NoData>
                                }
                            </GridWrapper>
                        </ManufactureAbout>

                        <ManufactureContact>
                            <GridWrapper>
                                <SummaryTitle>Contacts</SummaryTitle>
                                {this.state.contacts && this.state.contacts.length > 0 ?
                                    <ComponentTablePagination
                                        currentPage={this.state.currentPageContacts}
                                        numberOfPage={pageCountofTableContacts}
                                        handlePageClick={this.handleTablePageContacts}
                                    >
                                        <TableContainerLicensed calcHeight={pageCountofTableContacts !== 1 && contentLengthContacts <= 3 }>
                                            <TableHead>
                                                <tr>
                                                    <th>name</th>
                                                    <th>position</th>
                                                </tr>
                                            </TableHead>
                                            <TableBody>
                                                {this.getContactsTable()}
                                            </TableBody>
                                        </TableContainerLicensed>
                                    </ComponentTablePagination>
                                    :
                                    <NoData>No Data Present</NoData>
                                }
                                
                            </GridWrapper>
                        </ManufactureContact>

                        <ManufactureNews>
                            <GridWrapper>
                                <SummaryTitle>News</SummaryTitle>
                                {
                                    this.state.news && this.state.news.length > 0 ?
                                    <NewsContainer>
                                        <NewsBlock>
                                            <NewsInfo>
                                                <p>Date of press release</p>
                                                <p>News article related to the technology </p>
                                            </NewsInfo>
                                            <NewsButton>Read More</NewsButton>
                                        </NewsBlock>
                                        <NewsBlock>
                                            <NewsInfo>
                                                <p>Date of press release</p>
                                                <p>News article related to collaboration </p>
                                            </NewsInfo>
                                            <NewsButton>Read More</NewsButton>
                                        </NewsBlock>
                                    </NewsContainer>
                                    :
                                    <NoData>No Data Present</NoData>
                                }
                            </GridWrapper>
                        </ManufactureNews>

                        <ManufactureLicensed>
                            <GridWrapper>
                                <SummaryTitle>{`Licensed vaccines by ${this.state.manufacturer} (Page ${this.state.currentPageLicensed}/${pageCountofTableLicensed})`}</SummaryTitle>
                                {
                                    this.state.vaccineAvailability && this.state.vaccineAvailability.length > 0 ?
                                        <ComponentTablePagination
                                            currentPage={this.state.currentPageLicensed}
                                            numberOfPage={pageCountofTableLicensed}
                                            handlePageClick = {this.handleTablePageLicensed}
                                        >
                                            <TableContainerLicensed calcHeight={pageCountofTableLicensed !== 1 && contentLengthLicensed <= 3 }>
                                                <TableHeadLicensed>
                                                    <tr>
                                                        <th>Vaccine name</th>
                                                        <th>Type</th>
                                                        <th>WHO PQ</th>
                                                    </tr>
                                                </TableHeadLicensed>
                                                <TableBodyByLicensed>
                                                    {this.getLicensedByManufacture()}
                                                </TableBodyByLicensed>
                                            </TableContainerLicensed>
                                        </ComponentTablePagination>    
                                    : <NoData>No Data Present</NoData>
                                }
                            </GridWrapper>
                        </ManufactureLicensed>

                        <ManufactureMaps>
                            <GridWrapper>
                                <SummaryTitle>Vaccine availability by country</SummaryTitle>
                                {/* <WorldMapContainer>
                                    <WorldMap
                                        data={this.state.mapData}
                                        colorLabel={colorLabel}
                                        getContextColor={this.WorldMapColorMount}
                                    />
                                </WorldMapContainer> */}
                                <ShadePlate>{this.getShadePlate()}</ShadePlate>
                            </GridWrapper>
                        </ManufactureMaps>

                        <ManufactureFunding>
                            <GridWrapper>
                                <SummaryTitle>Total Funding Receiving</SummaryTitle>
                                <TotalFundingAmount>${processNumberString(this.state.TotalFundingAmount)}</TotalFundingAmount>
                            </GridWrapper>
                        </ManufactureFunding>

                        <ManufactureTrials>
                            <GridWrapper>
                                <SummaryTitle style={{paddingBottom: "5px"}}>{`Clinical Trials by ${this.state.manufacturer}`}</SummaryTitle>
                                {
                                    this.state.clinicalTrials && this.state.clinicalTrials.length > 0 ?
                                        <ComponentTablePagination
                                            currentPage={this.state.currentPage}
                                            numberOfPage={pageCountofTable}
                                            handlePageClick = {this.handleTablePage}
                                        >
                                            <TableContainer calcHeight={pageCountofTable !== 1 && contentLength <= 5 }>
                                                <TableHeadLicensed>
                                                    <tr>
                                                        <th>Clinical Trial</th>
                                                        <th>Phase</th>
                                                        <th>Country</th>
                                                    </tr>
                                                </TableHeadLicensed>
                                                <TableBodyByLicensed>
                                                    {this.getClinicalTrialsByManufacture()}
                                                </TableBodyByLicensed>
                                            </TableContainer>
                                        </ComponentTablePagination>    
                                        : <NoData>No Data Present</NoData>
                                }
                            </GridWrapper>
                        </ManufactureTrials>

                        <ManufactureViewMore
                            disabled
                        >
                            View more
                        </ManufactureViewMore>
                    </ManufactureGrid>
                </ContentContainer>

                <Footer relative />
            </ManufacturerContainer>
        );
    }

    getShadePlate = () => {
        return colorLabel.map((value, index) => {
            return (
                <span
                    key={index}
                    style={{
                        backgroundColor: `${value}`,
                        width: "1.3vw",
                        height: "1.5vh",
                    }}
                />
            );
        });
    };

    getClinicalTrialsByManufacture = () => {
        const endingIndex = this.state.currentPage * this.state.tableContent;
        const startingIndex = endingIndex - this.state.tableContent;

        return this.state.clinicalTrials.slice(startingIndex,endingIndex).map((item, index) => {
            return (
                <tr key={index} >
                    <td><TableDataDiv>{item.title}</TableDataDiv></td>
                    <td><TableDataDiv>{item.phase}</TableDataDiv></td>
                    <td><TableDataDiv>USA</TableDataDiv></td>
                </tr>
            );
        });
    };

    getLicensedByManufacture = () => {
        const endingIndex = this.state.currentPageLicensed * this.state.tableContentLicensed;
        const startingIndex = endingIndex - this.state.tableContentLicensed;

        return this.state.vaccineAvailability.slice(startingIndex,endingIndex).map((item, index) => {
            return (
                <tr key={index}>
                    <td><TableDataDiv>{item.vaccine_name}</TableDataDiv></td>
                    <td><TableDataDiv>{item.type}</TableDataDiv></td>
                    <td><TableDataDiv>{item.who_pq ? <FcCheckmark/>: <IoMdClose/>}</TableDataDiv></td>
                </tr>
            );
        });
    };

    getContactsTable = () => {
        const endingIndex = this.state.currentPageContacts * this.state.tableContentContacts;
        const startingIndex = endingIndex - this.state.tableContentContacts;

        return this.state.contacts.slice(startingIndex, endingIndex).map((item, index) => {
            return (
                <tr key={index}>
                    <td><TableDataDiv><a href={`${item.link}`} target="_blank">{item.name}</a></TableDataDiv></td>
                    <td><TableDataDiv>{item.position}</TableDataDiv></td>
                </tr>
            );
        });
    }
};

export default withRouter(Organisation);