import { ComponentWrapper, PieChartSingleContainer } from "./style.graphComponent";

const PieChartComponent = ({ pageType, isBordered, dataValue, color, label}) => {

    const getLanguage = (pageType, sliceType) => {
        switch (pageType) {
            case "Vaccine Availability":
                return `vaccine(s) are ${sliceType}`
            default:
                return `${pageType} are in ${sliceType}`;
        }
    };

    return (
        <ComponentWrapper>
            <span>All</span>
            <PieChartSingleContainer
                isBordered={isBordered}
                backgroundColor={color}
            >
                <p>{dataValue}</p>
            </PieChartSingleContainer>
            <span>{getLanguage(pageType, label)}</span>
        </ComponentWrapper>
    );
};

export default PieChartComponent;