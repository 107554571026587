import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const [ currentUser, setCurrentUser ] = useState();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const userSignIn = (userInfo) => {
        localStorage.setItem("token", JSON.stringify(userInfo));
        setCurrentUser(userInfo);
    }

    const userLogOut = () => {
        localStorage.removeItem("token");
        setCurrentUser();
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token') || null);
        setCurrentUser(token);
        setLoading(false);
    }, [])

    return (
        <AuthContext.Provider value={{currentUser, userSignIn}}>
            {/* Don't render children until loading is done */}
            {!loading && children}
        </AuthContext.Provider>
    )
}