import React, { Component } from "react";
import { Loader, Header, Footer, TabSwitch, Breadcrumbs, WorldMapModal, WorldMap, ZoomWorldMap, ComponentTablePagination, LineChartPagination, PagedTable } from "components";
import { processNumberString } from "utils/Function/number";

import { connect } from 'react-redux';
import { updateOptions } from 'store/funding/optionsReducer';
import { filterActions } from "store/funding/filtersReducer";
import { IoCaretBack } from "react-icons/io5";

import { API_PORT, API_PATH, API_MODE } from "services/libraryAPI";

import { FILTER_DELAY } from "utils/Constants";

import { ContentContainer, GridWrapper, ShadePlate, WorldMapContainer, SummaryTitle, TitleRowContainer, TopicTitle, BreadcrumbContainer, WoldMapModalContainer, SummaryTitleWrapper, ModalButton, TitleBackWrapper, DeselsectFilterArrow, TextButton } from "pages/style.pages";
import { FundingContainer, FundingGrid, FundingGeneralInfo, TotalFunding, MapContainer, ByFunder, FundingByYear, ProjectGrant, ProjectRecipient, FundingViewMore, SummaryTitleFunding, TotalFundingAmount, MapContainer2, ProjectByYear, FundingGrant, } from '../style.funding';

import { Pie, Line } from 'components/Charts';

import FiltersRow from "../filter/FiltersRow";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { NewsContainer } from "pages/Organizations/style.organisation";
import { ByFunderTile, ByRecipientTile } from "./tiles";

const colorYear = ["#B8EAEF", "#85E9F2", "#7FBFC5", '#BDF2EB'];
const colorProject = ["#B1EAE3", "#81D9CF", "#7FB7B0", '#BDF2EB'];



const colorLabel = [
    "#23647C",
    "#468AAB",
    "#4CA2C5",
    "#53BBDF",
    "#6ED4F3",
    "#86DDF6",
    "#B0EDFB",
    "#FAFAF9",
    "#DBEAF3",
];

const colorLabel2 = [
    "#2B8F82",
    "#2B8F82",
    "#80BBB3",
    "#80BBB3",
    "#A8E7E0",
    "#A8E7E0",
    "#BDF2EB",
    "#BDF2EB",    
    "#F0F0EF",
];

class Funding extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            currentPage: 1,
            currentPageRecipient: 1,
            showWorldMap: false,
            currentPageSY: 1,
            currentPagePY: 1,
            totalContentSY: 8,
            showMore: false,
            summaryLabel: "Projects being funded",
            data: {
                totalFunding: 0,
                allProjectCount: 0,
                projectByFunder: [],
                projectByRecipient: [],
                fundingByCountry: [],
                projectsByCountry: []
            },
            typeDataPie: {
                labels: ["Contracts", "Grants"],
                datasets: [
                    {
                        data: [1, 1],
                        backgroundColor: ["#5BC3CE", "#97EAEE"], // "#70CCEB"
                        hoverBackgroundColor: ["#E7F6FF", "#E7F6FF"],
                    },
                ],
            },
            fundingDataPie: {
                labels: ["Contracts", "Grants"],
                datasets: [
                    {
                        data: [1, 1],
                        backgroundColor: ["#81D9CF", "#B1EAE3"], // "#70CCEB"
                        hoverBackgroundColor: ["#E7F6FF", "#E7F6FF"],
                    },
                ],
            },
            projectByYear: {
                labels: ["Year", "Year", "Year"],
                datasets: [
                    {
                        label: "",
                        data: [1],
                        fill: true,
                        backgroundColor: "#139384",
                        borderColor: "#000000",
                    },
                    {
                        label: "",
                        data: [1],
                        fill: true,
                        backgroundColor: "#0EAD9B",
                        borderColor: "#000000",
                    },
                    {
                        label: "",
                        data: [1],
                        fill: true,
                        backgroundColor: "#8AE5DB",
                        borderColor: "#000000",
                    },
                ],
            },
            yearDataLine: {
                labels: ["Year", "Year", "Year"],
                datasets: [
                    {
                        label: "",
                        data: [1],
                        fill: true,
                        backgroundColor: "#139384",
                        borderColor: "#000000",
                    },
                    {
                        label: "",
                        data: [1],
                        fill: true,
                        backgroundColor: "#0EAD9B",
                        borderColor: "#000000",
                    },
                    {
                        label: "",
                        data: [1],
                        fill: true,
                        backgroundColor: "#8AE5DB",
                        borderColor: "#000000",
                    },
                ],
            },
        };

        this.processSummaryLabel = this.processSummaryLabel.bind(this);
        this.handleZoomClick = this.handleZoomClick.bind(this);
        this.handleTablePage = this.handleTablePage.bind(this);
        this.handleTablePageRecipient = this.handleTablePageRecipient.bind(this);
        this.handleFilterDeselect = this.handleFilterDeselect.bind(this);
        
    };

    handleFilterDeselect = (key) => {
        this.props.deselectFilter({ key: key });
    };

    handleTablePage = (pageNumber) => {
        this.setState((prevState) => ({ currentPage: pageNumber }));
    };

    handleTablePageRecipient = (pageNumber) => {
        this.setState((prevState) => ({ currentPageRecipient: pageNumber }));
    };

    queryFilteredData = async () => {
        this.setState(
            () => ({
                loading: true,
            }),
            async () => {
                let filters = JSON.parse(JSON.stringify(this.props.filters));
                switch (filters["Active"]) {
                    case "Active":
                        filters["Active"] = "active";
                        break;
                    case "Inactive":
                        filters["Active"] = "inactive";
                        break;
                    default:
                        filters["Active"] = "";
                        break;
                }

                try {
                    const rawResponse = await fetch(
                        `${API_MODE}://${API_PATH}:${API_PORT}/v1/funding/summary`,
                        {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(filters),
                        }
                    );

                    // Receive filtered results
                    const results = await rawResponse.json();
                    const summary = results.summary;

                    // Update options
                    this.handleOptionsUpdate("Location", results.options.locations)
                    this.handleOptionsUpdate("Year", results.options.years);
                    this.handleOptionsUpdate("Funder", results.options.funders);
                    this.handleOptionsUpdate("Active", results.options.Active);
                    this.handleOptionsUpdate("Type", results.options.Type);

                    // Process Types
                    const newTypeDataPie = this.handleChartData(
                        this.state.typeDataPie,
                        summary.fundingByType
                    );


                    const newFundingDataPie = this.handleChartData(
                        this.state.fundingDataPie,
                        summary.projectsByFunding
                    )

                    // Process Yearly Count
                    const newYearDataLine = this.handleLineChartData(
                        this.state.yearDataLine,
                        summary.fundingByYear,
                        colorYear
                    );

                    const newProjectDataLine = this.handleLineChartData(
                        this.state.projectByYear,
                        summary.projectByYear,
                        colorProject
                    );

                    this.setState(() => ({
                        data: summary,
                        yearDataLine: newYearDataLine,
                        projectByYear: newProjectDataLine,
                        typeDataPie: newTypeDataPie,
                        fundingDataPie: newFundingDataPie,
                        loading: false,
                        currentPage: 1,
                        currentPageRecipient: 1,
                        summaryLabel: this.processSummaryLabel(),
                    }));
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                }
            }
        );
    };

    formatLabel = (label, maxwidth) => {
        return label.length > maxwidth ? label.substr(0, maxwidth) + "..." : label;
    };

    handleOptionsUpdate = (option, value) => {
        this.props.updateOptions({optionKey: option, optionValue: value});
    };

    handleChartData = (stateObj, resultObj) => {
        let labels = Object.keys(resultObj),
        data = Object.values(resultObj);
        let newObj = { ...stateObj, labels: labels };
        newObj.datasets[0].data = data;
        return newObj;
    };

    handleLineChartData = (stateObj, resultObj, color) => {
        let labels = Object.keys(resultObj.total)
        let newObj = { ...stateObj, labels: labels };
        // newObj.datasets[0].label = data[0][0];
        // newObj.datasets[1].label = data[1][0];
        // newObj.datasets[2].label = data[2][0];
        // newObj.datasets[0].data = Object.values(data[0][1]);
        // newObj.datasets[1].data = Object.values(data[1][1]);
        // newObj.datasets[2].data = Object.values(data[2][1]);
        // let i = 0;

        const dataSets = Object.entries(resultObj).map((item, i) => {
            return {
                label: item[0],
                data: Object.values(item[1]),
                fill: true,
                backgroundColor: color[i],
                borderColor: "rgba(75,192,192,1)"
            }
        })
        newObj.datasets = dataSets;
        return newObj;

    };

    processSummaryLabel = () => {
        let summaryLabel = "";

        const filters = this.props.filters;

        if (filters.Active === "Active") {
            summaryLabel += " Active";
        } else if (filters.Active === "Inactive") {
            summaryLabel += " Inactive";
        }

        summaryLabel += " Projects being funded";

        if (filters.Type != "") {
            summaryLabel += ` (${filters.Type})`;
        }

        if (filters.Location != "") {
            summaryLabel += `, located in ${filters.Location}`;
        }

        if (filters.Funder != "") {
            summaryLabel += `, funded by ${filters.Funder}`;
        }

        if (filters.Year != "") {
            summaryLabel += `, started in year ${filters.Year}`;
        }

        return summaryLabel;
    };

    componentDidMount = () => {
        this.queryFilteredData();
    };

    // Should be implemented in Redux Thunk later
    componentDidUpdate = (prevState) => {
        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevState.filters)) {
            this.setState(
                () => (
                    { ifSelected: true }
                ));
            this.fetchCall();
        }
    };

    handleItemClick = (value) => {
        this.props.changeFilter({key: "Funder", value: `${value}`});
    }

    handleMapClick = (value) => {
        this.props.changeFilter({key: `Location`, value: `${value}`});
    }

    fetchCall = () => {
        this.timeOut = setTimeout(() => {
            this.queryFilteredData();
            this.setState(
                () => (
                    { ifSelected: false }
                ));
        }, FILTER_DELAY);
    };

    WorldMapColorMount = (color, e) => {
        let percentValue = null;

        if (e.countryValue !== null) {
            percentValue = (e.countryValue / e.maxValue) * 100;
        }

        if (percentValue > 90) {
            return color[0];
        } else if (percentValue <= 90 && percentValue > 75) {
            return color[1];
        } else if (percentValue <= 75 && percentValue > 60) {
            return color[2];
        } else if (percentValue <= 60 && percentValue > 45) {
            return color[3];
        } else if (percentValue <= 45 && percentValue > 30) {
            return color[4];
        } else if (percentValue <= 30 && percentValue > 15) {
            return color[5];
        } else if (percentValue <= 15 && percentValue >= 0) {
            return color[6];
        } else {
            return color[8];
        }
    };

    handleZoomClick = () => {
        this.setState((prevState) => ({ showWorldMap: !prevState.showWorldMap }));
    };

    handleLineChartView = (pageNumber) => {
        this.setState((prevState) => ({ currentPageSY: pageNumber }))
    }

    handleLineChartViewProject = (pageNumber) => {
        this.setState((prevState) => ({ currentPagePY: pageNumber }))
    }

    render = () => {

        const numberOfPageSY = Math.ceil(this.state.yearDataLine.labels.length / this.state.totalContentSY);
        const numberOfPagePY = Math.ceil(this.state.projectByYear.labels.length / this.state.totalContentSY);

        return (
            <FundingContainer>
                <Loader isVisible={this.state.loading} />
                <Header routes={this.props.history} />

                <ContentContainer>
                    <BreadcrumbContainer>
                        <Breadcrumbs />
                        <TextButton href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">
                            Share Additional Data
                        </TextButton>
                    </BreadcrumbContainer>

                    <TitleRowContainer>
                        <TopicTitle>Funding</TopicTitle>

                        {/* ----- Tab Switch ----- */}
                        <TabSwitch tabSwitchOn={'graph'} pageType={`funding`} insights />
                    </TitleRowContainer>

                    {/* <Filters updateFilters={this.queryFilteredData} /> */}
                    <FiltersRow
                        routes={this.props.history}
                        setTimeOutVaccine={this.fetchCall}
                        clearTimeoutVaccine={this.stopFetch}
                    />

                    {/* <WorldMapModal
                        isVisible={this.state.showWorldMap}
                        handleCrossClick = {this.handleZoomClick}
                    >
                        <WoldMapModalContainer>
                            <ZoomWorldMap
                                data={this.state.data.mapData}
                                colorLabel={colorLabel}
                                getContextColor={this.WorldMapColorMount}
                            />
                        </WoldMapModalContainer>
                    </WorldMapModal> */}
                    
                    <FundingGrid setHeight = {this.state.showMore}>
                        <FundingGeneralInfo onClick={() => {this.props.history.push(`/funding/list`)}}>
                            <GridWrapper style={{justifyContent: "center"}}>
                                <SummaryTitleFunding>
                                    <span style={{paddingRight: ".7vw", textAlign: "left"}}>See All</span>
                                    <span
                                        style={{
                                            color: "#468AAB",
                                            fontWeight: "400",
                                            fontSize: "1.6rem",
                                            paddingRight: ".7vw",
                                            textAlign: "left"
                                        }}
                                    >
                                        { processNumberString(this.state.data.allProjectCount) }</span>
                                    <span
                                        style={{
                                            overflowY: "auto",
                                            maxHeight: "5vh",
                                            lineHeight: "normal",
                                            width: "40%",
                                            flexGrow: 1,
                                        }}
                                    >
                                        {/* Active Projects Being Funded */}
                                        {this.state.summaryLabel}
                                    </span>
                                </SummaryTitleFunding>
                            </GridWrapper>
                        </FundingGeneralInfo>
                            
                        <TotalFunding>
                            <GridWrapper style={{justifyContent: 'flex-start', gap: '2vh'}}>
                                <SummaryTitle>Total Funding</SummaryTitle>
                                <TotalFundingAmount>${processNumberString(this.state.data.totalFunding)}</TotalFundingAmount>
                            </GridWrapper>
                        </TotalFunding>

                        <MapContainer>
                            <GridWrapper style={{ overflow: 'hidden' }}>
                                <SummaryTitleWrapper>
                                    <SummaryTitle>
                                        <TitleBackWrapper>
                                            {this.props.filters.Location !== "" && <DeselsectFilterArrow onClick={() => { this.handleFilterDeselect('Location') }}><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                            Funding by country
                                        </TitleBackWrapper>
                                    </SummaryTitle>
                                    {/* <ModalButton onClick={this.handleZoomClick}><MdOutlineZoomOutMap/></ModalButton> */}
                                </SummaryTitleWrapper>
                                <WorldMapContainer>
                                    <WorldMap
                                        data={this.state.data.fundingByCountry}
                                        colorLabel={colorLabel}
                                        getContextColor={(e) => this.WorldMapColorMount(colorLabel, e)}
                                        clickable={true}
                                        onClickCountry={this.handleMapClick}
                                    />
                                </WorldMapContainer>
                                <ShadePlate>{this.getShadePlate(colorLabel)}</ShadePlate>
                            </GridWrapper>
                        </MapContainer>

                        {
                            this.state.showMore && 
                            <MapContainer2>
                                <GridWrapper style={{ overflow: 'hidden' }}>
                                    <SummaryTitleWrapper>
                                            <SummaryTitle>
                                                <TitleBackWrapper>
                                                    {this.props.filters.Location !== "" && <DeselsectFilterArrow onClick={() => { this.handleFilterDeselect('Location') }}><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                                    Project by country
                                                </TitleBackWrapper>
                                            </SummaryTitle>
                                        {/* <ModalButton onClick={this.handleZoomClick}><MdOutlineZoomOutMap/></ModalButton> */}
                                    </SummaryTitleWrapper>
                                    <WorldMapContainer>
                                        <WorldMap
                                            data={this.state.data.projectsByCountry}
                                            colorLabel={colorLabel2}
                                            getContextColor={(e) => this.WorldMapColorMount(colorLabel2, e)}
                                            clickable={true}
                                            onClickCountry={this.handleMapClick}
                                        />
                                    </WorldMapContainer>
                                    <ShadePlate>{this.getShadePlate(colorLabel2)}</ShadePlate>
                                </GridWrapper>
                            </MapContainer2>
                        }

                        <ByFunder>
                            <GridWrapper>
                                <ByFunderTile 
                                    funderFilter={this.props.filters.Funder}
                                    projectsByFunderData={this.state.data.projectByFunder}
                                />
                            </GridWrapper>
                        </ByFunder>

                        <FundingByYear moveChart={this.state.showMore}>
                            <GridWrapper>
                                <SummaryTitle>
                                    <TitleBackWrapper>
                                        {this.props.filters.Year !== "" && <DeselsectFilterArrow onClick={() => { this.handleFilterDeselect('Year') }}><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                        {`Funding by start year (${this.state.yearDataLine.labels[0]}-${this.state.yearDataLine.labels[this.state.yearDataLine.labels.length - 3]})`}
                                    </TitleBackWrapper>
                                </SummaryTitle>
                                <LineChartPagination
                                    currentPage={this.state.currentPageSY}
                                    numberOfPage={numberOfPageSY}
                                    handlePageClick={this.handleLineChartView}
                                >
                                    <Line
                                        data={this.getStartYearData(this.state.yearDataLine)}
                                        chartHeight={'100%'}
                                        chartWidth={'99%'}
                                        pageType = "Funding"
                                        clickable={true}
                                        filterType={`Year`}
                                        isBordered={this.props.filters.Year}
                                    />
                                </LineChartPagination>
                            </GridWrapper>
                        </FundingByYear>

                        {
                            this.state.showMore &&
                            <ProjectByYear>
                                <GridWrapper>
                                        <SummaryTitle>
                                            <TitleBackWrapper>
                                                {this.props.filters.Year !== "" && <DeselsectFilterArrow onClick={() => { this.handleFilterDeselect('Year') }}><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                                {`Project by start year (${this.state.projectByYear.labels[0]}-${this.state.projectByYear.labels[this.state.projectByYear.labels.length - 3]})`}
                                            </TitleBackWrapper>
                                        </SummaryTitle>
                                    <LineChartPagination
                                        currentPage={this.state.currentPagePY}
                                        numberOfPage={numberOfPagePY}
                                        handlePageClick={this.handleLineChartViewProject}
                                    >
                                        <Line
                                            data={this.getStartYearDataProject(this.state.projectByYear)}
                                            chartHeight={'100%'}
                                            chartWidth={'99%'}
                                            pageType="Funding"
                                            filterType={`Year`}
                                            clickable={true}
                                            isBordered={this.props.filters.Year}
                                        />
                                    </LineChartPagination>
                                </GridWrapper>
                            </ProjectByYear>
                        }

                        <ProjectGrant>
                            <GridWrapper>
                                <SummaryTitle>
                                    <TitleBackWrapper>
                                        {this.props.filters.Type !== "" && <DeselsectFilterArrow onClick={() => { this.handleFilterDeselect('Type') }}><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                        Projects by contract/grant
                                    </TitleBackWrapper>
                                </SummaryTitle>
                                <Pie
                                    data={this.state.typeDataPie}
                                    chartWidth={'85%'}
                                    chartHeight={'85%'}
                                    filterType={`Type`}
                                    pageType = {`Funding`}
                                    clickable={true}
                                    isBordered={this.props.filters.Type}
                                />
                            </GridWrapper>
                        </ProjectGrant>

                        {
                            this.state.showMore &&
                            <FundingGrant>
                                <GridWrapper>
                                    <SummaryTitle>
                                        <TitleBackWrapper>
                                            {this.props.filters.Type !== "" && <DeselsectFilterArrow onClick={() => { this.handleFilterDeselect('Type') }}><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                                            Funding by contract/grant
                                        </TitleBackWrapper>
                                    </SummaryTitle>
                                    <Pie
                                        data={this.state.fundingDataPie}
                                        chartWidth={'85%'}
                                        chartHeight={'85%'}
                                        filterType={`Type`}
                                        pageType = {`Funding`}
                                        clickable={true}
                                        isBordered={this.props.filters.Type}
                                    />
                                </GridWrapper>
                            </FundingGrant>
                        }

                        <ProjectRecipient moveTable={this.state.showMore}>
                            <GridWrapper>
                                <ByRecipientTile projectsByRecipientData={this.state.data.projectByRecipient} />
                            </GridWrapper>
                        </ProjectRecipient>

                        <FundingViewMore
                            moveButton = {this.state.showMore}
                            onClick={() => {
                                this.setState((prevState) => ({
                                    showMore: !prevState.showMore
                                }))
                            }}
                        >
                            {this.state.showMore ? `View less`: `View more`}
                        </FundingViewMore>
                    </FundingGrid>
                </ContentContainer>
                <Footer relative={this.state.showMore}/>
            </FundingContainer>
        );
    };

    getShadePlate = (colorMap) => {
        return colorMap.map((value, index) => {
            return (
                <span
                    key={index}
                    style={{
                        backgroundColor: `${value}`,
                        width: "1.3vw",
                        height: "1.5vh",
                    }}
                />
            );
        });
    }

    getStartYearData = (dataObj) => {
        if (dataObj.datasets[0].data.length > 1) {
            const endingIndex = this.state.currentPageSY * this.state.totalContentSY;
            const startingIndex = endingIndex - this.state.totalContentSY;
            const labels = dataObj.labels.slice(startingIndex, endingIndex); 
            let newObj = JSON.parse(JSON.stringify(dataObj));
            newObj.labels = labels;

            newObj.datasets[0].data = dataObj.datasets[0].data.slice(startingIndex, endingIndex);
            newObj.datasets[1].data = dataObj.datasets[1].data.slice(startingIndex, endingIndex);
            newObj.datasets[2].data = dataObj.datasets[2].data.slice(startingIndex, endingIndex);

            return newObj;
        }

        return dataObj;
    }

    getStartYearDataProject = (dataObj) => {
        if (dataObj.datasets[0].data.length > 1) {
            const endingIndex = this.state.currentPagePY * this.state.totalContentSY;
            const startingIndex = endingIndex - this.state.totalContentSY;
            const labels = dataObj.labels.slice(startingIndex, endingIndex); 
            console.log(dataObj)
            let newObj = JSON.parse(JSON.stringify(dataObj));
            newObj.labels = labels;

            newObj.datasets[0].data = dataObj.datasets[0].data.slice(startingIndex, endingIndex);
            newObj.datasets[1].data = dataObj.datasets[1].data.slice(startingIndex, endingIndex);
            newObj.datasets[2].data = dataObj.datasets[2].data.slice(startingIndex, endingIndex);

            return newObj;
        }


        return dataObj;
    }
};

const mapStateToProps = (state) => {
    return {
        filters: state.fundingFilter.filters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
        deselectFilter: (obj) => dispatch(filterActions.deselectFilter(obj)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Funding);