// import { UPDATE_OPTION } from "./actionTypes";
import { createSlice } from '@reduxjs/toolkit';


const defaultState = {
    options: {
        Location: [],
        Phase: [],
        Sponsored_by: [],
        Active: ["Active", "Inactive"],
        Age: [],
        Size_Bucket: [],
        Year: [],
    }
};


const options = createSlice({
    name: 'option/clinical',
    initialState: defaultState,
    reducers: {
        updateOptions: (state, action) => {
            state.options[action.payload.optionKey] = action.payload.optionValue;
        }
    }
})

export const { updateOptions } = options.actions;

export default options.reducer;
