import React from "react";
import { WorldMap } from "react-svg-worldmap";
import useWindowDimensions from "hooks/useWindowDimensions";

function Worldmap(props) {
    const { data, colorLabel } = props;

    const { height, width } = useWindowDimensions();


    const stylingFunction = (context) => {
        const opacityLevel = 0.3 + (1.5 * (context.countryValue - context.minValue) / (context.maxValue - context.minValue));

        //Finding the percentage to color county according to country value
        context.color = props.getContextColor(context);

        return {
            fill: context.color,
            fillOpacity: opacityLevel,
            stroke: props.colorLabel[0] ,
            strokeWidth: 5,
            strokeOpacity: 0.1,
            cursor: "pointer"
        }
    };


    const resizeMap = (height, width) => {
        if (height >= 1980 && width >= 3350)
            return "xxl";
        if (height >= 1440 && width >= 2250)
            return "xl"
        if (height >= 1100 && width >= 1960)
            return "lg"
        if (height >= 740 && width >= 1100)
            return "md";
        return "sm";
    };

    return (
        <WorldMap
            value-suffix="trials"
            size={resizeMap(height, width)}
            backgroundColor="none"
            data={data}
            styleFunction={stylingFunction}
            borderColor={colorLabel[0]}
            tooltipBgColor={"rgba(35, 100, 124, 0.52)"}
            onClickFunction={(event, e) => {
                if (props.clickable) {
                    props.onClickCountry(event["countryName"]);
                }
            }}
        />
    )
};

export default Worldmap;