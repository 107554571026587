import React, { useState, useEffect } from "react";
import { ChartContainer } from "./style.charts";
import { Chart as ChartJS } from 'chart.js/auto';
import { Pie } from 'react-chartjs-2'
import useWindowDimensions from "hooks/useWindowDimensions";
import { PieChartComponent } from "./GraphComponents";


import { filterActions as CTFilterActions } from 'store/clinicalTrials/filtersReducer';
import { filterActions as FundingFilterActions } from 'store/funding/filtersReducer';
import { filterActions as VAFilterActions } from 'store/vaccineAvailability/filtersReducer';
import { useDispatch } from 'react-redux';


// const colors = ["#ffff", "#224F68", "#BADCF0", "#7AD6BF", "#4875A8"];

const PieChart = ({isBordered, data, pageType, chartHeight, chartWidth, position, filterType, clickable}) => {

    const dispatch = useDispatch();
    const { height, width } = useWindowDimensions();
    
    let phaseValue = isBordered ? isBordered : '' ;
    const [indexOfData, setIndexOfData] = useState(-1);
    const [singleValue, setSingleValue] = useState(false);

    useEffect(() => {
        let nonZeroCount = 0;
        for (let i = 0; i < data.datasets[0].data.length; i++) {
            if (data.datasets[0].data[i] > 0) {
                nonZeroCount += 1;
            }
        }

        setSingleValue(() => nonZeroCount === 1);

    }, [data]);

    useEffect(() => {
        for (let i = 0; i < data.datasets[0].data.length; i++) {
            if (data.datasets[0].data[i] != 0) {
                setIndexOfData(() => i);
            };
        }
    }, [singleValue]);

    const resizeFont = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 22;
        if (height >= 1210 && width >= 1800)
            return 15;
        if (height >= 920 && width >= 1380)
            return 9;
        if (height >= 720 && width >= 1024)
            return 8;
        if (height >= 620 && width >= 768)
            return 7;
        return 6;
    };

    const resizeLegend = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 40;
        if (height >= 1210 && width >= 1800)
            return 20;
        if (height >= 920 && width >= 1380)
            return 10;
        if (height >= 720 && width >= 1024)
            return 9;
        if (height >= 620 && width >= 768)
            return 8;
        return 6;
    };

    const handleClick = (label) => {
        switch (pageType) {
            case "Clinical Trials":
                dispatch(CTFilterActions.changeFilter({key : `${filterType}`, value : label}));
                break;
            case "Funding":
                dispatch(FundingFilterActions.changeFilter({key : `${filterType}`, value : label}));
                break;
            case "Vaccine Availability":
                dispatch(VAFilterActions.changeFilter({key : `${filterType}`, value : label}));
                break;
            default:
                break;
        }
    };


    return (
        <ChartContainer width={chartWidth} height={chartHeight}>
            {(data && phaseValue == "" && !singleValue) ?
            <Pie
                data={data}
                height={'95%'}
                options={{
                    interaction: {
                        mode: 'point'
                    },
                    onClick:(event, el) => {
                        if (el && clickable) {
                            const label = data["labels"][[el[0]["index"]]];
                            if (label !== "Not available") {
                                handleClick(label);
                            }
                        }
                    },
                    onHover: (event, el) => {
                        if (clickable) {
                            const target = event.native ? event.native.target : event.target;
                            target.style.cursor = el[0] ? 'pointer' : 'default';
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItems, data) => {
                                const label = data.labels[tooltipItems.index];
                                const value = data.datasets[0].data[tooltipItems.index];
                                switch (pageType) {
                                    case "Funding":
                                        return `${label}: $${processNumberString(value)}`;
                                    case "Clinical Trials":
                                        return `${label}: ${processNumberString(value)} trials`;
                                    case "Vaccine Availability":
                                        return `${label}: ${processNumberString(value)} vaccines`;
                                    default:
                                        break;
                                }
                            }
                        },
                    },
                    maintainAspectRatio: false,
                    // layout: {
                    //     padding: { top: 10, bottom: 50, left: 45, right: 40 }
                    // },
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: `${position ? position : "right"}`,
                            // labels: { fontSize: window.innerWidth > 1050 ? 8 : 5 }
                            labels: { 
                                fontSize: resizeFont(height, width),
                                boxWidth: resizeLegend(height, width),
                                fontColor: "#164050",
                            }
                        },
                    },
                }}
            />
            : <PieChartComponent
            pageType={pageType}
                isBordered={phaseValue !== ""}
                dataValue={data.datasets[0].data[indexOfData]}
                color={data.datasets[0].backgroundColor[indexOfData]}
                label={data.labels[indexOfData]}
            />
        }
        </ChartContainer>
    );
}

export default PieChart;