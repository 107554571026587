import React, { useState } from 'react';
import { SummaryTitle, TitleBackWrapper } from "pages/style.pages";
import { PagedTable } from 'components';

import { connect } from 'react-redux';
import { filterActions } from 'store/funding/filtersReducer';


function ByFunderTile({ funderFilter, projectsByFunderData, deselectFilter, changeFilter }) {

    const [ currentPage, setCurrentPage ] = useState(1);

    const NUM_OF_ROWS = 7;

    const pageCountofTable = Math.ceil(projectsByFunderData.length / NUM_OF_ROWS);

    const handleItemClick = (value) => {
        changeFilter({key: 'Funder', value: `${value}`});
    };

    return (
        <>
            <SummaryTitle>
                <TitleBackWrapper>
                    { funderFilter !== "" && <DeselsectFilterArrow onClick={ deselectFilter({ key: 'Funder' }) }><IoCaretBack style={{paddingTop: "20%"}}/></DeselsectFilterArrow>}
                    {
                        pageCountofTable > 1 ?
                            `Projects by funder (${currentPage}/${pageCountofTable})`
                            : `Projects by funder`
                    }
                </TitleBackWrapper>
            </SummaryTitle>
            <PagedTable
                header={["no. projects", "Total Funding", "Funder Name"]}
                data={projectsByFunderData}
                currentPage={currentPage}
                pageCount={pageCountofTable}
                numOfRows={NUM_OF_ROWS}
                handleTablePage={(pageNumber) => { setCurrentPage(pageNumber) }}
                isBordered={projectsByFunderData.length == 1 && funderFilter}
                handleItemClick={handleItemClick}
            />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deselectFilter: (obj) => dispatch(filterActions.deselectFilter(obj)),
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
    };
};

export default connect(null, mapDispatchToProps)(ByFunderTile);