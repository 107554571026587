import React from 'react';
import { useNavigate } from "react-router-dom";
import { HomePage, InfoArea, InfoInput, SolidButton, TitleContainer, WiserLogo, CompanyLogo, InputContainer, Error } from './style.Auth';
import { TextButton } from 'pages/style.pages';
import { useAuth } from "contexts/AuthContext";

import ProductLogo from "assets/image/wiser-logo.png";
import ProductTitle from "assets/image/company-logo.png";


export default function LogInForm(props) {

    const { userSignIn } = useAuth();
    const navigate = useNavigate();

    const initialState = {
        email: "",
        password: "",
        isSubmitting: false,
        errorMessage: null
    };

    const [data, setData] = React.useState(initialState);

    const handleIconClick = () => {
        return window.open("https://www.globalactionalliance.net/", "_blank")
    };

    const handleInputChange = (label, event) => {
        setData({
            ...data,
            [label]: event.target.value
        });
    };

    const handleSubmit = () => {
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null
        });

        fetch("https://life-science-wiser.herokuapp.com/v1/user/login", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": data.email,
                "password": data.password
            })
        })
        .then(rawResponse => {
            if (rawResponse.ok) {
                return rawResponse.json();
            }
            throw rawResponse;
        })
        .then(resJson => {
            userSignIn(resJson.token);
            navigate("/");
        })
        .catch(error => {
            console.log(error)
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: error.statusText || error.message
            });
        });
    };

    return (
        <HomePage>
            <InfoArea>
                <TitleContainer onClick={handleIconClick}>
                    <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" />
                    <CompanyLogo id="org-title" src={ProductTitle} alt="LifeScience WISE-R" />
                </TitleContainer>
                <InputContainer style={{margin: "2vh auto 1vh"}}>
                    <InfoInput
                        type={"text"}
                        value={data.email}
                        onChangeCallback={(e) => {handleInputChange("email", e)}}
                        placeholder="Organization Email"
                        keyDownCallback={handleSubmit}
                    />
                    <InfoInput
                        type={"password"}
                        value={data.password}
                        onChangeCallback={(e) => {handleInputChange("password", e)}}
                        placeholder="Password"
                        keyDownCallback={handleSubmit}
                    />

                    {
                        data.errorMessage && (
                            <Error>{data.errorMessage}</Error>
                        )
                    }
                </InputContainer>
                <SolidButton onClick={handleSubmit}>Log in</SolidButton>
                <TextButton 
                    onClick={() => navigate('/signup')}
                    style={{marginTop: "15px"}}
                >
                    Don't have account yet? Create an account
                </TextButton>
            </InfoArea>
        </HomePage>
    )
};