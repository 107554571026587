import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { HomePage, InfoArea, InfoInput, SolidButton, TitleContainer, WiserLogo, CompanyLogo, InputContainer, Prompt, PackagesContainer, BorderedTextButton, PackageCard } from './style.Auth';

const packageContent = {
    "busInt": {
        label: "Business Intelligence",
        packages: ["Licensed Products", "Organizations", "Funding"]
    },
    "R&D": {
        label: "Research & Development",
        packages: ["Clinical Trials", "Publications"]
    },
    "policy": {
        label: "Policy & Legal",
        packages: ["Policy", "Legal"]
    },
    "medical": {
        label: "Medical",
        packages: ["Library1", "Library2"]
    }
};


export default function PurchasedItems(props) {

    const [packages, setPackages] = useState({
        "busInt": "",
        "R&D": "",
        "policy": "",
        "medical": ""
    });

    useEffect(() => {
        const fetchData = async () => {
            const rawResponse = await fetch (
                "./purchasedPackages.json", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            );
            // Receive filtered results
            const packageResult = await rawResponse.json();
            setPackages(packageResult);
        };

        fetchData()
            .catch(console.error);
    }, []);

    const gotoPreviousStep = e => {
        e.preventDefault();
        props.prevStep();
    };

    const gotoNextStep = e => {
        e.preventDefault();
        props.nextStep();
    };

    return (
        <HomePage>
            <InfoArea>
                <Prompt>
                    <span>
                        Welcome to WISE-R
                    </span>
                    <span>
                        Your organization currently includes the following libraries:
                    </span>
                </Prompt>
                
                <PackagesContainer>
                    { getPackageCard() }
                </PackagesContainer>

                <SolidButton onClick={gotoPreviousStep}>Back</SolidButton>
                <SolidButton onClick={gotoNextStep}>Perfect! Continue</SolidButton>
            </InfoArea>
        </HomePage>
    )

    function getPackageCard() {
        return Object.entries(packageContent).map(item => {
            const [key, info] = item;
            return (
                <PackageCard>
                    <div>
                        <p style={{textAlign: "center"}}>{ info.label }</p>
                        <ul>
                            {
                                info.packages.map(packageItem => {
                                    return <li>{packageItem}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div>
                        { getPurchasedButtons(key) }
                    </div>  
                </PackageCard>
            )
        })
    };

    function getPurchasedButtons(key) {
        if (packages[key]) {
            switch (packages[key]) {
                case "regular":
                    return (
                        <>
                            <BorderedTextButton selected><>&radic;&nbsp;</> Regular</BorderedTextButton>
                            <BorderedTextButton>+<>&nbsp;</> Plus</BorderedTextButton>
                        </>
                    );
                case "plus":
                    return (
                        <>
                            <BorderedTextButton selected><>&radic;&nbsp;</> Regular</BorderedTextButton>
                            <BorderedTextButton selected><>&radic;&nbsp;</> Plus</BorderedTextButton>
                        </>
                    );
                default:
                    return (
                        <>
                            <BorderedTextButton>+<>&nbsp;</> Regular</BorderedTextButton>
                            <BorderedTextButton>+<>&nbsp;</> Plus</BorderedTextButton>
                        </>
                    )
            }
        }


        return (
            <>
                <BorderedTextButton>+<>&nbsp;</> Regular</BorderedTextButton>
                <BorderedTextButton>+<>&nbsp;</> Plus</BorderedTextButton>
            </>
        )
        
    }
};

